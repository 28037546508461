import Resizer from 'react-image-file-resizer';

export const resizeFile = async (file) => new Promise(resolve => {
    try{
        Resizer.imageFileResizer(file, 700, 700, 'JPEG', 100, 0,
        uri => {
          resolve(uri);
          return uri;
        },
        'blob'
        );
    } catch (error){
        console.log('error: ' + error);
        throw error;
    }

});