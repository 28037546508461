import React from 'react';
import { connect } from 'react-redux';
import CartListItem from './CartListItem';
import CustomerOrderItem from './CustomerOrderItem';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const CartList = (props) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className="content-container">
            <div className="list-body">
                {
                    props.products.length === 0 ? (
                        <div className="list-item list-item--message centered">
                            <span>{t("CartList.0")}</span>
                        </div>
                    ) : (
                            <div>
                                <h1>{props.type === 'cart' ? "My Cart" : (props.type === 'wishlist' ? "My Wishlist" : "My Orders")}</h1>
                                {
                                    (props.type === 'cart' || props.type === 'orders') &&
                                    <h2>{"Total cost: " + (props.type === 'cart' ? totalCost(props.products).toString() : totalOrderCost(props.products).toString()) + " EGP"}</h2>
                                }

                                <div className="vertical-list">
                                    {
                                        props.products.map(
                                            (product) => {

                                                return props.type !== 'orders' ?
                                                    <CartListItem key={product.id} {...product} cart={props.type}  id={product.productDocId} type={props.type} docId={product.id}/>
                                                    : <CustomerOrderItem key={product.id} {...product} type={props.type} />   //TODO Add cancelOrder function
                                            }
                                        )
                                    }
                                </div>

                                {
                                    (props.type === 'cart' && props.products.length > 0) &&
                                    <button className="button" onClick={() => {
                                        history.push('/checkout')
                                    }}>
                                        {t("CartList.1")}
                                    </button>
                                }

                            </div >
                        )
                }
            </div>


        </div >
    )
};

const totalCost = (products) => {
    let total = 0;
    products.forEach(product => {
        total += parseFloat(product.publishedPrice)
    });
    return total;
}

const totalOrderCost = (products) => {
    let total = 0;
    products.forEach(product => {
        total += product.amount ? parseFloat(product.amount) : 0
    });
    return total / 100;
}

const mapStateToProps = (state, props) => {
    return {
        products: props.type && (props.type === 'cart' ? state.cart.cart : (props.type === 'wishlist' ? state.wishlist : state.orders)),
        auth: state.auth
    };
};

export default connect(mapStateToProps)(CartList);
