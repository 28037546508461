import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import selectExpenses from '../selectors/expenses';
import ProductListItemCustomer from './ProductListItem-Customer';

import GridList from '@material-ui/core/GridList';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


const ProductListCustomer = (props) => {

    const { t } = useTranslation();
    const classes = useStyles();
    const listCount = 7;

    return (
        <div className="">
            <div className="list-body--customer">
                {
                    props.expenses.length === 0 ? (
                        <div className="list-item list-item--message">

                            <span>
                                {t("CartList.0")}
                            </span>
                        </div>
                    ) : (
                        <div className="content-container">

                            <Typography varient="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                {t("ProductListCustomer.0")}
                            </Typography>
                            {/* <Carousel responsive={responsive}>
                                {
                                    props.expenses.map(
                                        (product) => {
                                            return <ProductListItemCustomer key={product.id} product={product} />;
                                        }
                                    )
                                }
                            </Carousel> */}
                            <div className={classes.root}>
                                <GridList className={classes.gridList} cols={2.5}>
                                    {
                                        props.expenses.slice(0, listCount).map((product) => (
                                            <ProductListItemCustomer key={product.id} product={product} />
                                        ))
                                    }
                                </GridList>
                            </div>

                            {
                                props.numRows !== undefined && props.numRows == -1 &&
                                <div>
                                    <Typography varient="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        {t("ProductListCustomer.1")}
                                    </Typography>
                                    <div className={classes.root}>
                                        <GridList className={classes.gridList} cols={2.5}>
                                            {
                                                props.expenses.slice(listCount, listCount * 2).map((product) => (
                                                    <ProductListItemCustomer key={product.id} product={product} />
                                                ))
                                            }
                                        </GridList>
                                    </div>
                                </div>
                            }
                            {
                                props.numRows !== undefined && props.numRows >= 2 &&
                                <div>
                                    
                                    <Typography varient="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        {t("ProductListCustomer.1")}
                                    </Typography>
                                    <div className={classes.root}>
                                        <GridList className={classes.gridList} cols={2.5}>
                                            {
                                                props.expenses.slice(listCount, listCount * 2).map((product) => (
                                                    <ProductListItemCustomer key={product.id} product={product} />
                                                ))
                                            }
                                        </GridList>
                                    </div>
                                </div>
                            }


                            {
                                props.numRows !== undefined && props.numRows >= 3 &&
                                <div>

                                    <Typography varient="h3" style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        {t("ProductListCustomer.2")}
                                    </Typography>
                                    <div className={classes.root}>
                                        <GridList className={classes.gridList} cols={2.5}>
                                            {
                                                props.expenses.slice(listCount * 2, listCount * 3).map((product) => (
                                                    <ProductListItemCustomer key={product.id} product={product} />
                                                ))
                                            }
                                        </GridList>
                                    </div>
                                </div>

                            }

                        </div >
                    )
                }
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        expenses: selectExpenses(state.expenses.marketProducts, state.filters),
    };
};

export default connect(mapStateToProps)(ProductListCustomer);
