import db from '../firebase/firebase';

//Add expense
export const addToWishlist = (item) => ({
    type: 'ADD_TO_WISHLIST',
    item
});

export const startAddToWishlist = (itemData) => {
    return (dispatch, getState) => {

        const item = {
            //    ...itemData,
            title: itemData.title,
            titleAR: itemData.titleAR,
            publishedPrice: itemData.publishedPrice,
            inStock: itemData.inStock,
            description: itemData.description,
            descriptionAR: itemData.descriptionAR,
            numReviews: itemData.numReviews,
            productDocId: itemData.id,
            rating: itemData.rating,
            shopId: itemData.shopId,
            url1: itemData.detailPageUrls[0],
            uid: getState().auth.uid
        };

        const userId = getState().auth.id;
        if (!userId) {
            return;
        }
        db.collection('users').doc(userId).collection('userFavs').add(item).then((value) => {
            dispatch(addToWishlist({
                id: value.id,
                ...item
            }));
            //         console.log("success!");
        });


    };
};

export const removeFromWishlist = ({ id } = {}) => ({
    type: 'REMOVE_FROM_WISHLIST',
    id
});

export const startRemoveFromWishlist = ({ id }) => {
    return (dispatch, getState) => {
        const userId = getState().auth.id;
        db.collection('users').doc(userId).collection('userFavs').doc(id).delete().then((snapshot) => {
            dispatch(removeFromWishlist({ id }));
        })

    }
}

//Set expenses
export const setWishlist = (wishlist) => ({
    type: 'SET_WISHLIST',
    wishlist
});

//get shop products
export const startSetWishlist = () => {
    return async (dispatch, getState) => {
        const id = getState().auth.id;
        //    console.log("user id: " + id);
        if (id == null) return;

        db.collection('users').doc(id).collection('userFavs').get().then((snapshot) => {
            const wishlist = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                wishlist.push({
                    id: doc.id,
                    ...data
                });
            });

            dispatch(setWishlist(wishlist));
        });
    };
};
