import React, { useState } from "react";
import { connect } from "react-redux";
import { handleSendCodeLogin, startLogin } from "../actions/auth";
import { toast } from "react-toastify";
import validatePhoneNumber from "../utility/validatePhone";
import LoginForm from "../components/auth/LoginForm";
import VerifyForm from "../components/auth/VerifyForm";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const { t, i18n } = useTranslation();

  const handlephoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const valid = validatePhoneNumber(phoneNumber);
    if (!valid) {
      toast.error(t("Login.6"));
      return;
    }

    const data = await props.handleSendCodeLogin(`+2${phoneNumber}`);
    if (data) {
      if (data.error) {
        if (data.error.code == "auth/phone-not-exist") {
          toast.error(t("Login.7"));
        } else if (data.error.code == "auth/invalid-phone-number") {
          toast.error(t("Login.8"));
        } else {
          toast.error(data.error.message);
        }
      } else {
        setVerificationId(data);
        setPhoneNumber(`+2${phoneNumber}`);
        setStep(2);
        toast.success(t("Login.9"));
      }
    }
  };

  const handleVerifyCodeSubmit = async (
    user,
    setState,
    verificationId,
    phoneNumber,
    startLogin
  ) => {
    user.verificationId = verificationId;
    user.phoneNumber = phoneNumber;
    try {
      const data = await startLogin(user);
      if (data.error) {
        if (data.error.code == "auth/phone-not-exist") {
          setState(() => ({
            error:t("Login.10")}));
        } else if (data.error.code == "auth/invalid-verification-code") {
          setState(() => ({
            error:t("Login.11")}));
        } else if (data.error.code == "auth/invalid-phone-number") {
          setState(() => ({ error: t("Login.12") }));
        } else if (data.error.code == "auth/code-expired") {
          setState(() => ({
            error: t("Login.13"),
          }));
        } else {
          setState(() => ({ error: data.error.message }));
        }
      } else {
        setState(() => ({ success: true }));
      }
    } catch (error) {
      setState(() => ({ error: error.message }));
    }
  };

  if (step == 1) {
    return (
      <LoginForm
        phoneNumber={phoneNumber}
        handlephoneNumberChange={handlephoneNumberChange}
        handleLoginSubmit={handleLoginSubmit}
        t={t}
        i18n={i18n}
      />
    );
  } else if (step == 2) {
    return (
      <VerifyForm
        onSubmit={(user, setState) =>
          handleVerifyCodeSubmit(
            user,
            setState,
            verificationId,
            phoneNumber,
            props.startLogin
          )
        }
        t= {t}
      />
    );
  }
};

//dispatch is what calls the fn globally, we need to map the function to this property (startLogin:  ) in props
const mapDispatchToProps = (dispatch) => ({
  handleSendCodeLogin: (phoneNumber) =>
    dispatch(handleSendCodeLogin(phoneNumber)),
  startLogin: (user) => dispatch(startLogin(user)),
});

export default connect(undefined, mapDispatchToProps)(Login);
