import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ShopDrawer from './widgets/ShopDrawer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            height: '900',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 500,
            paddingLeft: 150,
            flexDirection: 'row',
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        padding: 'auto',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
            height: "100%",
        },
    },
    title: {
        fontSize: 20,
        fontWeight: "Bold"
    },
    titleNumber: {
        fontSize: 18,
    },
    cardRoot: {
        padding: 'auto',
        background: '#f9f3f3',
        minWidth: 275,
        maxWidth: 590,
        marginRight: 40,
        marginTop: 40,
        height: 150,
        [theme.breakpoints.down('md')]: {
            width: 200,
            maxWidth: 700,
        },
    },
    mainTitle: {
        [theme.breakpoints.down('md')]: {
            marginTop: 180,
        },
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}));


const ShopHomePage = (props) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className="content-container">
            {
                ((props.shop != undefined && props.shop.pending) || props.user.hasShop == 'pending')
                &&
                <label className="content-container center" style={{ marginTop: 20 }}>{t("AddShop.1")}</label>
            }
            {/* //TODO Add dashboard */}
            <ShopDrawer />
            <div className={classes.root}>
                <Typography variant="h2" className={classes.mainTitle}>{t("ShopDashboard.1")}</Typography>
                <div className={classes.row}>
                    <Card className={classes.cardRoot}>
                        <CardContent>
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                {t("ShopDashboard.2")}
                            </Typography>
                            <Typography variant="h2" component="h2">
                                0
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.cardRoot}>
                        <CardContent>
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                {t("ShopDashboard.3")}
                            </Typography>
                            <Typography variant="h2" component="h2">
                                0
                            </Typography>
                        </CardContent>
                    </Card>

                </div>
                <div className={classes.row}>

                    <Card className={classes.cardRoot}>
                        <CardContent>
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                {t("ShopDashboard.4")}
                            </Typography>
                            <Typography variant="h2" component="h2">
                                0
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card className={classes.cardRoot}>
                        <CardContent>
                            <Typography className={classes.title} color="textPrimary" gutterBottom>
                                {t("ShopDashboard.5")}
                            </Typography>
                            <Typography variant="h2" component="h2">
                                0.0 EGP
                            </Typography>
                        </CardContent>
                    </Card>

                </div>

                <Card className={classes.cardRoot}>
                    <CardContent>
                        <Typography className={classes.title} color="textPrimary" gutterBottom>
                            {t("ShopDashboard.6")}
                        </Typography>
                        <Typography variant="h2" component="h2">
                            0
                        </Typography>
                    </CardContent>
                </Card>
            </div>

        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth,
        shop: state.shop,
    }
}

export default connect(mapStateToProps)(ShopHomePage);

//export default ShopHomePage;