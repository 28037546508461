const categoriesReducerDefaultState = {
    categories: {},
    categoriesTr: {}
};

export default (state = categoriesReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_CATEGORIES':
            const categories = action.categories;
            return {
                ...state,
                categories
            };
        case 'SET_CATEGORIES_TR':
            const categoriesTr = action.categoriesTr;
            return {
                ...state,
                categoriesTr
            };
        default:
            return state;
    }
}