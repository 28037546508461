import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startEditUser } from '../actions/auth';
import EditUserForm from './EditUserForm';
import { sendPasswordReset, updateEmail } from '../actions/auth';
import Modal from 'react-modal';

const EditUserPage = (props) => {
    const { t } = useTranslation();

    const [type, setType] = useState('');
    const [feedback, setFeedback] = useState('');

    const [openModal, setModal] = useState(false);

    const handleCloseModal = () => {
        setModal(false);
    }

    return (
        <div className="custom-140-height">
            <div>
                {
                    type == '' &&
                    <div className="content-container">
                        <h1>{t('EditUser.4')}</h1>
                        <div className="public-data-container">
                            <div className="center-col">
                                <h3>{t('EditUser.5')}</h3>
                                <div>
                                    <label className="input-tips">{props.user.firstName} &nbsp;</label>
                                    <label className="input-tips">{props.user.secondName}</label>
                                </div>
                            </div>

                            <button className="upper-margin button" onClick={() => {
                                setType('name');
                            }}>{t('EditUser.11')}</button>
                        </div>

                        <div className="upper-margin public-data-container">
                            <p className="input-tips">{t('EditUser.6')}</p>
                            <div className="center-col">
                                <h3>{t('EditUser.7')}</h3>
                                <label className="input-tips">{props.user.phoneNumber || 'No phone number added'}</label>
                            </div>
                            <div className="center-col">
                                <h3>{t('EditUser.8')}</h3>
                                <label className="input-tips">{props.user.addressLine || 'No address line added'}</label>
                            </div>
                            <button className="upper-margin button" onClick={() => {
                                setType('verify');
                            }}>{t('EditUser.11')}</button>
                        </div>

                        <div className="upper-margin public-data-container">
                            <p className="input-tips">{t('EditUser.6')}</p>
                            <button className="upper-margin button" onClick={() => {
                                setType('email');
                            }}>{t('EditUser.9')}</button>
                        </div>

                        <div className="upper-margin public-data-container">
                            <p className="input-tips">{t('EditUser.6')}</p>
                            <button className="upper-margin button" onClick={async ()  => {
                                await sendPasswordReset(props.user.email);
                                setFeedback('Email sent to change your password, please check your email');
                                setModal(true);
                            }}>{t('EditUser.10')}</button>
                        </div>
                    </div>
                }

                {
                    type != '' &&
                    <div className="content-container">
                        <EditUserForm
                            type={type}
                            t={t}
                            user={props.user}
                            onSubmit={(user) => {
                                props.dispatch(startEditUser(props.user.id, user));
                                props.history.push('/home-page');
                            }
                            }
                        />
                    </div>
                }

                {
                    openModal &&
                    <Modal
                        isOpen={openModal}
                        contentLabel={feedback}
                        onRequestClose={handleCloseModal}
                        closeTimeoutMS={200}
                        className="modal"
                    >
                        <h2>Note</h2>
                        <h3>{feedback}</h3>
                        <button className="button--wishlist borderless" onClick={props.handleCloseModal}>Close</button>
                    </Modal>
                }
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        user: state.auth,
    }
}

export default connect(mapStateToProps)(EditUserPage);