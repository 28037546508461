import React from 'react';
import { connect } from 'react-redux';
import { startAddExpense } from '../actions/expenses';
import ProductForm from './ProductForm';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShopDrawer from './widgets/ShopDrawer';

const AddExpensePage = (props) => {
    const { t, i18n } = useTranslation();
    
    return (
    <div className="off-white-background">
        <ShopDrawer />
        <div className="shift-right content-container">

        {
                (props.hasShop === 'true' && props.phoneVerified) &&
                <div>
                    <h1>{t("Add Product.Title")}</h1>
                    <ProductForm
                        t={t}
                        i18n={i18n}
                        onSubmit={(expense) => {
                                props.dispatch(startAddExpense(expense));
                                props.history.push('/dashboard');
                            }
                        }
                        onSubmit2={(expense) => {
                            props.dispatch(startAddExpense(expense));
                            props.history.push('/add-product');
                        }}
                    />
                </div>
            }
            {
                props.hasShop !== 'true' &&
                (
                    props.shop.pending
                        ?
                        <p>
                            {t("Add Product.Please Wait")}
                        </p>
                        :
                        <p>
                            {t("Add Product.1")} <span> <Link to="/create-shop">{t("Add Product.2")}</Link> </span>  {t("Add Product.3")}
                        </p>
                )
            }
            {
                !props.phoneVerified &&
                <p>
                    {t("Add Product.Verify Phone")}
                </p>
            }

        </div>
    </div>

)};

const mapStateToProps = (state) => {
    return {
        hasShop: state.auth.hasShop,
        phoneVerified: state.auth.phoneVerified,
        shop: state.shop
    }
}

export default connect(mapStateToProps)(AddExpensePage);