import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Badge } from "reactstrap";

import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import 'react-image-gallery/styles/css/image-gallery.css';
import "../styles/product-details.css";
import ProductsList from "../components/UI/ProductList";
import dummyProducts from "../assets/data/products";
import { toast } from "react-toastify";
import {
  addProductNewReview,
  getProductReviews,
  loadProduct,
} from "../actions/expenses";
import { connect } from "react-redux";
import { startAddToCart } from "../actions/cart";
import { startAddToWishlist } from "../actions/wishlist";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ImageGallery from 'react-image-gallery';

const ProductDetails = (props) => {
  const [tab, setTab] = useState("desc");
  const [rating, setRating] = useState(null);
  const [product, setProduct] = useState({});
  const [hoveredRating, setHoveredRating] = useState(0);
  const { t, i18n } = useTranslation();
  const reviewMsg = useRef("");
  const [loading, setLoading] = useState(false);
  const [isReviewSubmitting, setIsReviewSubmitting] = useState(false);


  const handleHover = (hoveredRating) => {
    setHoveredRating(hoveredRating);
  };

  const handleMouseLeave = () => {
    setHoveredRating(0);
  };

  const getStarClassName = (index) => {
    if (rating >= index || hoveredRating >= index) {
      return "ri-star-s-fill";
    } else {
      return "ri-star-s-line";
    }
  };

  const fetchData = async () => {
    const loadedProduct = await loadProduct(props.match.params.id);
    await props.getReviews(props.match.params.id);
    return loadedProduct;
  };

  useEffect(() => {
    setLoading(true);
    fetchData().then(fetchedProduct => {
      setProduct(fetchedProduct)
      setLoading(false);
    })
  }, [props.match.params.id]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const submitHandle = async (e) => {
    e.preventDefault();
    setIsReviewSubmitting(true)
    const message = reviewMsg.current.value;

    if (rating == 0 || rating == null) {
      toast.error("please select rate");
      return;
    }

    const newReview = {
      rating,
      review: message,
      userName: `${props.currentUser.firstName} ${props.currentUser.secondName}`,
      uid: props.currentUser.uid,
      imageUrl: null // Assign a default image for each user
    };

    await props.addNewReview(product.id, newReview);
    setRating(null);
    setIsReviewSubmitting(false)
    toast.success("Review submitted");
    reviewMsg.current.value = "";
    await props.getReviews(props.match.params.id);
  };

  return (
    <Helmet title={product.title}>
      <section className="pt-0 w-100">
        <Container>
          <Row>
            {loading ? (<>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ fontSize: "4rem" }}
              />
            </>
            ) : product == (null || undefined) ? (
              <div>{t("ProductDetailsPage.11")}</div>
            ) : (
              <section>
                <Container>
                  <Row>
                    <Col lg="8">
                      {console.log(product)}
                      {product.detailPageUrls && product.detailPageUrls.length > 0 ? (
                        <ImageGallery
                          items={product.detailPageUrls.map(url => ({
                            original: url,
                            thumbnail: url,
                            fullscreen: url
                          }))}
                          showFullscreenButton={false}
                          showPlayButton={false}
                          showThumbnails={true}
                          thumbnailPosition="left"
                          showNav={false} // Hide navigation arrows
                          slideDuration={500} // Customize slide transition duration (optional)
                          useBrowserFullscreen={true} // Enable fullscreen mode (optional)
                          renderItem={item => (
                            <div className="image-gallery-image">
                              <img
                                src={item.original}
                                alt=""
                              />
                            </div>
                          )}
                        />
                      ) : (
                        <p>No product images available.</p>
                      )}
                    </Col>
                    <Col lg="4">
                      <div className="product__details">
                        <h2>{i18n.language == "en" ? product.title : product.titleAR}</h2>
                        <div className="product__rating d-flex align-item-center gap-5 mb-3">
                          <div>
                            {Array(5).fill(1).map((_, i) => (
                              <span>
                                <i
                                  key={i}
                                  className={`ri-star-s-${i < product.rating ? 'fill' : 'line'}`}
                                />
                              </span>
                            ))}
                          </div>
                          <p><span>{Math.ceil(product.rating)}</span> {t("ProductDetailsPage.1")}</p>
                        </div>
                        <div className="d-flex align-items-center gap-5">
                          <span className="product__price">
                            {product.publishedPrice} {t("ProductDetailsPage.2")}
                          </span>
                          {product.categories && product.categories.length > 0 && (
                            <div>
                              {product.categories.map((category, index) => (
                                <span key={index} className="category-badge">
                                  {i18n.language == "en" ? category.category : props.categoriesAR[category.category]}
                                </span>
                              ))}
                            </div>
                          )}
                        </div>
                        <p className="mt-3"> {i18n.language == "en" ? product.description : product.descriptionAR}</p>
                        <div className="wish-cart__btn">
                          <button
                            className="buy__btn"
                            onClick={() => props.addToCart(product)}
                          >
                            {t("ProductDetailsPage.3")}
                          </button>
                          <button
                            className="buy__btn wishlist__btn"
                            onClick={() => props.addToWishList(product)}
                          >
                            {t("ProductDetailsPage.4")}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <section>
                  <Container>
                    <Row>
                      <Col lg="12">
                        <div className="tab__wrapper d-flex align-items-center gap-5">
                          <h6
                            className={`${tab === "desc" ? "active__tab" : ""}`}
                            onClick={() => setTab("desc")}
                          >
                            {t("ProductDetailsPage.5")}
                          </h6>
                          <h6
                            className={`${tab === "rev" ? "active__tab" : ""}`}
                            onClick={() => setTab("rev")}
                          >
                            {t("ProductDetailsPage.6")}
                          </h6>
                        </div>

                        {tab === "desc" ? (
                          <div className="tab__content mt-5">
                            <p>{i18n.language == "en" ? product.description : product.descriptionAR}</p>
                          </div>
                        ) : (
                          <div className="product__review mt-5">
                            <div className="review__wrapper">
                              <ul className="list-unstyled">
                                {props.reviewList?.map((item, index) => (
                                  < li key={index} className="mb-4" >
                                    <div className="row align-items-center mb-2">
                                      <div className="col">
                                        <h6 className="mb-0">{item.userName}</h6>
                                      </div>
                                      <div className="col-auto">
                                        <small className="text-muted">
                                          {new Date(
                                            item.date.seconds * 1000
                                          ).toLocaleString()}
                                        </small>
                                      </div>
                                    </div>
                                    <span className="text-warning">
                                      {item.rating} ({t("ProductDetailsPage.8")})
                                    </span>
                                    <p className="mb-0">{item.review}</p>
                                  </li>
                                ))}
                              </ul>
                              {props.currentUser.uid && (
                                <div className="review__form">
                                  <h4>{t("ProductDetailsPage.9")}</h4>
                                  <form action="" onSubmit={submitHandle}>
                                    <div className="form__group d-flex align-items-center gap-5 rating__group">
                                      {[1, 2, 3, 4, 5].map((star, index) => (
                                        <span
                                          key={index}
                                          onClick={() => setRating(star)}
                                          onMouseEnter={() => handleHover(star)}
                                          onMouseLeave={() => handleMouseLeave()}
                                        >
                                          {star}
                                          <i
                                            className={`ri ${getStarClassName(star)}`}
                                          ></i>
                                        </span>
                                      ))}
                                    </div>

                                    <div className="form__group">
                                      <textarea
                                        ref={reviewMsg}
                                        rows={4}
                                        type="text"
                                        placeholder={t("ProductDetailsPage.12")}
                                        required
                                      />
                                    </div>
                                    <button type="submit" className="buy__btn" disabled={isReviewSubmitting}>
                                      {isReviewSubmitting ? (
                                        <>
                                          <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '1rem' }} />{' '}
                                          {t('ProductDetailsPage.10')}
                                        </>
                                      ) : (
                                        t('ProductDetailsPage.10')
                                      )}
                                    </button>

                                  </form>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col lg="12" className="mt-5">
                        <h2 className="related__title">{t("ProductDetailsPage.7")}</h2>
                      </Col>
                      <ProductsList t={t} i18n={i18n} data={dummyProducts} />
                    </Row>
                  </Container>
                </section>
              </section>
            )}
          </Row>
        </Container>
      </section>
    </Helmet >
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product) => {
    toast.success("Product added to your cart successfully!");
    return dispatch(startAddToCart(product));
  },
  addNewReview: (productId, review) => {
    return dispatch(addProductNewReview(productId, review));
  },
  getReviews: (productId) => {
    return dispatch(getProductReviews(productId));
  },
  addToWishList: (product) => {
    toast.success("Product added to your wishlist succesfully");
    return dispatch(startAddToWishlist(product))
  },
});

const mapStateToProps = (state) => {
  return {
    reviewList: state.expenses.reviewProductList,
    currentUser: state.auth,
    categoriesAR: state.categories.categoriesTr
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
