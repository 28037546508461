import db from '../firebase/firebase';

export const setCategories = (categories) => ({
    type: 'SET_CATEGORIES',
    categories
});

export const setCategoriesTr = (categoriesTr) => ({
    type: 'SET_CATEGORIES_TR',
    categoriesTr
});

export const startSetCategories = () => {
    return async (dispatch, getState) => {
        db.collection('categories').doc('Categories').get().then((doc) => {
            const data = doc.data();

            const keys = Object.keys(data).sort();

            let newCategories = {};
            keys.forEach(key => {
                var values = data[key];
                values.sort();
                if (key !== "Others")
                    newCategories[key] = data[key];
            });

            newCategories["Others"] = data["Others"];
            dispatch(setCategories(newCategories));
        }).catch(err => {
        })
    };
};

export const startSetCategoriesTr = () => {
    return async (dispatch, getState) => {
        db.collection('categories').doc('CategoriesTr').get().then((doc) => {
            const categoriesTr = doc.data()['sub'];
            dispatch(setCategoriesTr(categoriesTr));
        }).catch(err => {
        })
    };
};