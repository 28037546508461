const shopOrdersReducerDefaultState = [];

export default (state = shopOrdersReducerDefaultState, action) => {
    switch (action.type) {
        case 'UPDATE_ORDER':
            return state.map((order) => {
                if(order.id === order.id){
                    return{
                        ...order,
                        ...action.status
                    };
                }else{
                    return order;
                }
            });
        case 'SET_SHOP_ORDERS':
            return action.shopOrders;
        default:
            return state;
    }
}