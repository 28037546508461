import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
//import ExpenseForm from './ExpenseForm';
import { startEditExpenses, RemoveExpense, loadProduct, getDateAsString } from '../actions/expenses';
import LoadingPage from './LoadingPage';
import ProductForm from './ProductForm';
import { useTranslation } from 'react-i18next';

const EditExpensePage = (props) => {
  
    const { t,i18n } = useTranslation();

    useEffect(() => {
        async function getProduct() {
            if (props.product === null || props.product === undefined) {
                const pr = await loadProduct(props.match.params.id);
                const date = getDateAsString(pr.creationDate);
                setState({
                    product: pr,
                    madeDate: date
                });
            }

        }

        getProduct();
    })

    const [state, setState] = useState({
        product: null,
        madeDate: ''
    });

    return (
        <div className="content-container">
            {
                props.product === undefined ? (
                    state.product === null ? <LoadingPage /> :
                        <div>
                            <h1>{t("EditExpensePage.0")}</h1>
                            <ProductForm
                                t={t}
                                i18n={i18n}
                                product={state.product}
                                onSubmit={(product) => {
                                    props.dispatch(startEditExpenses(state.product.id, product));
                                    props.history.push('/dashboard');
                                }}
                            />

                            <button onClick={() => {
                                props.dispatch(RemoveExpense({ id: state.product.id }));
                                props.history.push('/dashboard');
                            }}>{t("EditExpensePage.1")}</button>
                        </div>
                )
                    :
                    <div>
                        <h1>{t("EditExpensePage.0")}</h1>
                        <ProductForm
                            t={t}
                            i18n={i18n}
                            product={props.product}
                            onSubmit={(product) => {
                                props.dispatch(startEditExpenses(props.product.id, product));
                                props.history.push('/dashboard');
                            }}
                        />

                        <button onClick={() => {
                            props.dispatch(RemoveExpense({ id: props.product.id }));
                            props.history.push('/dashboard');
                        }}>{t("EditExpensePage.1")}</button>
                    </div>
            }



        </div>
    );
}

const mapStateToPros = (state, props) => {
    return {
        product: state.expenses.shopProducts.find((product) => product.id === props.match.params.id)
    }
}

export default connect(mapStateToPros)(EditExpensePage);
