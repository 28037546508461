import React from 'react';
import { connect } from 'react-redux';
import CartListItem from './CartListItem';
import CustomerOrderItem from './CustomerOrderItem';
import { startAddToOrders } from '../actions/orders';
import { useTranslation, Translation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import CheckoutForm from './CheckoutForm';

class Checkout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shippingCost: 0,
            errorMessage: ''
        }
    }

    setShippingCost = (cost) => {
        this.setState({
            shippingCost: cost,
            errorMessage: ''
        });
    }

    cost = (products) => {
        let total = 0;
        products.forEach(product => {
            total += parseFloat(product.publishedPrice)
        });
        return total;
    }

    totalCost = (products) => {
        let total = 0;
        products.forEach(product => {
            total += parseFloat(product.publishedPrice)
        });
        return total + this.state.shippingCost;
    }

    render() {
        return (
            <Translation>
                {
                    t =>
                        <div className="content-container">
                            <div className='row-list'>

                                <CheckoutForm
                                    type='name'
                                    user={this.props.user}
                                    govs={this.props.govs}
                                    onSubmit={(user) => {
                                        if (this.state.shippingCost == 0) {
                                            this.setState({
                                                errorMessage: t('CheckoutForm.12')
                                            })
                                            return;
                                        }
                                        this.props.startAddOrders(user.firstName, user.secondName, user.phoneNumber, user.addressLine, user.email, this.totalCost(this.props.products));
                                    }
                                    }
                                    setShippingCost={(cost) => {
                                        this.setShippingCost(cost);
                                    }}
                                />
                                <div className="vertical-list">
                                    {
                                        this.props.products.map(
                                            (product) => {

                                                return <CartListItem key={product.id} {...product} cart={this.props.type} id={product.productDocId} type='checkout' />
                                            }
                                        )
                                    }

                                    {
                                        this.state.errorMessage !== "" &&
                                        <a className="error-msg">{this.state.errorMessage}</a>
                                    }

                                    <h3 className="list-item__title">{t("CheckoutForm.13") + this.state.shippingCost + " EGP"}</h3>
                                    <h3 className="list-item__title">{t("CheckoutForm.14") + this.cost(this.props.products) + " EGP"}</h3>
                                    <h3 className="list-item__title">{t("CheckoutForm.15") + this.totalCost(this.props.products) + " EGP"}</h3>

                                </div>
                            </div>

                        </div >}
            </Translation>);
    }
};



const mapStateToProps = (state, props) => {
    return {
        products: state.cart.cart,
        govs: state.cart.govs,
        auth: state.auth,
        shippingCost: 0
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        startAddOrders: (firstName, lastName, phone, address, email, cost) => dispatch(startAddToOrders(firstName, lastName, phone, address, email, cost))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
