import React from 'react';
import { connect } from 'react-redux';
import ShopForm from './ShopForm';
import { startEditShop } from '../actions/shop';
import { startCreateShop } from '../actions/shop';
import HeaderShop from '../header/HeaderShop';
import { useTranslation } from 'react-i18next';
import ShopDrawer from './widgets/ShopDrawer';

const EditShopPage = (props) => {
    
    const { t } = useTranslation();
    
    return(
    <div>
        {/* <HeaderShop /> */}
        <ShopDrawer />
        <div className="shift-right content-container">
            <h1>{t("Edit Shop.0")}</h1>
            <ShopForm
                shop={props.shop}
                editing={true}
                t={t}
                onSubmit={(shop) => {
                    if(props.hasShop != 'true'){
                        props.dispatch(startCreateShop(shop));
                    }
                    else{
                        props.dispatch(startEditShop(shop));
                    }
                    props.history.push('/');
                }
                }
            />
        </div>
    </div>

)};

const matchStateToProps = (state, props) => {
    return {
        shop: state.shop,
        hasShop: state.auth.hasShop
    }
}

export default connect(matchStateToProps)(EditShopPage);