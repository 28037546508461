import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';


const LoadingPage = () => {
    return(
    <div className="loader" color="secondary">
         <CircularProgress />
    </div>
)};

export default LoadingPage;