const cartReducerDefaultState = {
    cart: [],
    govs: [],
  };
  
  export default (state = cartReducerDefaultState, action) => {
    switch (action.type) {
      case "ADD_TO_CART":
        const cart = state.cart;
        cart.push(action.item);
        return {
          ...state,
          cart: cart,
        };
      case "REMOVE_FROM_CART":
        return {
          ...state,
          cart: state.cart.filter(({ id }) => id !== action.id),
        };
      case "SET_CART":
        return {
          ...state,
          cart: action.cart,
        };
      case "SET_GOVS":
        return {
          ...state,
          govs: action.govs,
        };
      case "UPDATE_CART_ITEM":
        const updatedCart = state.cart.map((item) => {
          if (item.id === action.id) {
            return {
              ...item,
              ...action.updates,
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          cart: updatedCart,
        };
      default:
        return state;
    }
  };