import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { PAGE_SIZE, PAGE_SIZE_MOBILE } from '../../utility/constants';
import { isMobile } from 'react-device-detect';

const PaginationNavigator = ({backwardClick, forwardClick, currentPage, productsLength}) => (
    <div className="horizontal-center">
        {
            currentPage > 0 &&
            <button className="button" onClick={backwardClick}>
                <ArrowBackIosIcon style={{ fontSize: "20px" }} />
            </button>
        }
        {
            (productsLength === PAGE_SIZE && !isMobile) &&
            <button className="button left-margin" onClick={forwardClick}>
                <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </button>
        }
        {
            (productsLength === PAGE_SIZE_MOBILE && isMobile) &&
            <button className="button left-margin" onClick={forwardClick}>
                <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </button>
        }
    </div>
);


export default PaginationNavigator;
