import React, { useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import "../styles/auth.css";
import registerImg from "../assets/images/register.svg";
import horusImg from "../assets/images/horus-favicon-colord.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyForm from "../components/auth/VerifyForm";
import { connect } from "react-redux";
import { handleSendCodeSignup, startSignUp } from "../actions/auth";
import validatePhoneNumber from "../utility/validatePhone";
import RegisterForm from "../components/auth/RegisterForm";
import { useTranslation } from "react-i18next";

const Signup = (props) => {
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [verificationId, setVerificationId] = useState("");
  const [userData, setUserData] = useState([]);
  const { t, i18n } = useTranslation();

  const handleSignupSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    let user = { firstName, secondName, email, phoneNumber };
    const valid = validatePhoneNumber(user.phoneNumber);
    if (!valid) {
      toast.error(
        t("Signup.11")
      );
      setLoading(false);
      return;
    }
    user.phoneNumber = `+2${user.phoneNumber}`;
    try {
      const data = await props.handleSendCodeSignup(user.phoneNumber);
      if (data.error) {
        if (data.error.code == "auth/phone-exist") {
          toast.error(
            t("Signup.12")
          );
        } else if (data.error.code == "auth/invalid-phone-number") {
          toast.error(t("Signup.13"));
        } else {
          toast.error(data.error.message);
        }
        setLoading(false);
      } else {
        setVerificationId(data);
        setUserData(user);
        setLoading(false);
        setStep(2);
        toast.success(t("Signup.14"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleVerifyCodeSubmit = async (phoneCode, setState) => {
    try {
      const data = await props.startSignUp(userData, verificationId, phoneCode);
      if (data.error) {
        if (data.error.code == "auth/phone-exist") {
          setState(() => ({
            error: t("Signup.15")
          }));
        } else if (data.error.code == "auth/invalid-verification-code") {
          setState(() => ({
            error:
              t("Signup.16")
          }));
        } else if (data.error.code == "auth/invalid-phone-number") {
          setState(() => ({ error: "Incorrect phone number" }));
        } else if (data.error.code == "auth/code-expired") {
          setState(() => ({
            error: t("Signup.17"),
          }));
        } else {
          setState(() => ({ error: data.error.message }));
        }
      } else {
        setState(() => ({ success: true }));
        toast.success(t("Signup.18"));
      }
    } catch (error) {
      setState(() => ({ error: error.message }));
    }
  };

  if (step === 1) {
    return (
      <RegisterForm
        firstName={firstName}
        setFirstName={setFirstName}
        secondName={secondName}
        setSecondName={setSecondName}
        email={email}
        setEmail={setEmail}
        phoneNumber={phoneNumber}
        setPhone={setPhone}
        loading={loading}
        handleSignupSubmit={handleSignupSubmit}
        t={t}
        i18n={i18n}
      />
    );
  } else if (step === 2) {
    return (
      <VerifyForm
        onSubmit={(user, setState) =>
          handleVerifyCodeSubmit(
            user,
            setState,
            verificationId,
            phoneNumber,
            props.startLogin
          )
        }
        t={t}
        i18n={i18n}
      />
    );
  }
};

const mapDispatchToProps = (dispatch) => ({
  startSignUp: (user, verificationId, phoneCode) =>
    dispatch(startSignUp(user, verificationId, phoneCode)),
  handleSendCodeSignup: (phoneNumber) =>
    dispatch(handleSendCodeSignup(phoneNumber)),
});

export default connect(undefined, mapDispatchToProps)(Signup);
