export const SetTextFilter = ( text='' ) => ({
    type: 'SET_TEXT_FILTER',
    text
});

export const SortByAmount = () => ({
    type: 'SORT_BY_AMOUNT',
});

export const SortByDate = () => ({
    type: 'SORT_BY_DATE',
});

export const SetStartData = (startDate) => ({
    type: 'SET_START_DATE',
    startDate
});

export const SetEndData = (endDate) => ({
    type: 'SET_END_DATE',
    endDate
});

export const sortByPrice = (minPrice, maxPrice) => ({
    type: 'SORT_BY_PRICE',
    minPrice,
    maxPrice
})