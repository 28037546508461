import React, { useState } from "react";
import Popup from "reactjs-popup";
import Badge from "react-bootstrap/Badge";


const OrderDetailsCard = ({
  orderProducts,
  setRefundReason,
  setRefundOption,
  handleSubmit,
  i18n,
  t
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  return (
    orderProducts.map((product) => (<tr>
      <td className="align-items-center d-flex">
        <div style={{ paddingRight: "1rem" }}>
          <img src={product.url1} alt="" />
        </div>
        <div>
          <div style={{ fontSize: "1.25rem", fontWeight: "bold" }}>
            {i18n.language === 'en' ? product.title : product.titleAR}
          </div>
        </div>
      </td>
      <td>{product.publishedPrice} {t("OrderDetailsPage.9")}</td>
      <td>{product.quantity}</td>
      <td>
        <div style={{ marginLeft: "1rem" }}>
          {product.status === "In progress" && (
            <Popup
              contentStyle={{ width: "400px" }}
              trigger={<button className="buy__btn" size="md">{t("OrderDetailsPage.3")}</button>}
              open={popupOpen}
              onClose={() => setPopupOpen(false)}
            >
              <form onSubmit={(e) => { handleSubmit(e, product.docId); setPopupOpen(false); }}>
                <div className="form-group px-2">
                  <label htmlFor="refundOptions">
                    {t("OrderDetailsPage.10")}
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control form-control-lg"
                    id="refundOptions"
                    defaultValue={null}
                    onChange={(e) =>
                      setRefundOption(e.target.value)
                    }
                  >
                    <option
                      value={null}
                      style={{ color: "#888", fontStyle: "italic" }}
                    >
                      {t("OrderDetailsPage.11")}
                    </option>
                    <option value="Item defective, not functioning as expected">
                      {t("OrderDetailsPage.16")}
                    </option>
                    <option value="Received Broken/torn">
                      {t("OrderDetailsPage.17")}
                    </option>
                    <option value="Received user or package already open">
                      {t("OrderDetailsPage.18")}
                    </option>
                    <option value="Fake, unauthentic item">
                      {t("OrderDetailsPage.19")}
                    </option>
                    <option value="Not as described,different from picture or description">
                      {t("OrderDetailsPage.20")}
                    </option>
                    <option value="Received wrong order, wrong color/size/model">
                      {t("OrderDetailsPage.21")}
                    </option>
                    <option value="I didn't like the item and I don't want it anymore">
                      {t("OrderDetailsPage.22")}
                    </option>
                  </select>
                </div>
                <div className="form-group px-2">
                  <label htmlFor="InputRefundReason">
                    {t("OrderDetailsPage.12")}
                  </label>
                  <textarea
                    style={{ fontSize: "15px" }}
                    className="form-control form-control-lg"
                    id="InputRefundReason"
                    aria-describedby="refundHelp"
                    placeholder={t("OrderDetailsPage.13")}
                    onChange={(e) =>
                      setRefundReason(e.target.value)
                    }
                  />
                  <small
                    id="refundHelp"
                    className="form-text text-muted"
                  >
                    {t("OrderDetailsPage.14")}
                  </small>
                </div>
                <button
                  type="submit"
                  style={{ fontSize: "5px" }}
                  className="btn btn-primary btn-sm display-3 buy__btn w-5"
                >
                  {t("OrderDetailsPage.15")}
                </button>
              </form>
            </Popup>
          )}
          {product.status !== "In progress" && (
            <Badge variant={
              product.status === "Refund requested" ? "warning" :
                product.status === "Refund accepted" ? "success" :
                  "secondary"
            }>
              {product.status}
            </Badge>
          )}
        </div>
      </td>
    </tr >
    ))
  );
};

export default OrderDetailsCard;