import firebase from "firebase";
import db, { _auth } from "../firebase/firebase";
import { startSetCart, startSetGovs } from "./cart";
import { startSetOrders } from "./orders";
import { startSetShop } from "./shop";
import { startSetWishlist } from "./wishlist";

export const signUp = (user) => ({
  type: "SIGNUP",
  ...user,
});

export const login = (user) => ({
  type: "LOGIN",
  ...user,
});

export const logout = () => ({
  type: "LOGOUT",
});

export const editUser = (updates) => ({
  type: "EDIT",
  updates,
});

export const startEditUser = (id, updates) => {
  try {
    return async (dispatch, getState) => {
      if (id === undefined || id === "") id = getState().auth.id;

      await db.collection("users").doc(id).update(updates);
      dispatch(editUser(id, updates));
    };
  } catch (e) {
  }
};

export const startSignUp = (user = {}, verificationId, { phoneCode }) => {
  return async (dispatch, getState) => {
    try {
      let response = { error: "" };
      const {
        firstName = "",
        secondName = "",
        phoneNumber = "",
        email = "",
        hasShop = "false", //false, pending, true
        shopId = "",
        imageUrl = "",
        numInterests = 0,
        hasPhone = false,
        hasAddress = false,
        sellerSignUpStep = 1,
        isSeller = false,
        createdFrom = "web",
      } = user;

      // Add user to database
      const uid = generateRandomCode(); // Generate a random UID for the user since we don't have one yet
      const _user = {
        firstName,
        secondName,
        phoneNumber,
        email,
        hasShop,
        shopId,
        imageUrl,
        uid,
        numInterests,
        hasPhone,
        hasAddress,
        sellerSignUpStep,
        isSeller,
        createdFrom,
      };

      //check if the user is exists
      const snapshotPhoneNumber = await db
        .collection("users")
        .where("phoneNumber", "==", _user.phoneNumber)
        .get();
      const docPhoneNumber = snapshotPhoneNumber.docs[0];
      if (docPhoneNumber) {
        return {
          error: {
            code: "auth/phone-exist",
            message: "the phone number you entered not exists",
          },
        };
      }

      const credential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        phoneCode
      );
      const userCredential = await firebase
        .auth()
        .signInWithCredential(credential);

      _user.uid = userCredential.user.uid;
      const doc = await db.collection("users").add(_user);
      doc.date()
      response = { ...response, doc };
      dispatch(
        signUp({
          ...doc.id,
          ..._user,
        })
      );

      return response;
    } catch (error) {
      return { error };
    }
  };
};

export const handleSendCodeLogin = (phoneNumber) => {
  return async (dispatch, getState) => {
    try {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      recaptchaContainer.innerHTML = ""; // Clear the contents of the container element
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );

      //check if phone exists or not
      //check if the user is exists
      const snapshotPhoneNumber = await db
        .collection("users")
        .where("phoneNumber", "==", phoneNumber)
        .get();
      const docPhoneNumber = snapshotPhoneNumber.docs[0];
      if (!docPhoneNumber) {
        return {
          error: {
            code: "auth/phone-not-exist",
            message: "the phone number you entered not exists",
          },
        };
      }

      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      return await phoneProvider.verifyPhoneNumber(phoneNumber, appVerifier);
    } catch (error) {
      return { error };
    }
  };
};

export const handleSendCodeSignup = (phoneNumber) => {
  return async (dispatch, getState) => {
    try {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      recaptchaContainer.innerHTML = ""; // Clear the contents of the container element
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );

      //check if the user is exists
      const snapshotPhoneNumber = await db
        .collection("users")
        .where("phoneNumber", "==", phoneNumber)
        .get();
      const docPhoneNumber = snapshotPhoneNumber.docs[0];
      if (docPhoneNumber) {
        return {
          error: {
            code: "auth/phone-exist",
            message: "the phone number you entered not exists",
          },
        };
      }

      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      return await phoneProvider.verifyPhoneNumber(phoneNumber, appVerifier);
    } catch (error) {
      return { error };
    }
  };
};

export const startLogin = (user) => {
  return async (dispatch, getState) => {
    try {
      if (user.uid != null) {
        const uid = user.uid;
        const snapshot = await db
          .collection("users")
          .where("uid", "==", uid)
          .get();
        if (snapshot.docs.length === 0) {
          return;
        }
        const doc = snapshot.docs[0];
        const authData = doc.data();
        const id = doc.id;
        const auth = {
          id,
          uid,
          ...authData,
        };
        dispatch(
          login({
            auth,
          })
        );

        dispatch(startSetWishlist());
        dispatch(startSetCart());
        dispatch(startSetOrders());
        dispatch(startSetShop());
      } else {
        const snapshotPhoneNumber = await db
          .collection("users")
          .where("phoneNumber", "==", user.phoneNumber)
          .get();
        const docPhoneNumber = snapshotPhoneNumber.docs[0];
        if (!docPhoneNumber) {
          return {
            ...user,
            error: {
              code: "auth/phone-not-exist",
              message: "the phone number you entered not exists",
            },
          };
        }

        const credential = await firebase.auth.PhoneAuthProvider.credential(
          user.verificationId,
          user.phoneCode
        );
        const userCredits = await firebase
          .auth()
          .signInWithCredential(credential);
        const uid = userCredits.user.uid;
        const snapshotUid = await db
          .collection("users")
          .where("uid", "==", uid)
          .get();
        const doc = snapshotUid.docs[0];
        const auth = doc.data();
        const id = doc.id;
        dispatch(
          login({
            auth,
            uid,
            id,
          })
        );
      }
      return user;
    } catch (error) {
      return { ...user, error: error };
    }
  };
};

export const startCheckUser = () => {
  return (dispatch, getState) => {
    const user = _auth.currentUser;
    _auth
      .signInWithEmailAndPassword(user.email, user.password)
      .then((userCredits) => {
        const uid = userCredits.user.uid;

        db.collection("users")
          .where("uid", "==", uid)
          .get()
          .then((snapshot) => {
            const doc = snapshot.docs[0];
            const userToPush = doc.data();
            dispatch(
              login({
                userToPush,
              })
            );
          });
      });
  };
};

export const startLogout = () => {
  return () => {
    return _auth.signOut();
  };
};

export const sendVerificationLink = () => {
  _auth.currentUser.sendEmailVerification();
};

export const checkVerificationCode = (code, verificationCode, userId) => {
  return async (dispatch, getState) => {
    if (code === verificationCode) {
      const updates = {
        phoneVerified: true,
        enteredPhoneVerifiction: code,
      };

      await db
        .collection("users")
        .doc(userId)
        .update({
          ...updates,
        });
      //   dispatch(editUser(updates));

      return true;
    }

    return false;
  };
};

export const sendPasswordReset = async (email) => {
  _auth.sendPasswordResetEmail(email).then(() => {
  });
};

export const updateEmail = async (newEmail) => {
  //  _auth.currentUser.verifyBeforeUpdateEmail();
  await _auth.currentUser.updateEmail(newEmail);
  await _auth.currentUser.sendEmailVerification();
};

export const generateRandomCode = () => {
  const random0 = Math.floor(Math.random() * Math.floor(10)).toString();
  const random1 = Math.floor(Math.random() * Math.floor(10)).toString();
  const random2 = Math.floor(Math.random() * Math.floor(10)).toString();
  const random3 = Math.floor(Math.random() * Math.floor(10)).toString();

  const random = random0 + random1 + random2 + random3;

  return random;
};
