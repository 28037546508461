import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { startRemoveFromCart } from '../actions/cart';
import { startRemoveFromWishlist } from '../actions/wishlist';
import { refundOrder } from '../actions/orders';
import Popup from "reactjs-popup"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css'


const CartListItem = ({ removeWishlistItem, removeCartItem, id, title, titleAR, description, descriptionAR, price, publishedPrice, type, _status, url1, docId, startRefundPrduct, orderId, productId }) => {
    const [value, setValue] = useState('');
    const handleChange = (event) => {
        setValue(event.target.value);
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        const refundReason = value;
        const refundOption = event.target.refundOptions.value; // Get the selected option value

        if (refundOption && refundOption !== "Please select reason") {
            startRefundPrduct(orderId, docId, refundReason, refundOption); // Pass the selected option as the fourth argument
            setValue('');
            event.target.parentNode.style.display = "none";
            toast.success('Your Refund Request Successfully Submitted', {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            toast.error('Please select a refund option', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    return (
        <div className="list-item">
            <Link className=" list-item--link" to={`/product-details/${id}`}>
                <div>
                    <img className="list-item__img-v" src={url1 != null ? url1 : 'images/img-ph.jpg'} />
                </div>
                <div className="col-div">

                    <h2 className="list-item__title">{title || titleAR}</h2>
                    <h3 className="list-item__title">{description || descriptionAR}</h3>
                    <h3 className="list-item__data">
                        {Math.ceil(publishedPrice) + " EGP"}
                    </h3>

                    {
                        type === 'orderItem' &&
                        <h3 className="list-item__title">{"Product status: " + _status}</h3>
                    }
                </div>
            </Link>
            {
                (type != 'orderItem' && type != 'checkout') &&
                <button className="button--confined" onClick={() => {
                    if (type === 'cart')
                        removeCartItem(docId);
                    else if (type === 'wishlist')
                        removeWishlistItem(docId);
                }}>
                    Remove
                </button>
            }
            {
                (type == 'orderItem' && _status !== 'Refund requested') &&
                <div>
                    <Popup
                        contentStyle={{ width: '400px' }}
                        trigger={<button className="button--confined">Refund</button>}

                    >
                        <form onSubmit={handleSubmit}>

                            <div className='form-group px-2'>
                                <label htmlFor="refundOptions">Refund Options <span className="text-danger">*</span></label>
                                <select className='form-control form-control-lg' id='refundOptions' defaultValue={null}>
                                    <option value={null} style={{ color: '#888', fontStyle: 'italic' }}>Please select reason</option>
                                    <option value='Item defective, not functioning as expected'>Item defective, not functioning as expected</option>
                                    <option value='Received Broken/torn'>Received Broken/torn</option>
                                    <option value='Received user or package already open'>Received user or package already open</option>
                                    <option value='Fake, unauthentic item'>Fake, unauthentic item</option>
                                    <option value='Not as described,different from picture or description'>Not as described,different from picture or description</option>
                                    <option value='Received wrong order, wrong color/size/model'>Received wrong order, wrong color/size/model</option>
                                    <option value="I didn't like the item and I don't want it anymore">I didn't like the item and i don't want it anymore </option>
                                    <option value="Ordered wrong color/size/model">I didn't like the item and i don't want it anymore </option>
                                </select>
                            </div>

                            <div className='form-group px-2'>
                                <label htmlFor="InputRefundReason">Refund Reason</label>
                                <textarea value={value} style={{ fontSize: "15px" }} className="form-control form-control-lg" id="InputRefundReason" aria-describedby="refundHelp" placeholder="Enter the reason" onChange={handleChange} />
                                <small id="refundHelp" className="form-text text-muted">Providing the reason for your refund will help us better understand your needs </small>
                            </div>
                            <button type="submit" style={{ fontSize: "15px" }} className="btn btn-primary btn-lg w-25 display-3">Submit</button>
                        </form>
                    </Popup>
                    <ToastContainer />
                </div>
            }

        </div>


    );
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        startRefundPrduct: (orderId, productId, refundReason, refundOption) => dispatch(refundOrder(orderId, productId, refundReason, refundOption)),
        removeCartItem: (id) => dispatch(startRemoveFromCart({ id })),
        removeWishlistItem: (id) => dispatch(startRemoveFromWishlist({ id }))
    }
}
export default connect(undefined, mapDispatchToProps)(CartListItem);