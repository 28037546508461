import React from "react";
import ProductCard from "./ProductCard";
const ProductsList = ({ t, i18n, data }) => {
  return (
    <>
      {data?.map((item, index) => (
        <ProductCard t={t} i18n={i18n} item={item} key={index} />
      ))}
    </>
  );
};

export default ProductsList;
