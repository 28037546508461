import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CategoryDropdown from './CategoryDropdown';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import IconButton from '@material-ui/core/IconButton';
import Storefront from '@material-ui/icons/Storefront';
import FavoriteSharp from '@material-ui/icons/FavoriteSharp';
import Badge from '@material-ui/core/Badge';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "#BBBA3A",
        zIndex: 1
    },
    logo: {
        marginTop: "15px",
        maxWidth: "150px",
        height: "auto"
    },
    menuItem: {
        width: "150px",
        fontSize: "15px"
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    largeIcon: {
        fontSize: "2em"
    },
    mediumIcon: {
        fontSize: "1.5em"
    },
    badge: {
        fontSize: 12
    }
}));

export const HeaderCategories = (props) => {

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    const [marginLeft, setMarginleft] = useState(i18n.language === 'en' ? 'auto' : '0');
    const [marginRight, setMarginRight] = useState(i18n.language === 'ar' ? 'auto' : '0');

    useEffect(() => {
        setMarginRight(i18n.language === 'ar' ? 'auto' : '0');
        setMarginleft(i18n.language === 'en' ? 'auto' : '0');
    })

    return (
        <div className="header__middle_cats">
            <NavLink style={{ color: "white", fontWeight: "normal", marginLeft: 12, marginRight: 12, marginTop: 7 }} className="category-item" to={`/home-page`} activeClassName="is-active">
                <p>{t("Header.8")}</p>
            </NavLink>
            {
                props.mainCategories.map((title) => {
                    return <CategoryDropdown key={title} title={title} />
                })
            }

            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: marginLeft, marginRight: marginRight }}>
   

                {
                    props.isAuth &&
                    <div >
                        <IconButton style={{marginRight: 5}} className="icon" onClick={() => {
                            props.history.push(props.shopUrl);
                        }}>
                            <div className="simple-col">
                                <Storefront fontSize='large' />
                                <label>{t("Header.5")}</label>
                            </div>
                        </IconButton>

                        <IconButton aria-label="show 17 new notifications" color="inherit" onClick={() => {
                            props.history.push('/cart');
                        }}>
                            <div className="simple-col">
                                <Badge badgeContent={props.cartCount} color="secondary" classes={{ badge: classes.badge }}>
                                    <ShoppingCart className={classes.mediumIcon} />
                                </Badge>
                                <label>{t("Header.2")}</label>
                            </div>
                        </IconButton>

                        <IconButton className="icon" onClick={() => {
                            props.history.push('/customer-orders');
                        }}>
                            <div className="simple-col">
                                <ShoppingBasket fontSize='large' />
                                <label>{t("Header.4")}</label>
                            </div>
                        </IconButton>

                        <IconButton className="icon" onClick={() => {
                            props.history.push('/wishlist');
                        }}>
                            <div className="simple-col">
                                <FavoriteSharp fontSize='large' />
                                <label>{t("Header.3")}</label>
                            </div>
                        </IconButton>

                    </div>
                }
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {

    return {
        mainCategories: Object.keys(state.categories.categories),
        isAuth: state.auth.uid ? true : false,
        cartCount: state.cart.cart.length,
        shopUrl: state.auth.hasShop ? '/shop-home' : '/create-shop',
    }
}

export default connect(mapStateToProps)(HeaderCategories);


