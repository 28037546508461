import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import selectExpenses from '../../selectors/expenses';
import { startSetProductsCategory, clearLastProducts } from '../../actions/expenses';
import { sortByPrice } from '../../actions/filters';
import ProductListItemCustomer from '../ProductListItem-Customer';
import PaginationNavigator from '../widgets/PaginationNavigator';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

var catTitle = '';
var mainCatTitle = '';

const ProductsPage = (props) => {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (currentPage === 0) {
            props.getProducts(props.match.params.mainCat.replace(/%20/g, " "), props.match.params.cat.replace(/%20/g, " "), undefined, productsLength);
            props.clearCategoryLastProduct();
        }
        if (window.innerWidth >= 1920) {
            setProductsLength(18);
        } else if (window.innerWidth < 1920 && window.innerWidth >= 1500) {
            setProductsLength(15);
        } else if (window.innerWidth < 1500 && window.innerWidth >= 1400) {
            setProductsLength(16);
        }
        else {
            setProductsLength(12);
        }
    }, [props.products.length]);


    const [productsLength, setProductsLength] = useState(18);
    const [state, setState] = useState({
        mainCategory: props.match.params.mainCat.replace(/%20/g, " "),
        category: props.match.params.cat.replace(/%20/g, " "),
    });

    const [titleTr, setTitle] = useState(state.mainCategory);
    const [subCatTr, setSubCatTr] = useState(state.category);

    const [currentPage, setCurrentPage] = useState(0);

    catTitle = state.category;
    mainCatTitle = state.mainCategory;

    
    useEffect(() => {
        setTitle(i18n.language === 'en' ? mainCatTitle : props.categoriesTr[mainCatTitle]);
        setSubCatTr(i18n.language === 'en' ? catTitle : props.categoriesTr[catTitle]);
    })

    const forwardClick = () => {
        setCurrentPage(currentPage + 1);
        props.getProducts(state.mainCategory, state.category, 'forward', productsLength);
    }

    const backwardClick = () => {
        setCurrentPage(currentPage - 1);
        props.getProducts(state.mainCategory, state.category, 'backward', productsLength);
    }

    return (

        <div>

            <div className="show-for-desktop content-container">
                <div >
                    <h1>{titleTr}</h1>
                    <h2>{subCatTr}</h2>
                    <div className="horizontal-layout">
                        {
                            props.products.length > 0 &&

                            <div className="filters">

                                <div className="filters-section">
                                    <a>Price</a> <br />
                                    <div>
                                        <input type="radio" name="price" value="0 to 100" onChange={() => {
                                            props.priceFilter(0, 100);
                                            //   filterProducts(grid);                         
                                        }
                                        } />
                                        <label className="radio_text">0 to 100</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="price" value="100 to 200" onChange={() => {
                                            props.priceFilter(100, 200);
                                            //    filterProducts(grid);                         
                                        }
                                        } />
                                        <label className="radio_text" >100 to 200</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="price" value="200 to 300" />
                                        <label className="radio_text" >200 to 300</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="price" value="300 to 400" />
                                        <label className="radio_text" >300 to 400</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="price" value="Over 400" />
                                        <label className="radio_text" >Over 400</label>
                                    </div>
                                </div>

                                <div className="filters-section">
                                    <a>Item type</a>

                                    <div>
                                        <input type="radio" name="item-type" value="All items" />
                                        <label className="radio_text" >All items</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="item-type" value="Handmade" />
                                        <label className="radio_text" >Handmade</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="item-type" value="Vintage" />
                                        <label className="radio_text" >Vintage</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="item-type" value="Factory made" />
                                        <label className="radio_text" >Factory made</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="item-type" value="Food" />
                                        <label className="radio_text" >Food</label>
                                    </div>
                                </div>

                            </div>

                        }
                        <div>
                            <GridList container spacing={4}>
                                {
                                    props.products.length == 0 &&
                                    <div>{t("ProductsPage.0")}</div>
                                }
                                {
                                    props.products.map(
                                        product => { return <ProductListItemCustomer key={product.id} product={product} xs={4} /> }
                                    )
                                }
                            </GridList>
                        </div>
                    </div>


                </div>
            </div>


            <div className="show-for-mobile" style={{marginTop: '10rem', marginLeft: '2rem'}}>
                <Typography variant="h3" style={{fontSize: 21, marginBottom: 5}}>
                    {titleTr}
                </Typography>
                <Typography variant="h3" style={{fontSize: 18, color: 'grey', marginBottom: 5}}>
                    {subCatTr}
                </Typography>
                

                {
                    props.products.length == 0 &&
                    <div>{t("ProductsPage.0")}</div>
                }
                <GridList container>

                    {
                        props.products.map(
                            product => { return <ProductListItemCustomer key={product.id} product={product} /> }
                        )
                    }
                </GridList>

            </div>


            <PaginationNavigator forwardClick={forwardClick} backwardClick={backwardClick} currentPage={currentPage} productsLength={props.products.length} />


        </div>

    );
}

const mapStateToProps = (state, props) => {
    const categories = state.categoriesFilter;
    const mainCategory = categories.mainCategory;
    const category = categories.category;

    if (category !== '' && mainCategory !== '') {
        if (category !== catTitle || mainCategory !== mainCatTitle) {
            window.location.reload();
        }
    }

    return {
        products: selectExpenses(state.expenses.categoryProducts, state.filters),
        filters: state.filters,
        categoriesTr: state.categories.categoriesTr
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProducts: (mainCategory, category, nextPage, limit) => dispatch(startSetProductsCategory(mainCategory, category, nextPage, limit)),
    clearCategoryLastProduct: () => dispatch(clearLastProducts()),
    priceFilter: (minPrice, maxPrice) => dispatch(sortByPrice(minPrice, maxPrice)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);