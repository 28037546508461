import React from 'react';
import ExpenseList from '../components/ExpenseList';
import HeaderShop from '../header/HeaderShop';
import { useTranslation } from 'react-i18next';
import ShopDrawer from './widgets/ShopDrawer';

const ExpenseDashboardPage = () =>{ 
    
    const { t } = useTranslation();
    
    return(
    <div>
        <ShopDrawer />
        <div className="shift-right content-container">
            <h1>{t("Edit Shop.1")}</h1>
            <ExpenseList shopItem={true}/>
        </div>
    </div>
    
)};

export default ExpenseDashboardPage;