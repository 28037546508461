import React from 'react';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { startUpdateOrder } from '../actions/shopOrders';
import { getDateAsString } from '../actions/expenses';
import { useTranslation } from 'react-i18next';

const ShopOrderListItem = ({ startUpdateOrder, id, title, description, publishedPrice, status, creationDate, url1 }) => {
    
    const { t } = useTranslation();
    
    return(
    <div className="list-item">
        <div>
            <img className="list-item__img-v" src={url1 ?? 'images/img-ph.jpg'} />
        </div>
        <div className="col-div">

            <h2 className="list-item__title">{title}</h2>
            <h3 className="list-item__title">{description}</h3>
            <h3 className="list-item__data">
                {numeral(publishedPrice).format('0,0.00') + " EGP"}
            </h3>

            {
                (creationDate != null && typeof creationDate === 'number') &&
                <h3>
                    {"Ordered at " + getDateAsString(creationDate)}
                </h3>
            }

            <select className="select"
                //    value={props.filters.sortBy}
                onChange={e => status = e.target.value}>
                <option value="In progress">{t("ShopOrderListItem.0")}</option>
                <option value="Shipped">{t("ShopOrderListItem.1")}</option>
                <option value="Delivered">{t("ShopOrderListItem.2")}</option>
                <option value="Cancelled">{t("ShopOrderListItem.3")}</option>
            </select>
            <h3 className="list-item__title">{"Product status: " + status}</h3>

        </div>
        {
            <button className="button--confined" onClick={() => startUpdateOrder(id, status)}>
                {t("ShopOrderListItem.4")}
            </button>}
    </div>


)};

const mapDispatchToProps = (dispatch) => {
    return {
        startUpdateOrder: (id, status) => dispatch(startUpdateOrder({ id, status }))
    }
}
export default connect(undefined, mapDispatchToProps)(ShopOrderListItem);