import React from 'react';
import "../../styles/terms-condition.css";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const SellerPolicy = () => {

    const { t } = useTranslation();

    return (
        <div className="terms content-container">
            <h1 className="title">
                Seller Policy
            </h1>

            <h4>
                Bazaar is a marketplace where you can sell your handmade goods, vintage items, and craft supplies indirectly to buyers (through bazaar)
                around the world. We want to make sure that you and your buyers have a positive experience on Bazaar. Please read on to find out more about
                your rights, as well as what is expected of you, as a seller.<br /><br />
            </h4>
            <p>
                This policy is a part of our <span className="link-underline"> <Link onClick={() => window.open('/termsAndConditions')}> Terms and Conditions.</Link></span>  By shopping on Bazaar, you’re agreeing to this policy and our Terms and Conditions.
            </p>

            <div className="content sub-list">
                1. Selling Basics  <br />
                <div className="sub-list">
                    a. What can be sold on Bazaar  <br />
                    b. What can't be sold on Bazaar  <br />
                    c. Managing your Bazaar shop <br />
                    d. Seller Standards <br />
                    e. Selling Fees   <br /> <br />
                </div>

                2. Being a Member of the Bazaar Community <br />
                <div className="sub-list">
                    a. Creating and Uploading Content  <br />
                    b. Privacy and Protecting Personal Information  <br />
                    c. Communication Standards <br />
                    <div className="sub-list">
                        i. Messages <br />
                        ii. Forums/Teams   <br />
                        iii. Communicating Cancellations   <br /> <br />
                    </div>
                </div>

                3. Feedback, Disputes, and Your Success <br />
                <div className="sub-list">
                    a. Reviews  <br />
                    b. Bazaar’s Case System   <br />
                    c. Your Seller Account and Bazaar’s Terms <br />
                    d. Seller Protection <br /> <br />
                </div>
            </div>

            <div>
                <h1>1. Selling Basics</h1>
                <p>
                    Keeping in mind these basic requirements will set you up for success on Bazaar.   <br /><br />
                    <h2>a. What can be sold on Bazaar</h2>
                    Bazaar is a unique marketplace. Buyers come here to purchase items that they might not find anywhere else. Everything listed for sale on
                    Bazaar must be handmade, vintage, or a craft supply.   <br /><br />

                    Handmade items are items that are made and/or designed by you, the seller. <br />
                    If you sell handmade items, you agree that:  <br /><br />
                    <p className='sub-list'>
                        1. All handmade items are made or designed by you. If you work with a production partner, you must disclose that production partner
                        in your relevant listings.<br />
                        2. You accurately describe every person involved in the making of an item in your shop in your About section.   <br />
                        3. You are using your own photographs or video content —not stock photos, artistic renderings, or photos used by other sellers or
                        sites.  <br />
                    </p>
                    If you are selling personalized or made‐to‐order items in the Handmade category, you agree that:<br />
                    <p className='sub-list'>
                        1. All listings are available for purchase at a set price.  <br />
                        2. If you are using photographs of previous work with options for customization (like color choices) included in the listing, it is clear in
                        your description that the photos shown are just examples.  <br />
                        3. Vintage items must be at least 20 years old.<br />
                    </p>
                    Craft Supplies are tools, ingredients, or materials whose primary purpose is for use in the creation of an item or special occasion. Craft supplies
                    may be handmade, commercial, or vintage. Party supplies may also be sold as craft supplies.<br />
                    <br />
                    We encourage you to be transparent about how your craft supplies were made and where your materials come from. You can disclose whether
                    your items have social or environmental attributes, such as organic or recycled. You can also select the location of manufacture.<br />
                    <br />
                    <h2>b. What Can't be Sold on Bazaar</h2>
                    <br />
                    Even if they otherwise meet our marketplace criteria, prohibited items, services, and items that violate our intellectual property policies are not
                    allowed to be sold on Bazaar.  <br />
                    Reselling is not allowed in the handmade category on Bazaar. Reselling refers to listing an item as handmade when you were not involved in
                    designing or making that item.  <br />
                    <br />
                    Keep in mind that members may flag listings that appear to violate our policies for Bazaar's review. Bazaar may remove any listings that violate
                    our policies. Note that listing fees are non‐refundable. Bazaar may also suspend or terminate your account for any violations. You’ll still be on
                    the hook to pay any outstanding fees on your Bazaar statement.<br />
                    <br />
                    If you are raising money on behalf of a charity, you must obtain that charity’s consent.<br />
                    <br />
                    <h2>c. Managing your Bazaar Shop</h2>
                    <br />
                    Your shop represents you and your business to the Bazaar community. It’s important that you, your items and your shop are honestly and
                    accurately represented.  <br />
                    <br />
                    By selling on Bazaar, you agree that you will:  <br /> <br />
                    1. Provide honest, accurate information in your About section.<br />
                    <br />
                    2. Honor your Shop Policies.  <br />
                    <br />
                    3. Ensure your shop content, such as any text, photos or videos used to represent yourself, your shop or your listings, abide by Bazaar’s
                    policies, including our Anti‐Discrimination policy.<br />
                    <br />
                    4. Accurately represent your items in listings and listing photos.<br />
                    <br />
                    5. Respect the intellectual property of others. If you feel someone has violated your intellectual property rights, you can report it to
                    Bazaar<br />
                    <br />
                    6. Not engage in fee avoidance.  <br />
                    <br />
                    7. Not create duplicate shops or take any other action (such as manipulating clicks, carts or sales) for the purpose of shilling,
                    manipulating search or circumventing Bazaar's policies.  <br />
                    <br />
                    8. Not coordinate pricing with other sellers. <br />  <br />

                    <h2>d. Seller Standards </h2>
                    <br />
                    By listing a product for sale on Bazaar you understand and agree that you are responsible for complying with all applicable
                    laws and regulations for the products you list for sale, including any required labels and warnings. Bazaar assumes no responsibility for the
                    accuracy, labeling, or content of your listings. <br />  <br />

                    Meeting Service Level Standards <br />  <br />

                    As a seller, you must provide great customer service and maintain trust with your buyers. These requirements are called our Seller Service Level
                    Standards. Bazaar may reach out to you if your shop fails to meet Bazaar's Seller Service Level Standards.  <br />  <br />

                    By selling on Bazaar, you agree to:<br />
                    <p className='sub-list'>
                        1. Honor your shipping and processing times. Sellers are obligated to ship an item or otherwise complete a transaction with “bazaar” in
                        a prompt manner, unless there is an exceptional circumstance. Please be aware that legal requirements for shipping times vary by
                        country.<br />
                        2. Respond to Messages in a timely manner.<br />
                        3. Honor the commitments you make in your shop policies.<br />
                        4. Resolve disagreements or disputes with the buyer directly with bazaar. In the unlikely event that you can’t reach a resolution, Bazaar
                        can help through our case system.<br />
                        5. If you are unable to complete an order, you must notify the buyer and cancel the order.<br />
                    </p>

                    <h2>e. Selling Fees</h2>
                    <br />
                    Sellers may be charged for using some of Bazaar’s services. There are fees associated with listing, selling, advertising, and certain other Bazaar
                    products and features. You can find information on fees in the Fees and Payments Policy.
                </p>


                {/* 2. Communicating with Other Bazaar Members */}
                <h1>2. Being a Member of the Bazaar Community</h1>

                At Bazaar, everyone is expected to treat fellow members of the Bazaar community with respect. As a seller, you have additional responsibilities
                to safeguard personal information and communicate promptly with buyers in order to provide a great customer experience.

                <h2>a. Creating and Uploading Content</h2>

                <p>
                    As a member of Bazaar, you have the opportunity to create and upload a variety of content, like listings, Messages, text, photos, and videos. In
                    order to keep our community safe and respectful, you agree that you will not upload content that is:<br /><br />

                    1. Abusive, threatening, defamatory, harassing, or otherwise in violation of our Anti‐Discrimination Policy; <br />
                    2. Obscene or vulgar;<br />
                    3. In violation of someone else’s privacy or intellectual property rights; or <br />
                    4. False, deceptive, or misleading. <br /><br />

                    Read more about how Bazaar search works, what factors impact search ranking, and how you can help optimize your listings and shop and in
                    The Ultimate Guide to Bazaar Search.<br /><br />


                    <h2>b. Privacy and Protecting Personal Information</h2>

                    You are responsible for protecting members’ personal information you receive or process, and you must comply with all relevant legal
                    requirements. This includes applicable data protection and privacy laws that govern the ways in which you can use Bazaar user information.
                    These laws may require that you post and comply with your own privacy policy, which must be accessible to Bazaar users with whom you
                    interact. Your privacy policy must be compatible with this policy and Bazaar’s Terms and Conditions.<br /><br /><br />

                    In particular, when you sell using our Services (subject to this Policy) or use Bazaar’s API (also subject to the Bazaar API Terms and Conditions),
                    you may receive and determine what to do with certain personal information, such as when communicating with users and entering into
                    transactions with buyers. This means you process personal information (for example, buyer name, email address, and shipping address) and, to
                    the extent you do so, under Egyptian law, you are an independent controller of data relating to other users that you may have obtained
                    through the Services. For more information on the General Data Protection Regulation. As a data controller (that is someone who decides what
                    personal data is collected and the purpose you’ll use the data for) to the extent that you process user personal information outside of the
                    Services, you may be required under applicable data protection and privacy laws to honor requests received from such users for data access,
                    portability, correction, deletion, and objections to processing. Also, if you disclose personal information without the buyer’s proper consent,
                    you are responsible for that unauthorized disclosure. This includes, for example, disclosures you make or unintentional data breaches. For
                    example, you may receive a buyer’s email address or other information as a result of entering into a transaction with that buyer. This
                    information may only be used for Bazaar‐related communications or for Bazaar‐facilitated transactions. You may not use this information for
                    unsolicited commercial messages or unauthorized transactions. Without the buyer’s consent, and subject to other applicable Bazaar policies
                    and laws, you may not add any Bazaar member to your email or physical mailing list, use that buyer’s identity for marketing, or obtain or retain
                    any payment information. Please bear in mind that you're responsible for knowing the standard of consent required in any given instance. If
                    Bazaar and you are found to be joint data controllers of personal information, and if Bazaar is sued, fined, or otherwise incurs expenses because
                    of something that you did in your capacity as a joint data controller of buyer personal information, you agree to indemnify Bazaar for the
                    expenses it occurs in connection with your processing of buyer personal information.<br /><br /><br />


                    <h2>c. Communication Standards</h2>
                    i. Messages<br /><br />
                    With Bazaar Messages, you have the ability to communicate directly other Bazaar members. Messages are a great way for buyers to ask you
                    questions about an item or an order.<br /><br />
                    Messages may not be used for the following activities:<br /><br />
                    1. Sending unsolicited advertising or promotions, requests for donations, or spam;<br />
                    2. Harassing or abusing another member or violating our Anti‐Discrimination Policy;<br />
                    3. Contacting someone after they have explicitly asked you not to; or<br />
                    4. Interfering with a transaction or the business of another member.<br />

                    Interference occurs when a member intentionally interferes with another member’s shop in order to drive away their business. Interference is
                    strictly prohibited on Bazaar. Examples of interference include:<br /><br />

                    1. Contacting another member via Bazaar Messages to warn them away from a particular member, shop, or item;<br />
                    2. Posting in public areas to demonstrate or discuss a dispute with another member;<br />
                    3. Purchasing from a seller for the sole purpose of leaving a negative review;<br />
                    4. Maliciously clicking on a competitor's Promoted Listings ads in order to drain that member's advertising budget, also known as "click
                    fraud."<br />
                    5. Creating or using an independent buyer account to maliciously upvote another shop’s negative reviews in order to position those
                    reviews more prominently.<br />  <br />

                    Harassment Any use of Bazaar Messages to harass other members is strictly prohibited. Similarly, Messages may not be used to support or
                    glorify hatred or otherwise violate our Anti‐Discrimination Policy. If you receive a Message that violates this policy, please let us know right
                    away.<br />  <br />

                    ii. Forums and Teams<br />  <br />

                    Forums and Teams are public spaces provided by Bazaar where sellers can connect, but there are communication standards that must be
                    followed. <br />  <br />

                    iii. Communicating Cancellations<br />  <br />

                    If you are unable to complete a transaction, you must notify the buyer via Bazaar Messages and cancel the transaction. If the buyer already
                    submitted payment, you must issue a full refund. You are encouraged to keep proof of any refunds in the event a dispute arises. All
                    cancellations are subject to our Cancellation Policy.<br />  <br />

                    Buyers are entitled to a 14‐day “right of withdrawal” after receiving an item, during which they may return an item for any reason. The right of
                    withdrawal may not apply to certain items like custom items or perishable goods.<br />  <br />

                    Please be aware that in addition to this policy, each country has its own laws surrounding shipping, cancellations, returns, and exchanges.
                    Please familiarize yourself with the laws of your own country and those of your buyers’ countries.<br />  <br />

                </p>

                {/* 3. Feedback, Disputes and Your Success */}
                <h1>3. Feedback, Disputes and Your Success</h1>
                <h2>a. Reviews</h2>
                <p>
                    Reviews are a great way for you to build a reputation on Bazaar. Buyers can leave a review, including a one to five star rating and a photograph
                    of their purchase, within 100 days after their item’s estimated delivery date. If an estimated delivery date is not available, the review window
                    opens after the order’s processing time and shipping time have elapsed. Buyers can edit their review, including the photograph, any number of
                    times during that 100 day period.<br />  <br />

                    On the rare occasion you receive an unfavorable review, if the review is less than 3 stars, leave a response.  <br />  <br />

                    Reviews and your response to reviews may not:<br />  <br />
                    1. Contain private information;<br />
                    2. Contain obscene, racist, or harassing language or imagery;<br />
                    3. Violate our Anti‐Discrimination Policy;<br />
                    4. Contain prohibited medical drug claims;<br />
                    5. Contain advertising or spam;<br />
                    6. Be about things outside the seller’s control, such as a shipping carrier, Bazaar or a third party;<br />
                    7. Contain threats or extortion;<br />
                    8. Include shilling or otherwise falsely inflate a shop’s review score; or  <br />
                    9. Undermine the integrity of the Reviews system.<br /><br />

                    <h2>b. Bazaar’s Case System</h2>

                    The Case system is how order disputes are resolved at Bazaar. We ask buyers to contact sellers through bazaar and attempt to resolve any
                    outstanding issues. For this reason, it is important that you fill out your shop policies and regularly respond to Messages from your buyers.
                    However, sometimes a case may be necessary, and Bazaar will work with you and the buyer to resolve it as quickly as possible.  <br /><br />

                    Buyers may file a case for a non‐delivery or a not‐as‐described item (for more information on how we define these terms). You must respond to
                    any open cases within three days or the time frame noted by Bazaar in the case. Bazaar may request your assistance in resolving a case opened
                    against your shop. Bazaar reserves the right to escalate a case early for circumstances such as seller inactivity, harassment, refusal of service,
                    case manipulation, and undermining the integrity of the case system.<br /><br />

                    By using Bazaar’s case system, you understand that Bazaar may use your personal information for the purpose of resolving disputes with other
                    members. For more information on cases and when an order might qualify for a case. If Bazaar determines that an item is not as described, you
                    will be required to refund the order, including original shipping and return shipping.<br /><br />

                    <h2>c. Your Seller Account and Bazaar’s Terms</h2>

                    In order to keep Bazaar safe and improve our Services, we may take actions that limit the visibility of your shop, listings or ads, or that impact
                    your payment account. In the event a shop sees unusual order activity, or we otherwise believe that your actions or shop may result in buyer
                    disputes, chargebacks, increased risk of fraud, counterfeiting, or other claims, Bazaar may take actions such as limiting visibility of your account,
                    or placing restrictions or reserves on your payments account, in accordance with our Terms of Service, including this Policy and our Bazaar
                    Payments Policy. When appropriate and permitted by law, Bazaar will communicate information to the affected seller about the issue.  <br /><br />

                    In the event a shop sees an unusual spike in orders, particularly in a high demand category, a shop may see an increase or decrease in its search
                    ranking. Often, if a shop sees an increase in fulfilled orders and good reviews, this may result in higher visibility and search rank. However,
                    sometimes a rapid increase in orders can reduce visibility. And what factors impact search ranking, and how you can help optimize your listings
                    and shop<br /><br />

                    In addition, we may limit the visibility of listings or ads in the interest of keeping Bazaar safe, and improving our Services. For example, listings
                    or ads may have decreased visibility because they include terms that represent a prohibited item or based on third party policies. These listings
                    or ads may also be restricted from appearing in one or more features of the Bazaar Services. While these listings or ads may have limited
                    visibility, they are still discoverable in search.<br /><br />

                    If Bazaar has reason to believe you, Your Content, or your use of the Services violate our Terms, including this Seller Policy, we may deactivate
                    Your Content or suspend or terminate your account (and any accounts Bazaar determines is related to your account) and your access to the
                    Services. Generally, Bazaar will notify you that Your Content or account has been suspended or terminated, unless you’ve repeatedly violated
                    our Terms or we have legal or regulatory reasons preventing us from notifying you.<br /><br />

                    <h2>d. Seller Protection  </h2>
                    Bazaar also provides limited protection to sellers who meet the requirements of our Seller Protection Program.<br /><br />
                </p>


            </div>
            <a className="d-flex justify-content-center fs-4 text-muted fw-bold">
                Last updated on &nbsp;<b>Jan 07, 2021</b>
            </a>
        </div>

    )
};

export default SellerPolicy;