import React from 'react';
import { connect } from 'react-redux';
import { checkVerificationCode } from '../actions/auth';

export class VerifyPhonePage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            error: '',
            successMsg: '',
            success: false
        }


    }

    onCodeChange = (e) => {
        var code = e.target.value;
        // if (!code || code.match(/^\d{1,}(\.\d{0,0})?$/))   //only take numbers with two decimals ex: 100.00
        //     this.setState(() => ({ code }));

        if (!code || code.match(/^[0-9]{0,4}$/))
            this.setState(() => ({ code }));
    }

    onSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.code || this.state.code.length < 4) {
            this.setState({ error: 'Please provide a 4-digits code' });
        }
        else {

        }
    }

    render() {
        return (
            <div className="content-container">
                <div className="center">
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="simple-col">
                            <h2>Enter phone verification code</h2>
                            <input
                                className="phone-verification-input"
                                type="text"
                                placeholder="4-digit code"
                                value={this.state.code}
                                onChange={this.onCodeChange}
                            />

                            <button className="button phone-verification-button" onClick={async () => {
                                const result = await this.props.checkVerificationCode(this.state.code, this.props.verificationCode, this.props.userId);
                                if (result) {
                                  //  this.props.phoneVerified = true;
                                    this.setState({
                                        successMsg: 'Success, your phone is now verified',
                                        success: true
                                    });
                                }
                            }}>Submit</button>

                            {
                                this.state.success &&
                                <div className="simple-col">
                                    <h2>{this.state.successMsg}</h2>
                                    <button className="button phone-verification-button" onClick={() => {
                                        this.props.history.push('/home-page');
                                    }}>Continue</button>
                                </div>
                            }
                        </div>

                    </form>
                </div>

            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkVerificationCode: (code, verificationCode, userId) => dispatch(checkVerificationCode(code, verificationCode, userId))
    }
}

const mapStateToProps = (state, props) => {
    return {
        verificationCode: state.auth.verificationCode,
        userId: state.auth.id,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhonePage);