import React from 'react';
import { sendPasswordReset } from '../actions/auth';
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css"



export default class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            t: props.t,
            phoneNumber: '',
            error: '',
            title: 'Login',
        }
    }

    onPhoneChange = (e) => {
        const phoneNumber = e.target.value;
        this.setState(() => ({ phoneNumber }));
    }


    onSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.phoneNumber) {
            this.setState(() => ({ error: this.state.t("VerifyForm.4") }));
        }
        else {
            this.setState(() => ({ error: '' }));
            try {
                this.props.onSubmit({
                    phoneNumber: "+2" + this.state.phoneNumber,
                    error: this.state.error
                }, this.setState = this.setState.bind(this));
            }
            catch (e) {
            }

        }
    }

    render() {
        return (
            <div>
                {
                    <form className="form" onSubmit={this.onSubmit}>
                        <input
                            className="text-input"
                            type="text"
                            placeholder={this.state.t("LoginForm.5")}
                            value={this.state.phoneNumber}
                            onChange={this.onPhoneChange}
                        />
                        <a className="error-msg">{this.state.error}</a>
                        <button className="button">{this.state.t("LoginForm.2")}</button>
                    </form>
                }

            </div>

        )
    }
}

