import React from 'react';
import CartListItem from './CartListItem';
import { useTranslation } from 'react-i18next';

export const OrderItemsList = (props) => {
    
    const { t } = useTranslation();
   
    return(
    <div className="content-container">
        <div className="list-body">
            {
                (
                    <div>
                        <h1> {t("CartList.2")}</h1>
                        <div className="vertical-list">
                            {
                                props.orderItem.orderProducts ?
                                props.orderItem.orderProducts.map(
                                    
                                    (order) => {
                                        return <CartListItem key={order.id} orderId={props.orderItem.id} productId={order.id}  {...order} id={order.productDocId} type="orderItem" _status={order.status} />
                                    }
                                ) : null
                            }
                        </div>

                    </div >
                )
            }
        </div>


    </div >
)};


//export default connect(mapStateToProps, mapDispatchToProps)(OrderItemsList);
