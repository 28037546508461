import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import ExpenseListItem from './ExpenseListItem';
import selectExpenses from '../selectors/expenses';
import ExpenseListFilter from './ExpenseListFilter';
import ProductListItemCustomer from './ProductListItem-Customer';
import CartListItem from './CartListItem';
import { useTranslation } from 'react-i18next';
import PaginationNavigator from './widgets/PaginationNavigator';
import { startSetExpensesShop, clearLastProducts } from '../actions/expenses';

const ExpenseList = (props) => {

    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState(0);

    const forwardClick = () => {
        setCurrentPage(currentPage + 1);
        props.getProducts('forward');
    }

    const backwardClick = () => {
        setCurrentPage(currentPage - 1);
        props.getProducts('backward');
    }

    useEffect(() => {
        if (currentPage == 0) {
            props.getProducts(undefined);
            props.clearLastProduct()
        }
    }, [props.expenses.length]);

    return (
        <div className="content-container">
            <div className={props.shopItem ? "list-body" : "list-body--customer"}>
                {
                    props.expenses.length === 0 ? (
                        <div className="list-item list-item--message">
                            <span>{t("CartList.0")}</span>
                        </div>
                    ) : (
                            <div>
                                <div className={!props.shopItem ? "horizontal-list" : ""}>
                                    {
                                        props.expenses.map(
                                            (product) => {
                                                return props.shopItem
                                                    ? <ExpenseListItem key={product.id} {...product} shopItem={props.shopItem} />
                                                    : <ProductListItemCustomer key={product.id} product={product} />;
                                            }
                                        )
                                    }

                                </div>

                            </div >
                        )
                }
            </div>

            {
                //Just to be safe
                props.shopItem &&
                <PaginationNavigator forwardClick={forwardClick} backwardClick={backwardClick} currentPage={currentPage} productsLength={props.expenses.length} />
            }
        </div >
    )
};

const mapStateToProps = (state, props) => {
    return {
        expenses: props.shopItem ? selectExpenses(state.expenses.shopProducts, state.filters) : selectExpenses(state.expenses.marketProducts, state.filters)
    };
};

const mapDispatchToProps = (dispatch) => ({
    getProducts: (nextPage) => dispatch(startSetExpensesShop(nextPage)),
    clearLastProduct: () => dispatch(clearLastProducts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseList);
