import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next';

import "../styles/home.css";

import Helmet from "../components/Helmet/Helmet";
import heroImg from "../assets/images/hero-img-3.png";
import counterImg from "../assets/images/counter-timer-img.png";

import products from "../assets/data/products";
import Services from "../components/services/services";
import ProductsList from "../components/UI/ProductList";
import Clock from "../components/UI/Clock";
import { startGetProductsList } from "../actions/expenses";

const Home = (props) => {
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [bestSalesProducts, setBestSalesProducts] = useState([]);
  const [mobileProducts, setMobileProducts] = useState([]);
  const [wirelessProducts, setWirelessProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);
  const { t, i18n } = useTranslation();

  const year = new Date().getFullYear();

  useEffect(() => {
    if (
      !props.trendingProducts.length ||
      !props.bestSalesProducts.length ||
      !props.newArrivalsProducts.length ||
      !props.popularCategoryProducts.length
    ) {
      props.startGetProductsList();
    }

    setTrendingProducts(props.trendingProducts);
    setBestSalesProducts(props.bestSalesProducts);
    setWirelessProducts(props.newArrivalsProducts);
    setPopularProducts(props.popularCategoryProducts);
  }, [
    props.trendingProducts,
    props.bestSalesProducts,
    props.newArrivalsProducts,
    props.popularCategoryProduct,
  ]);

  return (
    <Helmet title={"Home"}>
      <section className="hero__section">
        <Container>
          <Row>
            <Col lg="8" md="8">
              <div className="hero__content">

                <p className="hero__subtitle"> {t("homeHero.0")}</p>
                <h2> {t("homeHero.1")}</h2>
                <p>
                  {t("homeHero.2")}
                </p>
                <button className="buy__btn">
                  <Link to="/products">{t("homeHero.3")}</Link>
                </button>
              </div>
            </Col>

            <Col lg="4" md="4" className="text-left">
              <div className="hero__img">
                <img src={heroImg} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Services t={t} />

      <section className="trending__products">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title">{t("ProductListCustomer.2")}</h2>
            </Col>
            <ProductsList t={t} i18n={i18n} data={trendingProducts} />
          </Row>
        </Container>
      </section>

      <section className="best__sales">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title">{t("ProductListCustomer.3")}</h2>
            </Col>
            <ProductsList t={t} i18n={i18n} data={bestSalesProducts} />
          </Row>
        </Container>
      </section>

      {/* <section className="timer__count">
        <Container>
          <Row>
            <Col lg="6" md="12" className="cout__down-col">
              <div className="clock__top-content">
                <h4 className="fs-6 md-2">Limited Offers</h4>
                <h3 className="fs-5 mb-3">Quality Armchair</h3>
              </div>
              <Clock />
              <button className="buy__btn store__btn">
                <Link to="/products">Visit Store</Link>
              </button>
            </Col>
            <Col lg="6" md="12" className="text-end counter__img">
              <img src={counterImg} alt="" />
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="new__arrivals">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title">{t("ProductListCustomer.4")}</h2>
            </Col>
            <ProductsList t={t} i18n={i18n} data={mobileProducts} />
            <ProductsList t={t} i18n={i18n} data={wirelessProducts} />
          </Row>
        </Container>
      </section>

      <section className="popular__category">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title">{t("ProductListCustomer.5")}</h2>
            </Col>
            <ProductsList t={t} i18n={i18n} data={popularProducts} />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const mapStateToProps = (state, props) => {
  return {
    trendingProducts: state.expenses.trendingProducts,
    newArrivalsProducts: state.expenses.newArrivalsProducts,
    popularCategoryProducts: state.expenses.popularCategoryProducts,
    bestSalesProducts: state.expenses.bestSalesProducts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startGetProductsList: () => dispatch(startGetProductsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
