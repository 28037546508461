import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import expensesReducer from '../reducers/expenses';
import filtersReducer from '../reducers/filters';
import authReducer from '../reducers/auth';
import shopReducer from '../reducers/shop';
import wishlistReducer from '../reducers/wishlist';
import cartReducer from '../reducers/cart';
import ordersReducer from '../reducers/orders';
import shopOrdersReducer from '../reducers/shopOrders';
import categoriesReducer from '../reducers/categories';
import categoriesFilterReducer from '../reducers/categoriesFilter';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    //store creation
    const store = createStore(
        combineReducers({
            expenses: expensesReducer,
            filters: filtersReducer,
            auth: authReducer,
            shop: shopReducer,
            wishlist: wishlistReducer,
            cart: cartReducer,
            orders: ordersReducer,
            shopOrders: shopOrdersReducer,
            categories: categoriesReducer,
            categoriesFilter: categoriesFilterReducer 
        }),
        composeEnhancers(applyMiddleware(thunk))
        //   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    return store;
}

