import React from 'react';
import "../../styles/terms-condition.css";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const BuyerPolicy = () => {

    const { t } = useTranslation();

    return (
        <div className="terms content-container">
            <h1 className='title'>
                Buyer Policy
            </h1>

            <h4>
                Bazaar is a venue where you can purchase unique goods from sellers in Egypt. Whether you are looking for handmade or vintage goods or craft
                supplies, we want you to have a positive experience shopping on Bazaar. Please read on to find out more about your rights, as well as what is
                expected of you, as a buyer.<br /><br />
            </h4>
            <p>
                This policy is a part of our <span className="link-underline"> <Link onClick={() => window.open('/termsAndConditions')}> Terms and Conditions.</Link></span>  By shopping on Bazaar, you’re agreeing to this policy and our Terms and Conditions.
            </p>

            <div>
                1. Understanding Bazaar’s Limitations as a Venue   <br />
                2. Communicating with Other Bazaar Members   <br />
                3. Purchasing an Item on Bazaar <br />
                4. Leaving a Review of an Item<br />
                5. Creating and Uploading Content  <br />
                6. Reporting a Problem with an Order or Returning an Item<br />
            </div>

            <div>
                {/* 1. Understanding Bazaar’s Limitations as a Venue */}
                <h1>1. Understanding Bazaar’s Limitations as a Venue</h1>
                <p>
                    Bazaar provides a venue for buyers to discover and purchase from sellers in Egypt. It is important to note that Bazaar is not a part of that
                    transaction. By shopping on Bazaar, you understand that: <br /><br />
                    1. You are not buying directly from Bazaar, but from one of the many talented sellers on Bazaar <br />
                    2. Bazaar does not pre‐screen sellers and therefore does not guarantee or endorse any items sold on Bazaar or any content posted by
                    sellers (such as photographs or language used in listings or shop policies) <br />
                    3. Each seller on Bazaar has their own processing times, shipping methods, and shop policies; and <br />
                    4. You assume responsibility if you provide your own materials for a custom order.<br /><br />

                    As a member of the community, you have the opportunity to flag an item or a shop that violates any of Bazaar’s policies. Flagging is
                    confidential.

                </p>


                {/* 2. Communicating with Other Bazaar Members */}
                <h1>2. Communicating with Other Bazaar Members</h1>
                <h2>Messages</h2>
                <p>
                    You can use Bazaar’s Messages (“Messages”) tool to communicate with other Bazaar members. Messages are a great way to ask sellers any
                    questions you have about an item or an order. Messages may not be used for the following activities: <br /><br />

                    1. Sending unsolicited advertising or promotions, requests for donations, or spam; <br />
                    2. Harassing or abusing another member or otherwise violating our <span className="link-underline"> <Link onClick={() => window.open('/anti-discrimination-policy')}>Anti‐Discrimination Policy</Link> </span> <br />
                    3. Contacting someone after they have explicitly asked you not to; or <br />
                    4. Interfering with a transaction or the business of another member.  <br />

                    <span><h3>Interference</h3></span>
                    Interference occurs when a member intentionally interferes with another member’s shop in order to drive away their business. Interference is
                    strictly prohibited on Bazaar. Examples of interference include:<br /><br />
                    1. Contacting another member via Bazaar Messages to warn them away from a particular member, shop, or item;<br />
                    2. Posting in public areas to demonstrate or discuss a dispute with another member;  <br />
                    3. Purchasing from a seller for the sole purpose of leaving a negative review;<br />
                    4. Maliciously clicking on a competitor's Promoted Listings ads in order to drain that member's advertising budget, also known as "click
                    fraud."


                    <span><h3>Harassment</h3></span>
                    Any use of Bazaar Messages to harass other members is strictly prohibited. Similarly, Messages may not be used to support or glorify hatred or
                    otherwise violate our <span className="link-underline"> <Link onClick={() => window.open('/anti-discrimination-policy')}>Anti‐Discrimination Policy</Link> </span>. If you receive a Message that violates this policy, please let us know right away.
                </p>

                {/* 3. Purchasing an Item on Bazaar */}
                <h1>3. Purchasing an Item on Bazaar</h1>
                <p>
                    When you buy from a shop on Bazaar, you’re directly supporting an independent business, each with its unique listings, policies, processing
                    times, and payment systems. By making a purchase from a seller on Bazaar, you agree that you have:<br /><br />
                    1. Read the item description and shop policies before making a purchase;  <br />
                    2. Submitted appropriate payment for item(s) purchased; and<br />
                    3. Provided accurate shipping information to the seller.<br /><br />

                    You also agree to comply with our Bazaar Payments Policy when you use Bazaar Payments, and our Gift Cards & Bazaar Credits Policy when you
                    purchase or redeem Bazaar Gift Cards or Bazaar Credits.

                </p>

                {/* 4. Leaving a Review of an Item */}
                <h1>4. Leaving a Review of an Item</h1>
                <p>
                    Reviews are a great way to learn about a seller’s items, help good sellers build a strong reputation, or help warn other buyers about a poor
                    experience<br /><br />
                    You can leave a review, including a one to five star rating and a photograph of your purchase, for 100 days after your item’s estimated delivery
                    date. If an estimated delivery date is not available, the review window opens after the order’s processing time and shipping time have elapsed.
                    You can edit your review, including the photograph, any number of times during that 100 day period. Your review and/or photograph and
                    profile information will be publicly displayed on the seller's listing and review pages.  <br /><br />
                    By leaving a review or photograph, you acknowledge that your content may not:  <br /><br />

                    1. Contain private information;  <br />
                    2. Contain obscene, racist, or harassing language or imagery;  <br />
                    3. Violate our Anti‐Discrimination Policy;  <br />
                    4. Contain prohibited medical drug claims; <br />
                    5. Contain advertising or spam;  <br />
                    6. Be about things outside the seller’s control, such as a shipping carrier, Bazaar, or a third party;  <br />
                    7. Contain threats or extortion;<br />
                    8. Include shilling or otherwise falsely inflate a shop’s review score; or  <br />
                    9. Undermine the integrity of the Reviews system.   <br />  <br />

                    By uploading a photograph to one of Bazaar’s websites or Bazaar's mobile app, you warrant that: <br />  <br />

                    1. You own the photograph or you have the rights or permission to use the photograph; and  <br />
                    2. You understand that, as stated in Bazaar’s Terms and Conditions, Bazaar has license to use any content you provide to Bazaar.  <br /><br />

                    Sellers may respond to reviews of three or fewer stars. Sellers’ responses to reviews must also comply with this policy. Sellers may hide
                    photographs that they do not feel accurately represent their brand, or they may report reviews that violate our <span className="link-underline"> <Link onClick={() => window.open('/termsAndConditions')}> Terms and Conditions.</Link></span>  <br /><br />

                    We reserve the right to remove reviews or photographs that violate our policies or Terms and Conditions.   <br />

                </p>

                {/* 5. Creating and Uploading Content */}
                <h1>5. Creating and Uploading Content</h1>
                <p>
                    As a member of Bazaar, you have the opportunity to create and upload a variety of content, like Messages, text, photos, and videos. In order to
                    keep our community safe and respectful, you agree that you will not upload content that is:<br /><br />

                    1. Abusive, threatening, defamatory, harassing, or otherwise in violation of our Anti‐Discrimination Policy;  <br />
                    2. Obscene or vulgar;  <br />
                    3. In violation of someone else’s privacy or intellectual property rights; or  <br />
                    4. False, deceptive, or misleading.  <br />
                </p>

                {/* 6. Reporting a Problem with an Order or Returning an Item */}
                <h1>6. Reporting a Problem with an Order or Returning an Item</h1>
                <h2>Bazaar’s Case System</h2>
                <p>
                    Although Bazaar is not directly involved in a transaction between a buyer and a seller, we provide a case system in the unlikely event that your
                    order does not go as expected. By using Bazaar’s case system, you understand that Bazaar may use your personal information for the purpose
                    of resolving disputes with other members. You can use Bazaar’s case system to come to a resolution with the seller in the event of a non‐
                    delivery or if an item you receive is not as described in the listing. If you choose a refund as your preferred resolution, keep in mind that Bazaar
                    only allows on‐platform refunds for a period of 180 days post‐transaction. After this 180 window has elapsed, Bazaar cannot support on‐
                    platform refunds for your order. However, you may still communicate with the seller through bazaar to come to an off‐platform resolution.
                    Refunds issued by Bazaar will be in your original form of payment, and if such payment is not available, an Bazaar credit will be issued.

                    <span><h3>Non‐Delivery</h3></span>
                    A non‐delivery occurs when a buyer places an order but does not receive the item. The following are examples of non‐delivery cases:  <br /><br />
                    1. There is no proof that the item was shipped to the buyer. <br />
                    2. An item was not sent to the address provided on Bazaar.<br />

                    <span><h3>Not as Described</h3></span>
                    An item is not as described if the buyer can demonstrate that it is significantly different from the seller’s listing description or photos. Here are
                    a few examples of not as described cases:<br /><br />

                    1. The item received is a different color, model, version, or size.<br />
                    2. The item has a different design or material.<br />
                    3. The seller failed to disclose that an item is damaged or is missing parts.<br />
                    4. The buyer received the incorrect quantity of items (e.g., the buyer purchased three items but only received two).<br />
                    5. The item was advertised as authentic but is not authentic.<br />
                    6. The condition of the item is misrepresented (e.g., the item is described as new but is used).<br /><br />

                    Not as described cases can also be filed for late delivery. In order to qualify as late delivery, the buyer must provide proof that all of these
                    conditions have been met:<br /><br />

                    1. The item(s) were ordered for a specific date or event.<br />
                    2. The item(s) are rendered useless after that date.<br />
                    3. The seller did not ship the item(s) according to their processing time or the date agreed upon in Messages.<br /><br />

                    If Bazaar determines that an item is not as described, the seller will be required to refund the order, including original shipping and return
                    shipping. In the event that Bazaar needs to refund the return shipping cost on behalf of the seller, that refund may come in the form of an
                    Bazaar Credit. For more information about Bazaar Credits.

                    <span><h3>Ineligible Transactions</h3></span>
                    Some disputes don’t qualify for Bazaar’s case system. These include:<br /><br />

                    1. Items that are damaged by shipping carrier (if properly packaged by the seller).<br />
                    2. Items that have been altered, used, worn, washed, or discarded after receipt.<br />
                    3. Items that are received after the agreed‐upon delivery date due to shipping delays.<br />
                    4. Items that are returned without a return agreement.<br />
                    5. Items that are accurately described but don’t meet a buyer's expectations.<br />
                    6. Cost of shipping disputes.<br />
                    7. Items that are purchased in person.<br />
                    8. Items prohibited from sale on Bazaar, including services and intangible goods.<br />
                    9. Transactions where payment is not made via Bazaar’s checkout system.<br />

                    <span><h2>Requesting a Cancellation</h2></span>
                    Only sellers may cancel transactions. Buyers may request that a seller cancel an order via Bazaar Messages. Note that all cancellations must
                    comply with our Anti‐Discrimination Policy.

                    <span><h2>Returning an Item</h2></span>
                    Each seller has his or her own return policies, which should be outlined in their Shop Policies. Not all sellers accept returns. You may be entitled
                    to a 14‐day “cooling off period,” or a “right of withdrawal,” during which you may return an item for any reason.<br /><br />
                </p>
            </div>
            <a className="d-flex justify-content-center fs-4 text-muted fw-bold">
                Last updated on &nbsp;<b>Jan 07, 2021</b>
            </a>
        </div>

    )
};

export default BuyerPolicy;