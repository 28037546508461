import { useTranslation } from "react-i18next";
import React from "react";
import "./change-languages.css";

function ChangeLanguage() {
    const { t, i18n } = useTranslation();

    function handleClick(lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <div className="language-selector">
            <nav>
                {i18n.language === "ar" && (
                    <a
                        style={{ fontSize: 12 }}
                        className="header-language"
                        onClick={() => handleClick("en")}
                    >
                        English
                    </a>
                )}
                {i18n.language === "en" && (
                    <a
                        style={{ fontSize: 12 }}
                        className="header-language"
                        onClick={() => handleClick("ar")}
                    >
                        العربية
                    </a>
                )}
            </nav>
        </div>
    );
}

export default ChangeLanguage;