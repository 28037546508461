import db from "../firebase/firebase";
import firebase from "firebase/app";
import { PAGE_SIZE } from "../utility/constants";

//Add expense
export const AddExpense = (expense) => ({
  type: "ADD_EXPENSE",
  expense,
});

export const startAddExpense = (productData = {}) => {
  return (dispatch, getState) => {
    const {
      title = "",
      description = "",
      titleAR = "",
      descriptionAR = "",

      details = "",
      detailsAR = "",

      price = 0,
      publishedPrice = 0,
      inStock = 0,
      madeWithin = 0,
      rating = 0,
      numReviews = 0,
      WR = 0,

      madeByUser = false, //bool
      madeToOrder = false,

      madeDate = "",
      creationDate = new Date().toISOString(),

      category = "",
      mainCategory = "",
      categories = [],

      url1 = "", //first gets assigned from local, then after uploads
      url2 = "",
      url3 = "",
      url4 = "",
      uid = getState().auth.uid,
      shopId = getState().shop.id,

      pending = true,
      accepted = false,
      rejected = false,

      promoCode = "",
    } = productData;
    //    const shop = getState().shop.uid;
    //    publishedPrice = price * 1.15;
    const productToPush = {
      title,
      description,

      titleAR,
      descriptionAR,

      details,
      detailsAR,

      price,
      publishedPrice,
      inStock,
      madeWithin,

      madeByUser, //bool
      madeToOrder,
      madeDate,
      creationDate,

      mainCategory,
      category,
      categories,

      url1, //first gets assigned from local, then after uploads
      url2,
      url3,
      url4,

      uid,
      shopId,

      pending,
      accepted,
      rejected,
      rating,
      numReviews,
      WR,

      promoCode,
    };

    try {
      db.collection("products")
        .add(productToPush)
        .then((value) => {
          dispatch(
            AddExpense({
              id: value.id,
              ...productToPush,
            })
          );
        });
    } catch (e) {
    }
  };
};

export const RemoveExpense = ({ id } = {}) => ({
  type: "REMOVE_EXPENSE",
  id,
});

export const editExpense = (id, updates) => ({
  type: "EDIT_EXPENSE",
  id,
  updates,
});

export const startEditExpenses = (id, updates) => {
  return (dispatch, getState) => {
    db.collection("products")
      .doc(id)
      .update(updates)
      .then(() => {
        dispatch(editExpense(id, updates));
      })
      .catch((e) => {});
  };
};

//Set expenses
export const setShopExpenses = (shopProducts) => ({
  type: "SET_SHOP_PRODUCTS",
  shopProducts,
});

//get shop products
export const startSetExpensesShop = (_nextPage) => {
  return async (dispatch, getState) => {
    const id = getState().shop.id;
    if (id == null) return;

    const lastProduct = getState().expenses.lastShopProduct;
    const firstProduct = getState().expenses.firstShopProduct;
    const pageSize = PAGE_SIZE;
    var ref = db
      .collection("products")
      .where("shopId", "==", id)
      .limit(pageSize);
    if (lastProduct !== null && lastProduct !== undefined) {
      if (_nextPage) {
        if (_nextPage === "forward") {
          ref = nextPage(lastProduct, pageSize, ref);
        } else {
          if (firstProduct) {
            ref = prevPage(firstProduct, pageSize, ref);
          }
        }
      }
    }

    try {
      const snapshot = await ref.get();
      const shopProducts = [];
      const docs = snapshot.docs;
      for (var i = 0; i < docs.length; i++) {
        const doc = docs[i];
        const data = doc.data();
        shopProducts.push({
          id: doc.id,
          ...data,
        });

        if (i === 0) dispatch(setFirstShopProduct(doc));
        else if (i === docs.length - 1) {
          dispatch(setLastShopProduct(doc));
        }
      }

      dispatch(setShopExpenses(shopProducts));
    } catch (error) {
      console.error(error);
    }
  };
};

export const setMarketExpenses = (marketProducts) => ({
  type: "SET_MARKET_PRODUCTS",
  marketProducts,
});

export const setCategoryProducts = (categoryProducts) => ({
  type: "SET_CATEGORY_PRODUCTS",
  categoryProducts,
});

export const setLastCategoryProduct = (product) => ({
  type: "SET_LAST_CATEGORY_PRODUCTS",
  product,
});
export const setFirstCategoryProduct = (product) => ({
  type: "SET_FIRST_CATEGORY_PRODUCTS",
  product,
});

export const setLastShopProduct = (product) => ({
  type: "SET_LAST_SHOP_PRODUCTS",
  product,
});
export const setFirstShopProduct = (product) => ({
  type: "SET_FIRST_SHOP_PRODUCTS",
  product,
});

export const clearLastProducts = () => ({
  type: "CLEAR_LAST_PRODUCTS",
});

const updateMakretProducts = (snapshot, dispatch) => {
  const marketProducts = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    const urls = [];
    if (data.url1 !== "") urls.push(data.url1);
    if (data.url2 !== "") urls.push(data.url2);
    if (data.url3 !== "") urls.push(data.url3);
    if (data.url4 !== "") urls.push(data.url4);
    marketProducts.push({
      id: doc.id,
      urls: urls,
      ...data,
    });
  });
  if (dispatch !== undefined) dispatch(setMarketExpenses(marketProducts));
  return marketProducts;
};
export const startSetProductsCustomer = () => {
  return async (dispatch, getState) => {
    var prodsGenRef = db.collection("products");
    var key = prodsGenRef.doc().id;
    const limit = 21;
    prodsGenRef
      .where("accepted", "==", true)
      .where("hasThumbnails", "==", true)
      .where(firebase.firestore.FieldPath.documentId(), ">=", key)
      .limit(limit)
      .get()
      .then((snapshot) => {
        if (snapshot.length < 0) {
          prodsGenRef
            .where("accepted", "==", true)
            .where(firebase.firestore.FieldPath.documentId(), "<", key)
            .limit(limit)
            .get()
            .then((snapshot) => {
              return updateMakretProducts(snapshot, dispatch);
            });
        } else {
        }
        return updateMakretProducts(snapshot, dispatch);
      });
  };
};

export const startSetProductsCategory = (
  mainCategory,
  category,
  _nextPage,
  limit
) => {
  return async (dispatch, getState) => {
    const lastProduct = getState().expenses.lastCategoryProduct;
    const firstProduct = getState().expenses.firstCategoryProduct;
    const pageSize = limit || PAGE_SIZE;
    var ref = db
      .collection("products")
      .where("categories", "array-contains", { mainCategory, category })

      .where("accepted", "==", true)
      .limit(pageSize);

    if (lastProduct !== null && lastProduct !== undefined) {
      if (_nextPage !== undefined) {
        if (_nextPage === "forward") {
          ref = nextPage(lastProduct, pageSize, ref);
        } else {
          if (firstProduct) {
            ref = prevPage(firstProduct, pageSize, ref);
          }
        }
      }
    }

    ref.get().then((snapshot) => {
      const categoryProducts = [];
      for (var i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        const data = doc.data();
        categoryProducts.push({
          id: doc.id,
          ...data,
        });

        if (i === 0) dispatch(setFirstCategoryProduct(doc));
        else if (i === snapshot.docs.length - 1)
          dispatch(setLastCategoryProduct(doc));
      }
      dispatch(setCategoryProducts(categoryProducts));
    });
  };
};

export const loadProduct = async (id) => {
  const doc = await db.collection("products").doc(id).get();
  const data = doc.data();
  const product = {
    id: doc.id,
    ...data,
  };
  if (product && product.shopId && product.id)
    addViews(product.shopId, product.id);
  return product;
};

export const getDateAsString = (dateAsString) => {
  var date = new Date(dateAsString);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const madeDate = dt + "/" + month + "/" + year;

  return madeDate;
};

const getIncrement = (amount) =>
  firebase.firestore.FieldValue.increment(amount);

export const addViews = async (shopId, productId) => {
  const now = new Date();
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDay());
  const dateISO = date.toISOString();

  db.collection("products")
    .doc(productId)
    .collection("analytics")
    .doc(dateISO)
    .set(
      {
        views: getIncrement(1),
      },
      {
        merge: true,
      }
    );

  db.collection("shops")
    .doc(shopId)
    .collection("analytics")
    .doc(dateISO)
    .set(
      {
        views: getIncrement(1),
      },
      {
        merge: true,
      }
    );
};

export const nextPage = (last, pageSize, ref) => {
  return ref.startAfter(last).limit(pageSize);
};

export const prevPage = (first, pageSize, ref) => {
  return ref.orderBy("WR").endBefore(first).limitToLast(pageSize);
};

export const getProductsListReducer = (productsList) => ({
  type: "GET_PRODUCTS",
  productsList,
});

export const getTrendingProductsReducer = (trendingProducts) => ({
  type: "GET_TRENDING_PRODUCTS",
  trendingProducts,
});

export const getBestSalesProductsReducer = (bestSalesProducts) => ({
  type: "GET_BEST_SALES_PRODUCTS",
  bestSalesProducts,
});

export const getNewArrivalsProductsReducer = (newArrivalsProducts) => ({
  type: "GET_NEW_ARRIVALS_PRODUCTS",
  newArrivalsProducts,
});

export const getPopularCategoryProductsReducer = (popularCategoryProducts) => ({
  type: "GET_POPULAR_CATEGORY_PRODUCTS",
  popularCategoryProducts,
});

export const startGetProductsList = () => {
  return async (dispatch) => {
    const pageSize = 20;
    try {
      // Get the total number of products
      const docCount = await db
        .collection("products")
        .get()
        .then((snapshot) => {
          return snapshot.size;
        });

      // Generate four random numbers in the range [0, docCount)
      const randomIndices = Array.from({ length: 4 }, () =>
        Math.floor(Math.random() * docCount)
      );

      // Construct a query for the randomly selected products
      const ref = db
        .collection("products")
        .orderBy(firebase.firestore.FieldPath.documentId())
        .startAt(randomIndices[0].toString())
        .limit(pageSize);

      const snapshot = await ref.get();
      const products = [];
      const docs = snapshot.docs;
      for (var i = 0; i < docs.length; i++) {
        const doc = docs[i];
        const data = doc.data();
        products.push({
          id: doc.id,
          ...data,
        });
      }

      dispatch(getTrendingProductsReducer(products.slice(0, 4)));
      dispatch(getBestSalesProductsReducer(products.slice(4, 8)));
      dispatch(getNewArrivalsProductsReducer(products.slice(8, 16)));
      dispatch(getPopularCategoryProductsReducer(products.slice(16, 20)));
    } catch (error) {
      console.error(error);
    }
  };
};

export const addReviewAction = (newReview) => ({
  type: "ADD_PRODUCT_REVIEW",
  newReview,
});

export const addProductNewReview = (productId, review) => {
  return async (dispatch, getState) => {
    try {

      if (Object.keys(review).length == 0) {
        return;
      }
      // Add the createdAt field to the new review and set its value to the current timestamp
      const newReview = {
        ...review,
        date: firebase.firestore.FieldValue.serverTimestamp(),
      };

      // Check if the "reviews" collection exists for the given product id
      const reviewsRef = db
        .collection("products")
        .doc(productId)
        .collection("reviews");
      const doc = await reviewsRef.doc().get();

      // Add the new review to the "reviews" collection for the given product id
      await reviewsRef.add(newReview);

      // Fetch the latest reviews for the product and update the state with the new reviews
      dispatch(addReviewAction(newReview));
    } catch (error) {
    }
  };
};

export const getReviewAction = (reviews) => ({
  type: "GET_PRODUCT_REVIEWS",
  reviews,
});

export const getProductReviews = (productId) => {
  return async (dispatch, getState) => {
    try {
      // Get the latest five reviews for the given product id from the "reviews" collection
      const reviewsSnapshot = await db
        .collection("products")
        .doc(productId)
        .collection("reviews")
        .orderBy("date", "desc")
        .limit(5)
        .get();

      // Map the reviews data to an array
      const reviews = reviewsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Dispatch an action to update the state with the list of reviews
      dispatch(getReviewAction(reviews));
    } catch (error) {
    }
  };
};
