import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    width: {
        [theme.breakpoints.down('md')]: {
            maxWidth: '32rem',
            height: "auto"
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '75rem',
            height: "auto"
        },
    },
}));


const ServiceFeePolicy = () => {

    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <div style={{ background: "#c9c83a", height: "100vh" }}>
            <div style={{paddingTop: '100px', paddingLeft: '20px'}} >
                <h1>
                    {t("ServiceFeePolicy.0")}
                </h1>

                {
                    i18n.language === "en" &&
                    <div>
                        <img className={classes.width} src="https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/ServiceFeePolicy%2FValue%20proposition_E.jpg?alt=media&token=ed143e31-e2a8-4cf9-8670-ba9be228b0db" />
                        <img className={classes.width} src="https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/ServiceFeePolicy%2FShipping%20and%20packaging_E.jpg?alt=media&token=a7cd5fa0-7155-430f-866c-0f46ce5fdb36" />
                    </div>
                }

                {
                    i18n.language === "ar" &&
                    <div>
                        <img className={classes.width} src="https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/ServiceFeePolicy%2FValue%20proposition_A.jpg?alt=media&token=68c0569a-1b88-4f75-8bad-3621bfc3f59a" />
                        <img className={classes.width} src="https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/ServiceFeePolicy%2FShipping%20and%20packaging_A.jpg?alt=media&token=4e1937b2-ee2b-47ba-bb26-a2364e551144" />
                    </div>
                }
            </div>
        </div>

    )
};

export default ServiceFeePolicy;