import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { setCategory } from '../../actions/categoriesFilter';
import { useTranslation } from 'react-i18next';

const Sidebar = (props) => {
    const [sidebarClass, setsidebarClass] = useState(props.sidebar);
    const [mainCategory, setMainCategory] = useState('');
    const [categories, setCategories] = useState([]);

    const { t, i18n } = useTranslation();

    const close = (e) => {
        e.preventDefault();
        setsidebarClass("sidebar-close");
        setTimeout(() => {
            props.close();
        }, 1000);
    }

    const mainCategoryClick = (category) => {
        const categoriess = props.categories[category];
        setMainCategory(category);
        setCategories(categoriess);
    }

    return (
        <div className={sidebarClass}>
            {
                props.type !== "shop" &&
                <div>
                    {
                        props.hasShop == "true" &&
                        <div className="categories-header">
                            <h3>{t("Sidebar.0")}</h3>
                            <CloseIcon fontSize="large" onClick={close} />
                        </div>
                    }
                    {
                        props.hasShop == "true" &&
                        <div>
                            <NavLink className="header__link" to="/shop-home" activeClassName="is-active">
                                <p>{t("HeaderShop.0")}</p>
                            </NavLink>
                            <NavLink className="header__link" to="/add-product" activeClassName="is-active">
                                <p>{t("HeaderShop.1")}</p>
                            </NavLink>
                            <NavLink className="header__link" to="/edit-shop" activeClassName="is-active">
                                <p>{t("HeaderShop.2")}</p>
                            </NavLink>
                            <NavLink className="header__link" to="/shop-orders" activeClassName="is-active">
                                <p>{t("HeaderShop.3")}</p>
                            </NavLink>
                            <NavLink className="header__link" to="/dashboard" activeClassName="is-active">
                                <p>{t("HeaderShop.4")}</p>
                            </NavLink>
                        </div>
                    }
                    <div className="categories-header">
                        <h3>{t("Sidebar.1")}</h3>
                        {props.hasShop != "true" && <CloseIcon fontSize="large" onClick={close} />}
                    </div>

                    <div>
                        {
                            categories.length > 0 ?
                                <div>
                                    <h3>{i18n.language === 'en' ? mainCategory : props.categoriesTr[mainCategory]}</h3>
                                    {
                                        categories.map((cat) => {
                                            var _cat = i18n.language === 'en' ? cat : props.categoriesTr[cat]
                                             return <NavLink key={mainCategory + _cat} className="category-item" to={`/products/${mainCategory}/${cat}`} activeClassName="is-active" onClick={() => props.setCategoryFilter(mainCategory, cat)}>
                                                <p>{_cat}</p>
                                            </NavLink>
                                        })
                                    }
                                </div>

                                :
                                props.categoriesKeys.map((cat) => {
                                    var _cat = i18n.language === 'en' ? cat : props.categoriesTr[cat]
                                    return <p onClick={() => mainCategoryClick(cat)} key={_cat}>{_cat}</p>
                                })
                        }

                    </div>
                </div>
            }

            {
                props.type === "shop" &&
                <div>
                    <div className="categories-header">
                        <h3>Shop options</h3>
                        <CloseIcon fontSize="large" onClick={close} />
                    </div>

                    <div>


                        <NavLink className="header__link" to="/shop-home" activeClassName="is-active">
                            <p>Dashboard</p>
                        </NavLink>

                        <NavLink className="header__link" to="/add-product" activeClassName="is-active">
                            <p>Add Product</p>
                        </NavLink>



                        <NavLink className="header__link" to="/edit-shop" activeClassName="is-active">
                            <p>Edit Shop</p>
                        </NavLink>



                        <NavLink className="header__link" to="/shop-orders" activeClassName="is-active">
                            <p>View Shop Orders</p>
                        </NavLink>



                        <NavLink className="header__link" to="/dashboard" activeClassName="is-active">
                            <p>My Products</p>
                        </NavLink>

                    </div>
                </div>
            }

        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        hasShop: state.auth.hasShop,
        categories: state.categories.categories,
        categoriesKeys: Object.keys(state.categories.categories),
        categoriesTr: state.categories.categoriesTr
    }
};

const mapDispatchToProps = (dispatch) => ({
    setCategoryFilter: (mainCategory, category) => dispatch(setCategory(mainCategory, category))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);