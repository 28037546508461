import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCategory } from '../actions/categoriesFilter';
import { useTranslation } from 'react-i18next';

export const CategoryDropdown = (props) => {

    const { t, i18n } = useTranslation();
    const subCategories = props.categories[props.title];

    const [titleTr, setTitle] = useState('');

    useEffect(() => {
        setTitle(i18n.language === 'en' ? props.title : props.categoriesTr[props.title]);
    })

    return (
        <div className="right-menu">
            <button className="menu-button">{titleTr}</button>
            <div className="dropdown-menu">
                {
                    subCategories != null ?
                    subCategories.map((subCat) => {
                        const subCatTr = i18n.language === 'en' ? subCat : props.categoriesTr[subCat]
                        return <NavLink key={props.title + subCat} className="category-item" style={{fontSize: 16}} to={`/products/${props.title}/${subCat}`} activeClassName="is-active" onClick={() => {props.setCategoryFilter(props.title, subCat)}}>
                            <p>{subCatTr}</p>
                        </NavLink>
                    }) : null
                }
            </div>
        </div>
    );

};


const mapStateToProps = (state) => ({
    categories: state.categories.categories,
    categoriesTr: state.categories.categoriesTr
})

const mapDispatchToProps = (dispatch) => ({
    setCategoryFilter: (mainCategory, category) => dispatch(setCategory(mainCategory, category))
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDropdown);


