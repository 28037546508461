import { useTranslation } from 'react-i18next';
import React from 'react';

function ChangeLanguage() {

  const { t, i18n } = useTranslation();

  function handleClick(lang) {
    i18n.changeLanguage(lang);
  }

  return (
    <div>
      <nav style={{ width: '80px' }}>

        {
          i18n.language === "ar" &&
          <a style={{ fontSize: 12 }} className="header_languange" onClick={() => handleClick('en')}>
            English
          </a>
        }
        {
          i18n.language === "en" &&
          <a style={{ fontSize: 12 }} className="header_languange" onClick={() => handleClick('ar')}>
            العربية
          </a>
        }
      </nav>
    </div>
  );
}

export default ChangeLanguage;
