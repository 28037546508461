import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { startRemoveFromOrders } from '../actions/orders';
import { getDateAsString } from '../actions/expenses';
import { useTranslation } from 'react-i18next';



const CustomerOrderItem = ({ id, title, creationDate, amount, status, cancelOrder, uuid }) => {

    const { t } = useTranslation();
    return (
        <Link className=" list-item--link border" to={`/order-details/${id}`}>
            <div >
                <div className="col-div">

                    <h2 className="list-item__title">{"Order id: " + uuid}</h2>
                    {/* <h2 className="list-item__title">{title}</h2>
                <h3 className="list-item__title">{status}</h3> */}
                    <h3 className="list-item__data">
                        {"Total cost: " + numeral(amount / 100).format('0,0.00') + " EGP"}
                    </h3>
                    <h4 className="list-item--message">{t("CustomerOrderItem.0")}</h4>

                    {
                        (creationDate != null && typeof creationDate === 'number') &&
                        <h3>
                            {"Ordered at " + getDateAsString(creationDate)}
                        </h3>
                    }

                </div>
            </div>
        </Link>


    )
};


export default connect()(CustomerOrderItem);