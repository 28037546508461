

export default (state = {}, action) => {
    switch(action.type){
        case 'CREATE_SHOP':
            return {
                ...state,
                ...action.shop
            };
            case 'EDIT_SHOP':
                return state.map((shop) => {
                    if(shop.id === action.id){
                        return{
                            ...shop,
                            ...action.updates
                        };
                    }else{
                        return shop;
                    }
                });
            case 'SET_SHOP':
                return action.shop;
            default:
                return state;
    }
};