import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StickyFooter from 'react-sticky-footer';
import { connect } from 'react-redux';
import { isAndroid, isIOS } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#BBBA3A',
        top: 0,
        position: 'sticky',
        //      marginTop: 'auto',

        zIndex: 1,
        height: '25px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
    // title: {
    //     marginLeft: 40,
    //     fontSize: 22,
    //     fontWeight: 'bold',
    //     [theme.breakpoints.up('md')]: {
    //         paddingTop: 40,
    //     },
    //     [theme.breakpoints.down('md')]: {
    //         paddingTop: 10,
    //     }
    // },
    // linkStyle: {
    //     textDecoration: 'none',
    //     color: 'black',
    //     fontWeight: 'bold'
    // },
    // linkStyle2: {
    //     textDecoration: 'underline',
    //     color: 'black',
    //     fontWeight: 'bold'
    // }
}));

const MobileStickyFooter = (props) => {


    const classes = useStyles();
    return (
        <StickyFooter
            bottomThreshold={-1}
            stickyStyles={{
                backgroundColor: "#BBBA3A",
                padding: "1.3rem",
                width: "100%",
            }}
        >
            {
                <div className={classes.row}>
                    <Typography style={{ fontSize: 14, marginTop: 7 }}>
                        Shop on our mobile app
                     </Typography>
                    <Button variant="contained" style={{ fontSize: 14,  background: "#e34870", color: "white" }}>
                        <a style={{ color: "white" }} href={props.link} target="_blank" rel="noopener noreferrer">Download</a>
                    </Button>
                </div>
            }
        </StickyFooter>
    )
}


const mapStateToProps = (state, props) => {
    
    const androidLink = "https://play.google.com/store/apps/details?id=com.bazaar.ecommerce_platform_market";
    const iosLink = "https://apps.apple.com/us/app/bazaar-seller/id1541364575"
    return {
        link: isAndroid ? androidLink : (isIOS ? iosLink : androidLink)
    }
}

export default connect(mapStateToProps)(MobileStickyFooter);
