import React from "react";
import "./footer.css";
import horus from '../../assets/images/favicon_nobackground.png'


import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation();
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col lg="4" className="mb-4" md="6">
                        <div className="logo">
                            <div>
                                <h1 className="text-white footer-title">bazaar</h1>
                            </div>
                        </div>
                        <p className="footer__text mt-4">
                            {t("Footer.0")}
                        </p>
                    </Col>

                    <Col lg="3" md="3" className="mb-4">
                        <div className="footer__quick-links">
                            <h4 className="quick__link-title text-white">{t("Footer.1")}</h4>
                            <ListGroup>
                                <ListGroupItem className="ps-0 boder-0">
                                    <a href="https://www.bazaar-eg.com/" target="_blank">{t("Footer.2")}</a>
                                </ListGroupItem>

                                <ListGroupItem className="ps-0 boder-0">
                                    <Link to="#">{t("Footer.3")}</Link>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                    </Col>

                    <Col lg="2" md="3" className="mb-4">
                        <div className="footer__quick-links">
                            <h4 className="quick__link-title text-white">{t("Footer.4")}</h4>
                            <ListGroup>
                                <ListGroupItem className="ps-0 boder-0">
                                    <Link to="/privacy-policy">{t("Footer.5")}</Link>
                                </ListGroupItem>

                                <ListGroupItem className="ps-0 boder-0">
                                    <Link to="/termsAndConditions">{t("Footer.6")}</Link>
                                </ListGroupItem>

                                {/* <ListGroupItem className="ps-0 boder-0">
                                    <Link to="/service-fee-policy">Service Fee Policy</Link>
                                </ListGroupItem> */}

                                <ListGroupItem className="ps-0 boder-0">
                                    <Link to="/seller-policy">{t("Footer.7")}</Link>
                                </ListGroupItem>

                                <ListGroupItem className="ps-0 boder-0">
                                    <Link to="/buyer-policy">{t("Footer.8")}</Link>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                    </Col>

                    <Col lg="3" md="4">
                        <div className="footer__quick-links">
                            <h4 className="quick__link-title text-white">{t("Footer.9")}</h4>
                            <ListGroup className="footer__contact">
                                <ListGroupItem className="ps-0 boder-0 d-flex align-items-center gap-2">
                                    <span>
                                        <i class="ri-facebook-fill"></i>
                                    </span>
                                    <a href="https://www.facebook.com/bazaar.handcrafts" target="_blank">{t("Footer.10")}</a>
                                </ListGroupItem>

                                <ListGroupItem className="ps-0 boder-0 d-flex align-items-center gap-2">
                                    <span>
                                        <i class="ri-whatsapp-line"></i>
                                    </span>
                                    <p>{t("Footer.11")}</p>
                                </ListGroupItem>

                                <ListGroupItem className="ps-0 boder-0 d-flex align-items-center gap-2">
                                    <span>
                                        <i class="ri-mail-fill"></i>
                                    </span>
                                    <p>admin@bazaar-egy.com</p>
                                </ListGroupItem>
                            </ListGroup>
                        </div>
                    </Col>

                    <Col lg="12">
                        <div className="footer__copyright">
                            <div className="logo-icon">
                                <img src={horus} alt="logo" style={{ width: "7rem"}} />                            </div>
                            <p className="footer__copyright-text">
                                {t("Footer.12")}
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
