import db from '../firebase/firebase';
import firebase from 'firebase/app';
import { startRemoveFromCart } from './cart';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

//Go to local database and add all order
export const addToOrders = (item) => ({
    type: 'ADD_ORDERS',
    item
});


export const startAddToOrders = (firstName, lastName, phone, address, email,cost) => {
    return async (dispatch, getState) => {
        const devURL = 'http://127.0.0.1:5001/bazaar-bc2e3/us-central1/onlinePayment';
        const prodURL = 'https://us-central1-bazaar-bc2e3.cloudfunctions.net/onlinePayment';
        const user = getState().auth;
        const userId = user.id;
        const uid = user.uid;

        const response = await axios.post(prodURL, {
            "authUid": uid,
            "userDocId": userId,
            "firstName": firstName,
            "lastName": lastName,
            "phone": phone,
            "address": address,
            "email": email,
            "cost": cost
        }, {
            headers: { 'Content-Type': 'application/json' }
        });

        const paymentToken = response.data.key;
        const iFrameId = 383195
        const iFrameUrl = `https://accept.paymobsolutions.com/api/acceptance/iframes/${iFrameId}?payment_token=${paymentToken}`
        window.location.replace(iFrameUrl)

    };
};

export const removeFromOrders = ({ id } = {}) => ({
    type: 'REMOVE_FROM_ORDERS',
    id
});

export const startRemoveFromOrders = ({ id }) => {
    return async (dispatch, getState) => {

        db.collection('orders').doc(id).update({
            status: 'cancelled'
        });

        db.collection('orders').doc(id).collection('orderedProducts').get().then(async (snapshot) => {
            const docs = snapshot.docs;
            for (var i = 0; i < docs.length; i++) {
                const doc = docs[i];
                await doc.ref.update({
                    status: 'cancelled'
                });
            }
        })

        dispatch(removeFromOrders({ id }));

    }
}

//Set expenses
export const setOrders = (orders) => ({
    type: 'SET_ORDERS',
    orders
});

//get shop products
export const startSetOrders = () => {
    return async (dispatch, getState) => {

        const id = getState().auth.id;

        if (id == null) return;

        db.collection('orders')
            .where('userDocId', '==', id)
            .where('status', '==', 'paid')
            // .orderBy('amount')
            .get()
            .then(async (snapshot) => {

                if (snapshot.docs.length == 0) return;

                const orders = [];
                for (var i = 0; i < snapshot.docs.length; i++) {

                    const mainDoc = snapshot.docs[i];
                    const mainDocData = mainDoc.data();
                    const orderProducts = [];

                    const snapshotDocs = await mainDoc.ref.collection('orderedProducts').get();
                    const docs = snapshotDocs.docs;

                    //get products
                    docs.forEach((doc) => {
                        const data = doc.data();
                        orderProducts.push({
                            docId: doc.id,
                            ...data
                        });
                    });

                    const order = {
                        id: mainDoc.id,
                        ...mainDocData,
                        orderProducts
                    };

                    orders.push(order);
                }

                dispatch(setOrders(orders));

            });
    };
};

export const refundOrderAction = (orderId, prodId) => ({
    type: 'REFUND_ORDER',
    orderId,
    prodId
});

export const refundOrder = (orderId, prodId, refundReason, refundOption) => {
    return async (dispatch, getState) => {
        try {

            // Update backend, add new field as reason
            const product = await db.collection('orders').doc(orderId).collection('orderedProducts').doc(prodId).update({
                status: 'Refund requested',
                refundReason,
                refundOption
            });

            // When done, use dispatch to update redux store (local database)
            dispatch(refundOrderAction(orderId, prodId));
        } catch (e) {
        }
    }
}

export const loadOrder = async (id) => {
    const mainDoc = await db.collection('orders').doc(id).get();

    if (id == null) return;

    const mainDocData = mainDoc.data();
    const orders = [];
    const orderProducts = [];

    const snapshotDocs = await mainDoc.ref.collection('orderedProducts').get();
    const docs = snapshotDocs.docs;

    //get products
    docs.forEach((doc) => {
        const data = doc.data();
        orderProducts.push({
            docId: doc.id,
            ...data
        });
    });

    const order = {
        id: mainDoc.id,
        ...mainDocData,
        orderProducts
    };


    orders.push(order);

    return orders;
}

const getIncrement = amount => firebase.firestore.FieldValue.increment(amount);

const updateOrderData = async (shopId, prodId, rev) => {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDay());
    const dateISO = date.toISOString();

    db
        .collection('shops')
        .doc(shopId)
        .collection('analytics')
        .doc(dateISO)
        .set({
            'sales': getIncrement(1),
            'revenue': getIncrement(rev)
        }, { merge: true });

    db
        .collection('products')
        .doc(prodId)
        .collection('analytics')
        .doc(dateISO)
        .set({
            'sales': getIncrement(1),
            'revenue': getIncrement(rev)
        }, { merge: true });

    db
        .collection('products')
        .doc(prodId)
        .update({
            'sales': getIncrement(1),
            'revenue': getIncrement(rev),
            'inStock': getIncrement(-1)
        });
}

