import React, { useState } from "react";
import { Link } from "react-router-dom";
import Helmet from "../Helmet/Helmet";
import "../../styles/auth.css";
import registerImg from "../../assets/images/register.svg";
import horusImg from "../../assets/images/favicon_nobackground.png";
import { toast } from "react-toastify";

const RegisterForm = ({
  firstName,
  setFirstName,
  secondName,
  setSecondName,
  email,
  setEmail,
  phoneNumber,
  setPhone,
  loading,
  handleSignupSubmit,
  t,
  i18n
}) => {
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleCheckboxChange = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const isFormValid = () => {
    return (
      firstName.trim() !== "" &&
      secondName.trim() !== "" &&
      email.trim() !== "" &&
      phoneNumber.trim() !== "" &&
      acceptedTerms
    );
  };

  return (
    <Helmet title="Signup">
      <section className="container auth">
        <div className="img">
          <img src={registerImg} alt="Login" />
        </div>

        <div className="form card">
          <div className="auth-header-logo img-register">
            <img src={horusImg} alt="" className="auth-header-logo-img" />
          </div>
          <h1 className="auth-header-title">{t("Signup.1")}</h1>
          <p className="auth-header-subtitle">
            {t("Signup.2")}
          </p>

          <form onSubmit={handleSignupSubmit}>
            <input
              type="text"
              placeholder={t("Signup.3")}
              required
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <input
              type="text"
              placeholder={t("Signup.4")}
              required
              value={secondName}
              onChange={(event) => setSecondName(event.target.value)}
            />
            <input
              type="text"
              placeholder={t("Signup.5")}
              required
              value={phoneNumber}
              onChange={(event) => setPhone(event.target.value)}
            />
            <input
              type="text"
              placeholder={t("Signup.6")}
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <div className="terms-and-conditions">
              <input
                type="checkbox"
                id="terms"
                checked={acceptedTerms}
                onChange={handleCheckboxChange}
                required
              />
              <label htmlFor="terms" className="terms-label">
                &nbsp;  {i18n.language === 'en' ? (
                  <span>
                    I accept the <Link to={`/termsAndConditions`}>terms and conditions.</Link>
                  </span>
                ) : (
                  <span>
                    أوافق على <Link to={`/termsAndConditions`}>الشروط والأحكام</Link>
                  </span>
                )} <span className="text-danger">*</span>
              </label>
            </div>
            <button
              className="--btn --btn-primary --btn-block"
              disabled={!isFormValid() || loading}
            >
              {loading ? t("Signup.7") : t("Signup.8")}
            </button>
            <div id="recaptcha-container"></div>
          </form>
          <span className="register">
            <p>{t("Signup.9")}</p>
            <Link to="/login">{t("Signup.10")}</Link>
          </span>
        </div>
      </section>
    </Helmet>
  );
};

export default RegisterForm;