//Get visible data
export default (expenses, {sortBy, endDate, minPrice, maxPrice }) =>{
    return expenses.filter((expense) => {  
        if(minPrice == -1 && maxPrice == -1)
            return true;

        const minPriceMatch = typeof minPrice !== 'number' || expense.publishedPrice >= minPrice;
        const maxPriceMatch = typeof maxPrice !== 'number' || expense.publishedPrice <= maxPrice;

        return  minPriceMatch && maxPriceMatch;
    }).sort((a, b) => {
       if(sortBy === 'amount'){
            return a.amount < b.amount ? 1 : -1;
        }else{
            return a.createStore < b.createStore ? 1 : -1;
        }
    });
};