import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkVerificationCode, startEditUser } from '../actions/auth';

// Asks user to enter phone number and address
class VerifyUser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: '',
            addressLine: '',
            validPhoneNumber: false,
            error: '',

            successMsg: '',
            success: false
        }
    }

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        if (!phoneNumber || phoneNumber.match(/^[0-9]{0,11}$/))
            this.setState(() => ({ phoneNumber }));

        const firstChar = phoneNumber.charAt(0);
        const secChar = phoneNumber.charAt(1);
        console.log("first char: " + firstChar + " ,sec char: " + secChar);

        if (firstChar != 0) {
            this.setState({
                validPhoneNumber: false,
                error: 'Please add a valid phone number'
            });
        }
        if (secChar != 1) {
            this.setState({
                validPhoneNumber: false,
                error: 'Please add a valid phone number'
            });
        }
        if (firstChar == 0 && secChar == 1) {
            this.setState({
                validPhoneNumber: true,
                error: ''
            });
        }
    }

    onAddressLineChange = (e) => {
        const addressLine = e.target.value;
        this.setState(() => ({ addressLine }));
    }

    onSubmit = async (e) => {
        console.log(`onSubmit props: ${JSON.stringify(this.props)}`);
        e.preventDefault();
        console.log('onSubmit called 1');
        if (!this.state.phoneNumber || !this.state.validPhoneNumber || this.state.phoneNumber.length < 11) {
            console.log(`onSubmit called 1.5 isValied: ${this.state.validPhoneNumber} length: ${this.state.phoneNumber.length}`);
            this.setState({ error: 'Please provide a valid phone number' });
        }
        else if (!this.state.addressLine) {
            console.log('onSubmit called 1.7');
            this.setState({ error: 'Please provide an address line' });
        }
        else {
            console.log('onSubmit called 2');
            const updates = {
                phoneNumber: this.state.phoneNumber,
                addressLine: this.state.addressLine,
                hasPhone: true,
                hasAddress: true,
            };

            console.log('onSubmit called 3');
            this.props.updateUser(this.state.userId, updates)
            console.log('onSubmit called 4');

            this.setState({
                successMsg: 'We received your phone number, sending verification code soon :)',
                success: true
            });
        }
    }

    render() {
        return (
            <div className="content-container">
                <form className="form" onSubmit={this.onSubmit}>
                    <div className="center-col">
                        <h2>Please add your phone number and address</h2>
                        {
                            (!this.props.hasPhone || this.props.hasPhone == '') &&
                            <input
                                className="textline"
                                required
                                type="number"
                                placeholder="Phone number"
                                value={this.state.phoneNumber}
                                onChange={this.onPhoneNumberChange}
                            ></input>}

                        {
                            (!this.props.hasAddress || this.props.hasAddress == '') &&
                            <input
                                className="textline"
                                required
                                type="text"
                                placeholder="Address line"
                                autoFocus
                                value={this.state.addressLine}
                                onChange={this.onAddressLineChange}
                            />}

                        <button className="button phone-verification-button">Submit</button>

                        {
                            this.state.success &&
                            <div className="simple-col">
                                <h2>{this.state.successMsg}</h2>
                                <button className="button phone-verification-button" onClick={() => {
                                    this.props.history.push('/home-page');
                                }}>Continue</button>
                            </div>
                        }
                    </div>

                </form>

            </div>

        )
    }
}

const mapDispatchToProps = (dispatch) => {
    console.log(`mapDispatchToProps`);
    return {
        updateUser: (id, updates) => dispatch(startEditUser(id, updates)),
    }
}

const mapStateToProps = (state, props) => {
    console.log(`mapStateToProps`);
    return {
        verificationCode: state.auth.verificationCode,
        userId: state.auth.id,
        hasAddress: state.auth.hasAddress,
        hasPhone: state.auth.hasPhone
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);