import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row } from "reactstrap";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { connect } from "react-redux";
import cartTotalPrice from "../utility/cartTotalPrice";
import { startSetGovs } from "../actions/cart";
import { startAddToOrders } from "../actions/orders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import validatePhoneNumber from "../utility/validatePhone";
import { useTranslation } from "react-i18next";

import "../styles/checkout.css";

const Checkout = ({ cartItems, govs, startSetGovs, startAddOrders }) => {
  const [selectedCity, setSelectedCity] = useState("");
  const [shipping, setShipping] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const buttonClass = isSubmitting ? "checkout-button-disabled" : "";
  const { t, i18n } = useTranslation();

  const validateForm = () => {
    let errors = {};
    let isValid = true;
    if (!firstName) {
      errors.firstName = t("CheckoutForm.7");
      isValid = false;
    }
    if (!lastName) {
      errors.lastName = t("CheckoutForm.8");
      isValid = false;
    }
    if (!email) {
      errors.email = t("CheckoutForm.9");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.validEmail = t("CheckoutForm.12");
      isValid = false;
    }
    if (!phoneNumber) {
      errors.phoneNumber = t("CheckoutForm.11");
      isValid = false;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      errors.validPhoneNumber = t("CheckoutForm.13");
      isValid = false;
    }
    if (!addressLine) {
      errors.addressLine = t("CheckoutForm.10");
      isValid = false;
    }
    if (!selectedCity) {
      errors.selectedCity = t("CheckoutForm.26");
      isValid = false;
    }
    setErrors(errors);
    return isValid;
  };

  const placeOrder = (event) => {
    event.preventDefault();
    const validForm = validateForm();
    if (validForm) {
      setIsSubmitting(true);
      startAddOrders(
        firstName,
        lastName,
        phoneNumber,
        `${selectedCity} ${addressLine}`,
        email,
        shipping + Subtotal
      );
    }
  };

  const Subtotal = cartTotalPrice(cartItems);
  const totalQty = cartItems.length;

  useEffect(() => {
    startSetGovs();
  }, []);

  useEffect(() => {
    // Update the shipping cost based on the selected city
    const city = govs.find((city) => city.value === selectedCity);
    if (city) {
      setShipping(city.price);
    }
  }, [selectedCity, govs]);

  return (
    <Helmet title="Checkout">
      <CommonSection title="Checkout" />
      <section>
        <Container>
          <Row>
            <Col lg="8">
              <h6 className="mb-4 fw-bold">{t("CheckoutForm.0")}</h6>
              <Form className="billing__form">
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder={t("CheckoutForm.1")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder={t("CheckoutForm.2")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="email"
                    placeholder={t("CheckoutForm.3")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="number"
                    placeholder={t("CheckoutForm.4")}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder={t("CheckoutForm.5")}
                    value={addressLine}
                    onChange={(e) => setAddressLine(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="">{t("CheckoutForm.6")}</option>
                    {govs.map((city) => (
                      <option key={city.value} value={city.value}>
                        {i18n.language == "en" ? city.value : city.label}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Form>
            </Col>
            <Col lg="4">
              <div className="checkout__cart">
                <h6>
                  {t("CheckoutForm.20")}: <span>{totalQty} {t("CheckoutForm.21")}</span>
                </h6>
                <h6>
                  {t("CheckoutForm.18")}: <span>{Subtotal} {t("CheckoutForm.25")}</span>
                </h6>
                <h6>
                  <span>
                    {t("CheckoutForm.17")}:
                    <br />
                  </span>
                  <span>{shipping} {t("CheckoutForm.25")}</span>
                </h6>

                <h4>
                  {t("CheckoutForm.19")}: <span>{shipping + Subtotal} {t("CheckoutForm.25")}</span>
                </h4>
                <button
                  onClick={placeOrder}
                  className={`buy__btn auth__btn ${buttonClass} w-100`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        style={{ fontSize: "1em", marginRight: "0.5rem" }}
                      />
                      {t("CheckoutForm.14")}
                    </>
                  ) : (
                    t("CheckoutForm.14")
                  )}
                </button>
              </div>
              {Object.keys(errors).length > 0 && (
                <div className="invalid-form">
                  {Object.entries(errors).map(([key, value]) => (
                    <div key={key} className="text-danger">
                      {value}
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cart,
    govs: state.cart.govs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startSetGovs: () => dispatch(startSetGovs()),
    startAddOrders: (firstName, lastName, phone, address, email, cost) =>
      dispatch(
        startAddToOrders(firstName, lastName, phone, address, email, cost)
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
