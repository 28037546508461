import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        zIndex: -1
    },
    drawerPaper: {
        width: drawerWidth,
        zIndex: 0
    },
    drawerContainer: {
        overflow: 'auto',
        marginTop: "5rem"
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    listItem: {
        fontSize: "1em"
    }
}));

export default function ShopDrawer() {
    const classes = useStyles();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className="show-for-desktop">
            <SwipeableDrawer
                className={classes.drawer}
                onOpen={handleDrawerOpen}
                onClose={handleDrawerClose}
                variant="permanent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Toolbar />
                <div className={classes.drawerContainer}>
                    <List>
                        <ListItem button key={t("HeaderShop.0")} href="/shop-home" component="a">
                            <ListItemText classes={{ primary: classes.listItem }} primary={t("HeaderShop.0")} />
                        </ListItem>
                        <ListItem button key={t("HeaderShop.1")} href="/add-product" component="a">
                            <ListItemText classes={{ primary: classes.listItem }} primary={t("HeaderShop.1")} />
                        </ListItem>
                        <ListItem button key={t("HeaderShop.2")} href="/edit-shop" component="a">
                            <ListItemText classes={{ primary: classes.listItem }} primary={t("HeaderShop.2")} />
                        </ListItem>
                        <ListItem button key={t("HeaderShop.3")} href="/shop-orders" component="a">
                            <ListItemText classes={{ primary: classes.listItem }} primary={t("HeaderShop.3")} />
                        </ListItem>
                        <ListItem button key={t("HeaderShop.4")} href="/dashboard" component="a">
                            <ListItemText classes={{ primary: classes.listItem }} primary={t("HeaderShop.4")} />
                        </ListItem>
                    </List>
                    <Divider />
                </div>
            </SwipeableDrawer>
        </div>
    )
}