const serviceData = [
  {
    icon: "ri-truck-line",
    title: "servicesTitles.0",
    subtitle: "servicesSubtitles.0",
    bg: "#fdefe6",
  },
  {
    icon: "ri-refresh-line",
    title: "servicesTitles.1",
    subtitle: "servicesSubtitles.1",
    bg: "#ceebe9",
  },
  {
    icon: "ri-secure-payment-line",
    title: "servicesTitles.2",
    subtitle: "servicesSubtitles.2",
    bg: "#e2f2b2",
  },
  {
    icon: "ri-exchange-dollar-line",
    title: "servicesTitles.3",
    subtitle: "servicesSubtitles.3",
    bg: "#d6e5fb",
  },
];

export default serviceData;
