import React from 'react';
import { _storage } from '../firebase/firebase';
import { Link } from 'react-router-dom';
import { PhoneInTalk } from '@material-ui/icons';
import { updateEmail } from '../actions/auth';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { connect } from 'react-redux';
import { useTranslation, Translation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/fontawesome-free-solid';

class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // t: props.t,
            setShippingCost: props.setShippingCost,

            firstName: props.user ? props.user.firstName : '',
            secondName: props.user ? props.user.secondName : '',
            email: '',

            addressLine: props.user ? props.user.addressLine : '',
            phoneNumber: props.user ? props.user.phoneNumber : '',

            isSubmitting: false
        }
    }
    onFirstNameChange = (e) => {
        const firstName = e.target.value;
        this.setState(() => ({ firstName }));
    }
    onLastNameChange = (e) => {
        const secondName = e.target.value;
        this.setState(() => ({ secondName }));
    }
    onAddressLineChange = (e) => {
        const addressLine = e.target.value;
        this.setState(() => ({ addressLine }));
    }
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    }

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        if (!phoneNumber || phoneNumber.match(/^[0-9]{0,11}$/))
            this.setState(() => ({ phoneNumber }));

        const firstChar = phoneNumber.charAt(0);
        const secChar = phoneNumber.charAt(1);

        if (firstChar != 0) {
            this.setState({
                validPhoneNumber: true,
                error: 'Please add a valid phone number'
            });
        }
        if (secChar != 1) {
            this.setState({
                validPhoneNumber: true,
                error: 'Please add a valid phone number'
            });
        }
        if (firstChar == 0 && secChar == 1) {
            this.setState({
                validPhoneNumber: false,
                error: ''
            });
        }
    }


    onSubmit = async (e) => {
        e.preventDefault();
        const { t } = this.props;

        if (!this.state.firstName) {
            this.setState(() => ({ error: t("CheckoutForm.4") }));
        }
        else if (!this.state.secondName) {
            this.setState(() => ({ error: t("CheckoutForm.5") }));
        }
        else if (!this.state.email) {
            this.setState(() => ({ error: t("CheckoutForm.6") }));
        }
        else if (!this.state.phoneNumber) {
            this.setState(() => ({ error: t("CheckoutForm.11") }));
        }
        else if (!this.state.addressLine) {
            this.setState(() => ({ error: t("CheckoutForm.10") }));
        }
        else {

            this.setState(() => ({ error: '' }));
            this.setState({ isSubmitting: true });
            this.props.onSubmit({
                firstName: this.state.firstName,
                secondName: this.state.secondName,
                phoneNumber: this.state.phoneNumber,
                addressLine: this.state.addressLine,
                email: this.state.email,
            });
        }
    }

    _onSelect = (e) => {
        //value is the id
        const govId = e.value
        for (var i = 0; i < this.props.govs.length; i++) {
            const gov = this.props.govs[i];
            if (gov.value == govId) {
                this.props.setShippingCost(gov.price)
            }
        }
    }

    render() {
        const { isSubmitting } = this.state;
        const buttonClass = isSubmitting ? 'checkout-button-disabled' : 'checkout-button';

        return (
            <Translation>
                {
                    t =>
                        <form className="form" onSubmit={this.onSubmit}>
                            <div>
                                <h3 style={{ margin: "20px" }}>{t("CheckoutForm.7")}</h3>
                                <input
                                    className="text-input"
                                    required
                                    type="text"
                                    placeholder={t("UserForm.0")}
                                    autoFocus
                                    value={this.state.firstName}
                                    onChange={this.onFirstNameChange}
                                />

                                <input
                                    className="text-input"
                                    required
                                    type="text"
                                    placeholder={t("CheckoutForm.1")}
                                    value={this.state.secondName}
                                    onChange={this.onLastNameChange}
                                />

                                <input
                                    className="text-input"
                                    type="email"
                                    placeholder={t("CheckoutForm.2")}
                                    value={this.state.email}
                                    onChange={this.onEmailChange}
                                />

                                <input
                                    className="text-input"
                                    type="number"
                                    placeholder={t("CheckoutForm.3")}
                                    value={this.state.phoneNumber}
                                    onChange={this.onPhoneNumberChange}
                                ></input>

                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder={t("CheckoutForm.9")}
                                    value={this.state.addressLine}
                                    onChange={this.onAddressLineChange}
                                ></input>
                            </div>

                            <Dropdown options={this.props.govs} onChange={this._onSelect} placeholder="Select an governorate" />


                            <a className="error-msg">{this.state.error}</a>
                            <button className={`${buttonClass} button`} type="submit" disabled={isSubmitting}>
                            {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '4em' }} />} 
                            {t('CheckoutForm.8')}
                            </button>

                        </form>
                }
            </Translation>

        )
    }
}

function mapStateToProps(state) {
    return {
        govs: state.cart.govs,
    };
}

export default connect(mapStateToProps)(CheckoutForm);