import React from 'react';
import { connect } from 'react-redux';
import { SetTextFilter, SortByDate, SortByAmount } from '../actions/filters';
import { useTranslation } from 'react-i18next';

const ExpenseListFilters = (props) => {
    
    const { t } = useTranslation();
    
    return(
    <div className="content-container top-margin">
        <div className="input-group">
            <div className="input-group__item">
                <input className="text-input" 
                    type="text" 
                    placeholder="Search"
                    value={props.filters.text} onChange={(e) => props.dispatch(SetTextFilter(e.target.value)) }
                />
            </div>
            <div className="input-group__item">
                <select className="select"
                    //    value={props.filters.sortBy}
                    onChange={(e) => {
                        if (e.target.value === 'date') {
                            props.dispatch(SortByDate());
                        }
                        else if (e.target.value === 'amount')
                            props.dispatch(SortByAmount());
                    }}>
                    <option value="date">{t("ExpenseListFilters.0")}</option>
                    <option value="amount">{t("ExpenseListFilters.1")}</option>
                </select>
            </div>
        </div>


    </div>
)};

const mapStateToProps = (state) => {
    return {
        filters: state.filters
    };
};

export default connect(mapStateToProps)(ExpenseListFilters);