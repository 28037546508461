import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import "./header.css";
import logo from "../../assets/images/favicon_nobackground.png";
import userIcon from "../../assets/images/user-icon.png";
import { startLogout } from "../../actions/auth";
import ChangeLanguage from "./changeLanguages";

const nav__links = [
  {
    path: "/home-page",
    display: "Header.8",
    public: true
  },
  {
    path: "/products",
    display: "Header.11",
    public: true
  },
  {
    path: "/customer-orders",
    display: "Header.4",
    public: false
  },
];

const Header = (props) => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false); // Move the useState hook here
  const { t } = useTranslation();

  const navigateToHome = () => {
    history.push("/home-page");
  };

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };

  useEffect(() => {
    stickyHeaderFunc();
    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  });

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");
  const handleLogout = () => {
    props.startLogout();
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <header className="header" ref={headerRef}>
      <Container>
        <Row>
          <div className="nav__wrapper">
            <div className="logo" onClick={navigateToHome}>
              <img src={logo} alt="logo" />
              <h1>bazaar</h1>
            </div>

            <div className="navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="menu">
                {nav__links.map((item, index) =>
                ((props.isAuth || item.public) &&
                  <li className="nav__item" key={index}>
                    <Link
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                    >
                      {t(`${item.display}`)}
                    </Link>
                  </li>
                )
                )}
              </ul>
            </div>

            {props.isAuth ?
              <div className="nav__icons">
                <Link to="/wishlist">
                  <span className="fav__icon">
                    <i className="ri-heart-line"></i>
                    <span className="badge__header">{props.wishListTotalQuantity}</span>
                  </span>
                </Link>
                <Link to="/cart">
                  <span className="cart__icon">
                    <i className="ri-shopping-cart-2-line"></i>
                    <span className="badge__header">{props.cartTotalQuantity}</span>
                  </span>
                </Link>
                <span className="profile">
                  <img src={userIcon} alt="user-icon" onClick={toggleDropdown} />
                  {showDropdown && (
                    <div className="dropdown__container">
                      <ul className="dropdown__list">
                        <li onClick={handleLogout}>{t("Header.6")}</li>
                      </ul>
                    </div>
                  )}
                </span>
                <div className="mobile__menu">
                  <span onClick={menuToggle}>
                    <i className="ri-menu-line"></i>
                  </span>
                </div>
                <ChangeLanguage />
              </div>
              :
              <div className="login-signup">
                <Link to="/signup" className="btn btn-primary btn-sm">{t("Header.1")}</Link>
                <Link to="/login" className="btn btn-outline-primary btn-sm">{t("Header.0")}</Link>
                <ChangeLanguage />
              </div>
            }
          </div>
        </Row>
      </Container>
    </header>
  );
};

const mapStateToPros = (state) => {
  return {
    isAuth: state.auth.uid ? true : false,
    cartTotalQuantity: state.cart.cart.length,
    wishListTotalQuantity: state.wishlist.length,
  };
};

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout())
});

export default connect(mapStateToPros, mapDispatchToProps)(Header);