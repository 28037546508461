import "remixicon/fonts/remixicon.css";
import "bootstrap/dist/css/bootstrap.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import AppRouter, { history } from "./Routers/AppRouter";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import { Provider } from "react-redux";
import "normalize.css/normalize.css";
import "./styles/styles.scss";
import LoadingPage from "./components/LoadingPage";
import { _auth } from "./firebase/firebase";
import { startSetProductsCustomer } from "./actions/expenses";
import { startSetCategories, startSetCategoriesTr } from "./actions/categories";
import { logout, startLogin } from "./actions/auth";
import "./i18next";

const store = configureStore();

const jsx = (
  <Provider store={store}>
    <Suspense fallback={<LoadingPage />}>
      <ToastContainer
        theme="dark"
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnHover={false}
      />
      <AppRouter />
    </Suspense>
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("root"));
    hasRendered = true;
  }
};

ReactDOM.render(<LoadingPage />, document.getElementById("root"));

_auth.onAuthStateChanged((user) => {
  store.dispatch(startSetCategories());
  store.dispatch(startSetCategoriesTr());
  if (user && user.uid) {
    store.dispatch(startLogin(user)).then(() => {
      store.dispatch(startSetProductsCustomer()).then(() => {
        if (history.location.pathname === "/") {
          history.push("/home-page");
        }

        renderApp();
      });
    });
  } else {
    store.dispatch(logout());

    store.dispatch(startSetProductsCustomer()).then(() => {
      if (history.location.pathname === "/") {
        history.push("/home-page");
      }

      renderApp();
    });
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
