import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ImageViewer from 'react-simple-image-viewer';
import { startAddToCart } from '../actions/cart';
import { addViews, getDateAsString, loadProduct } from '../actions/expenses';
import { startAddToWishlist } from '../actions/wishlist';
import LoadingPage from './LoadingPage';
import ProductsListCustomer from './ProductsListCustomer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        flexGrow: 1,
        fontSize: 36,
        [theme.breakpoints.down('sm')]: {
            marginTop: 15,
            fontSize: 18
        }
    },
    description: {
        fontSize: 26,
        color: 'grey',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }

    },
    price: {
        flexGrow: 1,
        fontSize: 28,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
    },
    stock: {
        fontSize: 18,
        color: 'grey',
        marginTop: '7px',
        marginBottom: '7px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    addToCartButton: {
        margin: '12px',
        fontSize: 14,
        fontWeight: 'bold',
        width: 600,
        height: 50,
        background: '#e34870',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            width: "115%",
        }
    },
    wishlistButton: {
        margin: '12px',
        fontSize: 14,
        fontWeight: 'bold',
        width: 600,
        height: 50,
        background: 'white',
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            width: "115%",
        }
    }
}));

const ProductDetailsPage = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    useEffect(() => {
        async function getProduct() {
            if (props.product == null || props.product == undefined) {
                const pr = await loadProduct(props.match.params.id);
                const date = getDateAsString(pr.creationDate);
                setState({
                    product: pr,
                    madeDate: date
                });
                setTitle(i18n.language === 'en' ? pr.title : pr.titleAR);
                setDesc(i18n.language === 'en' ? pr.description : pr.descriptionAR);
                if (pr.detailPageUrls) setUrl(pr.detailPageUrls[0]);
            }

        }


        //setImages(links);

        getProduct();

        if (props.product !== undefined) {
            setTitle(i18n.language === 'en' ? props.product.title : props.product.titleAR);
            setDesc(i18n.language === 'en' ? props.product.description : props.product.descriptionAR);
        }
    }, [props.product, props.match.params.id, i18n.language])

    const [state, setState] = useState({
        product: null,
        madeDate: ''
    });
    const [quantity, setQuantity] = useState(1);
    const [imageUrl, setUrl] = useState((props.product != undefined && props.product.detailPageUrls != null) ? props.product.detailPageUrls[0] : ((state.product != null && state.product.detailPageUrls != null) ? state.product.detailPageUrls[0] : ''));
    const [cartString, setCart] = useState(t("ProductDetails.4"));
    const [wishlistString, setWishlist] = useState(t("ProductDetails.6"));
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, [currentImage]);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    function drawLayout(product) {
        if (!product) {
            return;
        }
        return (
            <div>
                {
                    isViewerOpen && (
                        <ImageViewer
                            src={product.detailPageUrls}
                            currentIndex={currentImage}
                            onClose={closeImageViewer}
                        />
                    )
                }
                <div className="grid-productsDetails">
                    {
                        product.detailPageUrls &&
                    <div className="imgs">
                        <img onClick={() => {
                            openImageViewer(currentImage)
                        }} className="img__main " src={imageUrl || 'public/images/img-ph.jpg'} />

                        <div className="imgs-row">
                            {
                                (product.detailPageUrls && product.detailPageUrls[0]) &&
                                <button className="close-image--button" onClick={() => {
                                    setCurrentImage(0);
                                    setUrl(product.detailPageUrls[0]);
                                }}>
                                    <img className="close-image--img" src={ (product.detailPageUrls != null && product.detailPageUrls[0]) != null ? product.detailPageUrls[0] : 'images/img-ph.jpg'} />
                                </button>
                            }
                            {
                                product.detailPageUrls[1] &&
                                <button className="close-image--button" onClick={() => {
                                    setCurrentImage(1);
                                    setUrl(product.detailPageUrls[1]);
                                }}>
                                    <img className="close-image--img" src={product.detailPageUrls[1] != null ? product.detailPageUrls[1] : 'images/img-ph.jpg'} />
                                </button>
                            }
                            {
                                product.detailPageUrls[2] &&
                                <button className="close-image--button" onClick={() => {
                                    setCurrentImage(2);
                                    setUrl(product.detailPageUrls[2])
                                }}>
                                    <img className="close-image--img" src={product.detailPageUrls[2] != null ? product.detailPageUrls[2] : 'images/img-ph.jpg'} />
                                </button>

                            }
                            {
                                product.detailPageUrls[3] &&
                                <button className="close-image--button" onClick={() => {
                                    setCurrentImage(3);
                                    setUrl(product.detailPageUrls[3])
                                }}>
                                    <img className="close-image--img" src={product.detailPageUrls[3] != null ? product.detailPageUrls[3] : 'images/img-ph.jpg'} />
                                </button>
                            }
                        </div>
                    </div>
                    }



                    <div className="titles">
                        <Typography varient="h1" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography varient="h2" className={classes.description}>
                            {desc}
                        </Typography>
                        <Typography varient="h2" className={classes.price}>
                            {(product.publishedPrice == undefined ? product.price * 1.15 : product.publishedPrice) + " EGP"}
                        </Typography>
                        <Typography varient="h3" className={classes.stock}>
                            {t("ProductDetails.0") + product.inStock}
                        </Typography>
                        <Typography varient="h3" className={classes.stock}>
                            {product.madeByUser ? t("ProductDetails.2") : t("ProductDetails.1")}
                        </Typography>


                        <div className="change-amount">
                            <Typography varient="h3" className={classes.stock}>
                                {t("ProductDetails.3") + quantity}
                            </Typography>

                            <div>
                                <button className="button--quantity" onClick={() => setQuantity(quantity <= 1 ? 1 : quantity - 1)}>-</button>
                                <button className="button--quantity" onClick={() => setQuantity(quantity >= product.inStock ? product.inStock : quantity + 1)}>+</button>
                            </div>
                        </div>

                        <div className="titles--button">
                            <Button variant="contained" className={classes.addToCartButton} onClick={() => {
                                product = {
                                    quantity: quantity,
                                    ...product
                                }
                                props.addToCart(product);

                                setCart(cartString == t("ProductDetails.4") ? t("ProductDetails.5") : t("ProductDetails.4"));
                            }}>
                                {cartString}
                            </Button>

                            <Button variant="contained" className={classes.wishlistButton} onClick={() => {
                                props.addToWishList(product);
                                setWishlist(wishlistString == t("ProductDetails.6") ? t("ProductDetails.7") : t("ProductDetails.6"));
                            }}>
                                {wishlistString}
                            </Button>

                        </div>


                    </div>


                </div>

                <ProductsListCustomer numRows={0} showFooter={true} />
            </div>
        )
    }

    function loadPage() {
        return (
            state.product == null ? <LoadingPage /> : drawLayout(state.product)
        )
    }

    return (
        <div>
            <div className="content-container">
                {
                    props.product == undefined ? loadPage() : drawLayout(props.product)
                }
            </div>
        </div>

    );
}

const mapStateToPros = (state, props) => {
    var product = state.expenses.marketProducts.find((product) => product.id === props.match.params.id);
    if (product == undefined)
        return {};
    //console.log('map product: ' + product.title);
    var madeDate;
    if (product != undefined) {
        madeDate = getDateAsString(product.creationDate);
        addViews(product.shopId, product.id);
    }

    return {
        product: product,
        madeDate: madeDate
    }
}

const mapDispatchToProps = (dispatch) => ({
    addToWishList: (product) => dispatch(startAddToWishlist(product)),
    addToCart: (product) => dispatch(startAddToCart(product))
})

export default connect(mapStateToPros, mapDispatchToProps)(ProductDetailsPage);
