import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-dropdown/style.css';
import { startLogout } from '../actions/auth';
import SignModal from '../components/SignModal';
import HeaderCategories from './HeaderCategories';
import Sidebar from '../components/widgets/sidebar';
import { _auth } from '../firebase/firebase';
import ChangeLanguage from '../components/widgets/change_language';

import IconButton from '@material-ui/core/IconButton';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "#BBBA3A",
        zIndex: 1
    },
    logo: {
        marginTop: "15px",
        maxWidth: "150px",
        height: "auto"
    },
    menuItem: {
        width: "150px",
        fontSize: "15px"
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    largeIcon: {
        fontSize: "2em"
    },
    mediumIcon: {
        fontSize: "1.5em"
    },
    badge: {
        fontSize: 12
    }
}));


const Header = (props) => {

    const { t, i18n } = useTranslation();

    const classes = useStyles();

    const [state, setState] = useState({
        options: [
            'My Cart', 'My Wishlist', 'My Orders', 'Logout'
        ],
        defaultOption: 'More options',
        openModal: false,
        modalTitle: 'Login',
    })

    const [appStoreModal, setAppStoreModal] = useState(false);

    const handleCloseModal = () => {
        setState({
            openModal: false
        });
    }

    const [sidebarOpen, setSidebaropen] = useState(false);


    const openHandler = () => {
        if (!sidebarOpen) setSidebaropen(true);
        else setSidebaropen(false);
    }

    const sidebarCloseHandler = () => {
        setSidebaropen(false);
    }

    useEffect(() => {
        if (sidebarOpen)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'unset';
    }, [sidebarOpen]);

    const handleCloseAppStoreModal = () => {
        setAppStoreModal(false);
    }

    function loggedOutComponents() {
        return (
            <div className="show-for-desktop">
                <div className="row-div">
                    <button className="button button--link" onClick={() => {
                        props.history.push('/login');
                    }}>{t("Header.0")}</button>

                    <button style={{ width: '15rem' }} className="button button--link" onClick={() => {
                        props.history.push('/signup');
                    }}>{t("Header.1")}</button>

                </div>
            </div>
        );
    }

    function loggedInComponents() {
        return (
            <div>
                <div className="show-for-desktop simple-row">
                    <label className="show-for-desktop">Welcome {props.firstName} |</label>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <AccountCircle className={classes.largeIcon} />
                    </IconButton>
                </div>

                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >

                    <div className="show-for-mobile">
                        
                        <MenuItem className="" style={{ fontSize: '15px' }} onClick={() => {
                            props.history.push('/cart');
                        }}>
                            {t("Header.2")}
                        </MenuItem>


                        <MenuItem className="" style={{ fontSize: '15px' }} onClick={() => {
                            props.history.push('/customer-orders');
                        }}>
                            {t("Header.4")}
                        </MenuItem>

                        <MenuItem className="" style={{ fontSize: '15px' }} onClick={() => {
                            props.history.push(props.shopUrl);
                        }}>

                            {t("Header.5")}
                        </MenuItem>

                        <MenuItem className="" style={{ fontSize: '15px' }} onClick={() => {
                            props.history.push('/wishlist');
                        }}>

                            {t("Header.3")}
                        </MenuItem>
                    </div>

                    <MenuItem className={classes.menuItem} onClick={() => {
                        props.history.push('/edit-user');
                        handleClose();
                    }}>
                        {t("Header.7")}
                    </MenuItem>

                    <MenuItem className={classes.menuItem} onClick={() => {
                        props.startLogout();
                        handleClose();
                    }}>
                        {t("Header.6")}
                    </MenuItem>


                </Menu>
            </div>
        )
    }

    function loggedInComponentsMobile() {
        return (
            <div className="show-for-mobile">
                {/* <IconButton aria-label="show 17 new notifications" color="inherit" onClick={() => {
                    props.history.push('/cart');
                }}>
                    <div className="simple-col">
                        <Badge badgeContent={props.cartCount} color="secondary" classes={{ badge: classes.badge }}>
                            <ShoppingCart className={classes.mediumIcon} />
                        </Badge>
                        <label>{t("Header.2")}</label>
                    </div>
                </IconButton> */}
                <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                >
                    <AccountCircle className={classes.largeIcon} />
                </IconButton>
            </div>
        )
    }

    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [marginLeft, setMarginleft] = useState(i18n.language === 'en' ? '85%' : '0');
    const [marginRight, setMarginRight] = useState(i18n.language === 'ar' ? '0%' : '85%');

    useEffect(() => {
        setMarginRight(i18n.language === 'en' ? '65vw' : '0');
        setMarginleft(i18n.language === 'en' ? '0' : '65vw');
    })

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className="show-for-mobile">
                        <IconButton className="show-for-mobile" onClick={openHandler}>
                            <MenuIcon />
                        </IconButton>
                    </div>

                    <div>
                        <Typography variant="h6" className={classes.title} style={{paddingLeft: marginLeft, paddingRight: marginRight}}>
                            <Link to="/home-page" >
                                <img className={classes.logo} src="https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/logo%2FLogo02-220x80.jpg?alt=media&token=8844eaf3-e632-494a-a023-e1c35104a8ef" />
                            </Link>
                        </Typography>
                    </div>


                    <div style={{ marginRight: '10px', marginLeft: '10px' }}> <ChangeLanguage /> </div>
                    {props.isAuth && loggedInComponents()}
                    {props.isAuth && loggedInComponentsMobile()}
                    {!props.isAuth && loggedOutComponents()}
                </Toolbar>

                <div className="show-for-desktop">
                    <HeaderCategories history={props.history} />
                </div>
            </AppBar>
            {
                sidebarOpen && <Sidebar className="show-for-mobile" sidebar="sidebar" type="categories" close={sidebarCloseHandler} />
            }
            <SignModal
                openModal={state.openModal}
                title={state.modalTitle}
                handleCloseModal={handleCloseModal}
            />

        </div>
    );


}

const mapDispatchToProps = (dispatch) => ({
    startLogout: () => dispatch(startLogout())
});

const mapStateToProps = (state, props) => {
    return {
        shop: state.shop,
        hasShop: state.auth.hasShop,
        shopUrl: state.auth.hasShop ? '/shop-home' : '/create-shop',
        shopLinkTitle: state.shop ? 'Edit shop' : 'Create shop',
        isAuth: state.auth.uid ? true : false,
        firstName: state.auth.uid ? state.auth.firstName : '',
        sentPhoneCode: state.auth.sentPhoneCode,
        phoneVerified: state.auth.phoneVerified,
        cartCount: state.cart.cart.length
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);