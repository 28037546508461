import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingPage from './LoadingPage';
import { OrderItemsList } from './OrderItemsList';
import { loadOrder, refundOrder } from '../actions/orders';
import { useTranslation } from 'react-i18next';

const CustomerOrderDetailsPage = (props) => {

    const { t } = useTranslation();
   
    useEffect(() => {
        async function getOrder() {
            if (props.orderItem === null || props.orderItem === undefined) {
                const order = await loadOrder(props.match.params.id);
                setState({
                    orderItem: order,
                });
            }

        }

        getOrder();
    })

    const [state, setState] = useState({
        orderItem: null,
    });

    return (
        <div className="content-container">
            {
                props.orderItem === undefined ?
                    (
                        state.orderItem === null ? <LoadingPage /> :
                            <div>
                                <h1>{t("CustomerOrderDetailsPage.0")}</h1>
                                <OrderItemsList type="order-item" orderItem={state.orderItem} />
                            </div>
                    )
                    :
                    <div>
                        <h1>{t("CustomerOrderDetailsPage.0")}</h1>
                        <OrderItemsList type="order-item" orderItem={props.orderItem} />
                    </div>
            }
        </div>
    );
}

// Called one time when the component is loaded
const mapStateToProps = (state, props) => {
    // var order = state.orders.find((order) => order.id === props.match.params.id);
    // console.log("order: " +  props.match.params.id);

    // Add to props object the orderItem property
    // To get irderItem, 
    return {
        //orderItem has the ordered products - we should make a list showing each product 
        orderItem: state.orders.find((order) => order.id === props.match.params.id)
    }
}


export default connect(mapStateToProps)(CustomerOrderDetailsPage);
