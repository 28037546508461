import React from 'react';
import "../../styles/terms-condition.css";
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {

    const { t } = useTranslation();

    return (
        <div className="terms content-container">
            <h1 className="title">
                {t("ServiceFeePolicy.1")}
            </h1>

            <h3 className="subtitle">
                Welcome to bazaar. We’re so glad you’re using our services. Make yourself happy and have a wonderful time, but please follow our ground rules.
            </h3>

            <div className="content sub-list">
                1. Accepting These Terms <br />
                2. Those Other Documents We Mentioned <br />
                3. Your Privacy<br />
                4. Your Account with bazaar<br />
                5. Your Content<br />
                6. Your Use of Our Services<br />
                7. Termination<br />
                8. Warranties and Limitation of Liability (or the Things You Can’t Sue Us For)<br />
                9. Indemnification (or What Happens If You Get Us Sued)<br />
                10. Disputes with Other Users<br />
                11. Disputes with bazaar<br />
                12. Changes to the Terms<br />
                13. Some Finer Legal Points<br />
                14. Contact Information<br />
            </div>

            <div>
                <h1>1. Accepting These Terms</h1>
                <p className="sub-list">
                    This document and the other documents that we reference below representing our ground rules,
                    or what we officially call our Terms and conditions <br />
                    The Terms are a legally binding contract between you and bazaar <br />
                    <br />
                    Please note that Section 11. Disputes with bazaar,
                    contains an arbitration clause and class action waiver.
                    By agreeing to the Terms, you agree to resolve all disputes through binding individual arbitration,
                    which means that you waive any right to have those disputes decided by a judge or jury,
                    and that you waive your right to participate in class actions, class arbitrations, or representative actions. * <br />
                    <br />
                    This contract sets out your rights and responsibilities when you use bazaar-egy.com,
                    Pattern by bazaar, our mobile apps,
                    and the other services provided by bazaar (we’ll refer to all of these collectively as our “Services”),
                    so please read it carefully. By using any of our Services (even just browsing one of our websites),
                    you’re agreeing to the Terms. If you don’t agree with the Terms, you may not use our Services.
                    Agree with us? Great, read on!

                </p>

                <h1>2. Those Other Documents We Mentioned</h1>
                <p className="sub-list">
                    bazaar’s Services connect people around the world,
                    both online and offline, to make, sell, and buy unique goods.
                    Here’s a handy guide to help you understand the specific rules that are relevant for you, depending on how you use the Services: <br />
                    <br />
                    Our Ground Rules for Everyone. If you use any of our Services,
                    you agree to these Terms, our Privacy Policy,
                    and our Anti-Discrimination Policy.<br />
                    <br />
                    Our Ground Rules for Sellers.
                    If you list any items for sale through our Services,
                    these policies apply to you. You can read them here.<br />
                    <br />
                    Our Ground Rules for Buyers.
                    If you use our Services to browse or shop,
                    these policies apply to you. You can read them here.<br />
                    <br />
                    Our Ground Rules for Third Parties.
                    These policies apply to intellectual property owners,
                    bazaar API users, affiliates, and anyone requesting information from bazaar.<br />
                    <br />
                    All of these policies are a part of our Terms,
                    so be sure to read the ones that are relevant for you.
                    Of course, you’ll still want to read the rest of this document because it applies to everyone!<br />
                </p>

                <h1>
                    3. Your Privacy
                </h1>
                <p className="sub-list">
                    We know your personal information is important to you,
                    so it’s important to us.
                    Our Privacy Policy details how your information is used when you use our Services.
                    By using our Services, you're also agreeing that we can process your information in the ways set out in the Privacy Policy,
                    so please read it here.<br />
                    <br />
                    Both bazaar and sellers process members’ personal information (for example, buyer name, email address, and shipping address)
                    and are therefore considered separate and independent data controllers of buyers’ personal information under EU law.
                    That means that each party is responsible for the personal information it processes in providing the Services.
                    For example, if a seller accidentally discloses a buyer’s name and email address when fulfilling another buyer’s order,
                    the seller, not bazaar, will be responsible for that unauthorized disclosure.<br />
                    <br />
                    If, however, bazaar and sellers are found to be joint data controllers of buyers’ personal information,
                    and if bazaar is sued, fined, or otherwise incurs expenses
                    because of something that you did as a joint data controller of buyer personal information,
                    you agree to indemnify bazaar for the expenses it occurs in connection with your processing of buyer personal information.
                    See Section 9. Indemnification (or What Happens If You Get Us Sued)
                    below for more information about your indemnification obligations to bazaar.<br />
                </p>

                <h1>
                    4. Your Account with bazaar
                </h1>
                <p className="sub-list">
                    You’ll need to create an account with bazaar to use some of our Services. Here are a few rules about accounts with bazaar:<br />
                    A. You must be 18 years or older to use our Services.
                    Minors under 18 and at least 13 years of age are only permitted
                    to use our Services through an account owned by a parent or legal guardian with their appropriate permission
                    and under their direct supervision.
                    Children under 13 years are not permitted to use bazaar or the Services.
                    You are responsible for any and all account activity conducted by a minor on your account,
                    and there may be commercial products or services available that you may want to
                    consider to limit a minor's access to material online. For more information, see bazaar's Minors Policy.<br />
                    <br />
                    A. You must be 18 years or older to use our Services.
                    Minors under 18 and at least 13 years of age are only permitted
                    to use our Services through an account owned by a parent or legal guardian with their appropriate permission
                    and under their direct supervision.
                    Children under 13 years are not permitted to use bazaar or the Services.
                    You are responsible for any and all account activity conducted by a minor on your account,
                    and there may be commercial products or services available that you may want to
                    consider to limit a minor's access to material online. For more information, see bazaar's Minors Policy.<br />
                    <br />
                    B. Be honest with us. Provide accurate information about yourself.
                    It’s prohibited to use false information or impersonate another person or company through your account.<br />
                    <br />
                    C. Choose an appropriate name.
                    If you decide to not have your full name serve
                    as the name associated with your account,
                    you may not use language that is offensive, vulgar,
                    infringes someone’s intellectual property rights, or otherwise violates the Terms.<br />
                    <br />
                    D. You're responsible for your account.
                    You’re solely responsible for any activity on your account.
                    If you’re sharing an account with other people,
                    then the person whose financial information is on the account will ultimately be responsible for all activity.
                    If you’re registering as a business entity,
                    you personally guarantee that you have the authority to agree to the Terms on behalf of the business.
                    Also, your accounts are not transferable.<br />
                    <br />
                    E. Protect your password.
                    As we mentioned above,
                    you’re solely responsible for any activity on your account,
                    so it’s important to keep your account password secure.
                    Here’s a Help article on how to make your account more secure.<br />
                    <br />
                    F. Let's be clear about our relationship.
                    These Terms don't create any agency,
                    partnership, joint venture, employment,
                    or franchisee relationship between you and bazaar.<br />
                </p>

                <h1>
                    5. Your Content
                </h1>
                <p className="sub-list">
                    Content that you post using our Services is your content (so let’s refer to it as “Your Content”).
                    We don’t make any claim to it,
                    which includes anything you post using our Services
                    (like shop names, profile pictures, listing photos, listing descriptions, reviews, comments, videos, usernames, etc.). <br />
                    <br />
                    A. Responsibility for Your Content.
                    You understand that you are solely responsible for Your Content.
                    You represent that you have all necessary rights to
                    Your Content and that you’re not infringing or violating any third party’s rights by posting it.<br />
                    <br />
                    B. Permission to Use Your Content.
                    By posting Your Content through our Services,
                    you grant bazaar a license to use it.
                    We don’t claim any ownership to Your Content,
                    but we have your permission to use it to help bazaar function and grow.
                    That way, we won’t infringe any rights you have in Your Content and we can help promote it.
                    For example, you acknowledge and agree bazaar may offer you or bazaar buyers promotions on the Site,
                    from time to time, that may relate to your listings<br />
                    <br />
                    C. Rights You Grant bazaar.
                    (Here’s the legalese version of the last section).
                    By posting Your Content,
                    you grant bazaar a non-exclusive, worldwide, royalty-free, irrevocable,
                    sub-licensable, perpetual license to use, display, edit,
                    modify, reproduce, distribute, store,
                    and prepare derivative works of Your Content.
                    This allows us to provide the Services and to promote bazaar,
                    your bazaar shop, or the Services in general,
                    in any formats and through any channels,
                    including across any bazaar Services, our partners,
                    or third-party website or advertising medium.
                    You agree not to assert any moral rights or rights of publicity against us for using Your Content.
                    You also recognize our legitimate interest in using it,
                    in accordance with the scope of this license,
                    to the extent Your Content contains any personal information.<br />
                    <br />
                    That sounds like a lot,
                    but it’s necessary for us to keep bazaar going.
                    Consider these examples: if you upload a photo or video of a listing on your bazaar shop,
                    we have permission to display it to buyers,
                    and we can resize or enhance it so it looks good to a buyer using our mobile app;
                    if you post a description in English,
                    we can translate it into French so a buyer in Paris can learn the story behind your item;
                    and if you post a beautiful photo or video of your latest handmade necklace,
                    we can feature it– often along with your shop name and shop picture– on our homepage,
                    in one of our blogs or even on a billboard to help promote your business and bazaar’s.<br />
                    <br />
                    D. Reporting Unauthorized Content.
                    bazaar has great respect for intellectual property rights,
                    and is committed to following appropriate legal procedures to remove infringing content from the Services.
                    If content that you own or have rights to has been posted to the Services without your permission and you want it removed,
                    please follow the steps listed in our Intellectual Property Policy.
                    If Your Content is alleged to infringe another person’s intellectual property,
                    we will take appropriate action,
                    such as disabling it if we receive proper notice or terminating your account if you are found to be a repeat infringer.
                    We’ll notify you if any of that happens.<br />
                    <br />
                    E. Inappropriate, False, or Misleading Content.
                    This should be common sense,
                    but there are certain types of content we don’t want posted on bazaar’s Services (for legal reasons or otherwise).
                    You agree that you will not post any content that is abusive, threatening, defamatory,
                    obscene, vulgar, or otherwise offensive or in violation of our Prohibited Items Policy,
                    Community Policy, or any part of our Terms.
                    You also agree not to post any content that is false and misleading or uses the Services in a manner that is fraudulent or deceptive.
                </p>

                <h1>
                    6. Your Use of Our Services
                </h1>
                <p className="sub-list">
                    License to Use Our Services.
                    We grant you a limited,
                    non-exclusive, non-transferable,
                    and revocable license to use our Services—subject to the Terms and the following restrictions in particular:<br />
                    <br />
                    A. Don’t Use Our Services to Break the Law.
                    You agree that you will not violate any laws in connection with your use of the Services.
                    This includes any local, state, federal,
                    and international laws that may apply to you.
                    For example, it’s your responsibility to obtain any permits or licenses that your shop requires,
                    and to meet applicable legal requirements in applicable jurisdiction(s).
                    This includes the sale and delivery of your items, such as age verification upon delivery,
                    where required by law. You may not sell anything that violates any laws;
                    you must comply with our Sanctions Policy,
                    and you may not engage in fraud (including false claims or infringement notices),
                    theft, anti-competitive conduct, threatening conduct,
                    or any other unlawful acts or crimes against bazaar, another bazaar user, or a third party.<br />
                    <br />
                    B. Pay Your Bills. You are responsible for paying all fees that you owe to bazaar.
                    Except as set forth below,
                    you are also solely responsible for collecting
                    and/or paying any applicable taxes for any purchases
                    or sales you make through our Services.
                    bazaar will help collect and remit the correct amount of value-added tax or VAT.
                    Some countries may refer to VAT using other terms, e.g. Goods and Services Tax (GST),
                    but we’ll just refer to VAT, GST, and any local sales taxes collectively as “VAT.”
                    In addition, bazaar will calculate, collect, and remit sales tax where applicable.
                    Please see this FAQ for more information.
                    Your fees, bills, taxes, and how you can pay them are fully explained in our Fees & Payments Policy.<br />
                    <br />
                    C. Don’t Steal Our Stuff. You agree not to crawl, scrape,
                    or spider any page of the Services or to reverse engineer or attempt to obtain the source code of the Services.
                    If you want to use our API, please follow our API terms and conditions.<br />
                    <br />
                    D. Don’t Try to Harm Our Systems. You agree not to interfere with or try to disrupt our Services,
                    for example by distributing a virus or other harmful computer code.<br />
                    <br />
                    E. Follow Our Trademark Policy. The name "bazaar" and the other bazaar marks, phrases,
                    logos, and designs that we use in connection with our Services (the bazaar Trademarks),
                    are trademarks, service marks, or trade dress of bazaar in the US and other countries.
                    If you’d like to use our trademarks, please follow our Trademark Policy.<br />
                    <br />
                    F. Share Your Ideas. We love your suggestions and ideas!
                    They can help us improve your experience and our Services.
                    Any unsolicited ideas or other materials you submit to bazaar (not including Your Content or items you sell through our Services)
                    are considered non-confidential and non-proprietary to you.
                    You grant us a non-exclusive, worldwide, royalty-free, irrevocable,
                    sub-licensable, perpetual license to use and publish those ideas and materials for any purpose,
                    without compensation to you.<br />
                    <br />
                    G. Talk to Us Online. From time to time,
                    bazaar will provide you with certain legal information in writing.
                    By using our Services, you’re agreeing to our Electronic Communications Policy,
                    which describes how we provide that information to you.
                    It says that we can send you information electronically (such as by email)
                    instead of mailing you paper copies (it’s better for the environment),
                    and that your electronic agreement is the same as your signature on paper.<br />
                </p>

                <h1>
                    7. Termination
                </h1>
                <p className="sub-list">
                    Termination By You. We'd hate to see you go, but you may terminate your account with bazaar at any time from your account settings.
                    You can find more information in this Help article.
                    Terminating your account will not affect the availability of some of
                    Your Content that you posted through the Services prior to termination. Oh,
                    and you’ll still have to pay any outstanding bills.<br />
                    <br />
                    Termination By bazaar. We may terminate or suspend your account (and any accounts bazaar determines are related to your account)
                    and your access to the Services should we have reason to believe you,
                    your Content, or your use of the Services violate our Terms.
                    If we do so, it’s important to understand that you don’t have a contractual or legal right to continue to use our Services,
                    for example, to sell or buy on our websites or mobile apps.
                    Generally, bazaar will notify you that your account has been terminated or suspended,
                    unless you’ve repeatedly violated our Terms or we have legal or regulatory reasons preventing us from notifying you.<br />
                    <br />
                    If you or bazaar terminate your account, you may lose any information associated with your account, including Your Content.<br />
                    <br />
                    We May Discontinue the Services. bazaar reserves the right to change,
                    suspend, or discontinue any of the Services at any time, for any reason,
                    including those laid out in bazaar’s policies under these terms and conditions.
                    We will not be liable to you for the effect that any changes to the Services may have on you,
                    including your income or your ability to generate revenue through the Services.<br />

                    Survival. The Terms will remain in effect even after your access to the Service is terminated, or your use of the Service ends.
                </p>

                <h1>
                    8. Warranties and Limitation of Liability (or the Things You Can’t Sue Us For)
                </h1>
                <p className="sub-list">
                    Items You Purchase. You understand that bazaar does not manufacture,
                    store, or inspect any of the items sold through our Services.
                    We provide the venue; the items in our marketplaces are produced, listed,
                    and sold directly by independent sellers,
                    so bazaar cannot and does not make any warranties about their quality,
                    safety, or even their legality. Any legal claim related to an item
                    you purchase must be brought directly against the seller of the item.
                    You release bazaar from any claims related to items sold through our Services,
                    including for defective items, misrepresentations by sellers, or items that caused physical injury (like product liability claims).<br />
                    <br />
                    Content You Access. You may come across materials that you find offensive or
                    inappropriate while using our Services.
                    We make no representations concerning any content posted by users through the Services.
                    bazaar is not responsible for the accuracy, copyright compliance, legality,
                    or decency of content posted by users that you accessed through the Services.
                    You release us from all liability relating to that content.<br />
                    <br />
                    People You Interact With. You can use the Services to interact with other individuals,
                    either online or in person. However, you understand that we do not screen users of our Services,
                    and you release us from all liability relating to your interactions with other users.
                    Please be careful and exercise caution and good judgment in all interactions with others,
                    especially if you are meeting someone in person. This Help article has some good advice about handling in person meetings.<br />
                    <br />
                    Third-Party Services. Our Services may contain links to third-party websites or services
                    that we don’t own or control (for example, links to Facebook, Twitter, and Pinterest).
                    You may also need to use a third party’s product or service in order to
                    use some of our Services (like a compatible mobile device to use our mobile apps).
                    When you access these third-party services, you do so at your own risk.
                    The third parties may require you to accept their own terms and conditions.
                    bazaar is not a party to those agreements; they are solely between you and the third party.<br />
                    <br />
                    Gift Cards and Promotions. You acknowledge that bazaar does not make any warranties
                    with respect to your Gift Card balance and is not responsible for any
                    unauthorized access to, or alteration, theft,
                    or destruction of a Gift Card or Gift Card code that results from any action by you or a third party.
                    You also acknowledge that we may suspend or prohibit use of your Gift Card if
                    your Gift Card or Gift Card code has been reported lost or stolen,
                    or if we believe your Gift Card balance is being used suspiciously, fraudulently,
                    or in an otherwise unauthorized manner. If your Gift Card code stops working,
                    your only remedy is for us to issue you a replacement Gift Card code.
                    By participating in a special offer or promotion, you agree that you may not later
                    claim that the rules of that special offer or promotion were ambiguous.<br />
                    <br />
                    WARRANTIES. bazaar IS DEDICATED TO MAKING OUR SERVICES THE BEST THEY CAN BE,
                    BUT WE’RE NOT PERFECT AND SOMETIMES THINGS CAN GO WRONG.
                    YOU UNDERSTAND THAT OUR SERVICES ARE PROVIDED “AS IS” AND WITHOUT ANY
                    KIND OF WARRANTY (EXPRESS OR IMPLIED).
                    WE ARE EXPRESSLY DISCLAIMING ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY,
                    AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE,
                    COURSE OF DEALING, OR USAGE OF TRADE.<br />
                    <br />
                    WE DO NOT GUARANTEE THAT:
                    <p className="sub-list">
                        <br />
                        (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION;
                        <br />
                        (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED;
                        <br />
                        (III) THE SERVICES WILL BE FREE OF VIRUSES OR OTHER HARMFUL MATERIALS; OR
                        <br />
                        (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR EXPECTATIONS.
                        <br /><br />
                    </p>
                    YOU USE THE SERVICES SOLELY AT YOUR OWN RISK.
                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES,
                    SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.<br />
                    <br />
                    LIABILITY LIMITS. TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER bazaar,
                    NOR OUR EMPLOYEES OR DIRECTORS SHALL BE LIABLE TO YOU FOR ANY LOST PROFITS OR REVENUES,
                    OR FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, OR PUNITIVE
                    DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR THESE TERMS.
                    IN NO EVENT SHALL bazaar’S AGGREGATE LIABILITY FOR ANY DAMAGES EXCEED THE GREATER OF ONE THOUSAND (EGP1000)
                    EGYPTIAN POUND (EGP) OR THE AMOUNT YOU PAID bazaar IN THE PAST TWELVE MONTHS.
                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                    SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>

                <h1>
                    9. Indemnification (or What Happens If You Get Us Sued)
                </h1>
                <p className="sub-list">
                    We hope this never happens, but if bazaar gets sued because of something that you did,
                    you agree to defend and indemnify us.
                    That means you’ll defend bazaar (including any of our employees)
                    and hold us harmless from any legal claim or demand (including reasonable attorney’s fees)
                    that arises from your actions, your use (or misuse) of our Services, your breach of the Terms,
                    or you or your account’s infringement of someone else’s rights.<br />
                    <br />
                    We reserve the right to handle our legal defense however we see fit, even if you are indemnifying us,
                    in which case you agree to cooperate with us so we can execute our strategy.<br />
                </p>

                <h1>
                    10. Disputes with Other Users
                </h1>
                <p className="sub-list">
                    If you find yourself in a dispute with another user of bazaar’s Services or a third party,
                    we encourage you to contact the other party and try to resolve the dispute amicably.<br />
                    <br />
                    Case System. Buyers and sellers who are unable to resolve a dispute related to a
                    transaction on our websites or mobile apps may participate in our case system.
                    You can find details about the case system in this Help article.
                    bazaar will attempt to help you resolve disputes in good faith and based solely on our interpretation of our policies,
                    in our sole discretion; we will not make judgments regarding legal issues or claims. bazaar has no obligation to resolve any disputes.<br />
                    <br />
                    Release of bazaar. You release bazaar from any claims,
                    demands, and damages arising out of disputes with other users or parties.<br />
                </p>

                <h1>
                    11. Disputes with bazaar
                </h1>
                <p className="sub-list">
                    A. Governing Law. The Terms are governed by the laws of the State of Cairo,
                    without regard to its conflict of laws rules, and the laws of the Egyptian law.
                    These laws will apply no matter where in the world you live,
                    but if you live outside of the Egypt, you may be entitled
                    to the protection of the mandatory consumer protection provisions of your local consumer protection law.<br />
                    <br />
                    B. Arbitration. You and bazaar agree that any dispute or claim arising
                    from or relating to the Terms shall be finally settled by final and binding arbitration,
                    using the English language, administered by the Certified translation Association then in effect
                    (those rules are deemed to be incorporated by reference into this section, and as of the date of these Terms),
                    unless otherwise required by law. **Arbitration, including threshold questions of arbitrability of the dispute,
                    will be handled by a sole arbitrator in accordance with those rules.
                    Judgment on the arbitration award may be entered in any court that has jurisdiction.<br />
                    <br />
                    Any arbitration or mediation under the Terms will take place on an individual basis.
                    You understand that by agreeing to the Terms,
                    you and bazaar are each waiving the right to trial by jury or to participate in a class action lawsuit.
                    Class arbitrations shall only be available if requested by either party under its
                    Class Action Arbitration Rules and approved by the arbitration entity.
                    Notwithstanding the foregoing, each party shall have the right to bring an action in a court of
                    proper jurisdiction for injunctive or other equitable or conservatory relief,
                    pending a final decision by the arbitrator or mediator.
                    You may instead assert your claim in “small claims” court,
                    but only if your claim qualifies, your claim remains in such court,
                    and your claim remains on an individual, non-representative, and non-class basis.<br />
                    <br />
                    C. Costs of Arbitration. Payment for any and all reasonable filing, administrative,
                    and arbitrator fees will be in accordance with the Consumer Arbitration Rules.
                    If the value of your claim does not exceed 5,000 EGP, bazaar will pay for the reasonable filing,
                    administrative, and arbitrator fees associated with the arbitration,
                    unless the arbitrator finds that either the substance of your claim or the relief sought was
                    frivolous or brought for an improper purpose.<br />
                    <br />
                    D. Forum. We’re based in Cairo, so any legal action against bazaar related to our Services must be
                    filed and take place in Egypt County, Cairo. For all actions,
                    the proceedings may be filed where your residence is,
                    or in Cairo, and any in-person hearings will be conducted at a location which is reasonably
                    convenient to both parties taking into account their ability to travel and other pertinent circumstances.
                    For any actions not subject to arbitration or mediation,
                    you and bazaar agree to submit to the personal jurisdiction of a state or federal court located in Egypt County, Cairo.<br />
                    <br />
                    E. Government Exception. If you are a government agent or entity in the Egypt using the Services in your official capacity,
                    and you are legally unable to agree to the clauses in this section,
                    then those clauses do not apply to you. In that case, the Terms and any action related to the
                    Terms will be governed by the laws of Egypt (without reference to conflict of laws) and,
                    in the absence of federal law and to the extent permitted under federal law, the laws of Egypt.<br />
                    <br />
                    F. Modifications. If we make any changes to this “Disputes with bazaar” section after the date you last accepted the Terms,
                    those changes will not apply to any claims filed in a legal proceeding against bazaar prior to the date the changes became effective.
                    bazaar will notify you of substantive changes to the
                    “Disputes with bazaar” section at least 30 days prior to the date the change will become effective.
                    If you do not agree to the modified terms, you may send bazaar a written notification (including email)
                    or close your account within those 30 days.
                    By rejecting a modified term or permanently closing your account,
                    you agree to arbitrate any disputes between you and bazaar in accordance with the provisions of this “Disputes with bazaar”
                    section as of the date you last accepted the Terms,
                    including any changes made prior to your rejection.
                    If you reopen your closed account or create a new account, you agree to be bound by the current version of the Terms.<br />
                </p>

                <h1>
                    12. Changes to the Terms
                </h1>
                <p className="sub-list">
                    We may update these Terms from time to time.
                    Changes will be effective upon the posting of the changes unless otherwise specified.
                    You are responsible for reviewing and becoming familiar with any changes.
                    Your use of the Services following the changes constitutes your acceptance of the updated Terms.<br />
                </p>

                <h1>
                    13. Some Finer Legal Points
                </h1>
                <p className="sub-list">
                    The Terms, including all of the policies that make up the Terms,
                    supersede any other agreement between you and bazaar regarding the Services.
                    If any part of the Terms is found to be unenforceable,
                    that part will be limited to the minimum extent necessary so that the Terms will
                    otherwise remain in full force and effect.
                    Our failure to enforce any part of the Terms is not a waiver of our
                    right to later enforce that or any other part of the Terms.
                    We may assign any of our rights and obligations under the Terms.
                </p>

                <h1>
                    14. Contact Information
                </h1>
                <p className="sub-list">
                    If you have any questions about the Terms, please email us at: admin@bazaar-egy.com.
                </p>
            </div>
            <a className="d-flex justify-content-center fs-4 text-muted fw-bold">
                Last updated on &nbsp;<b>Jan 07, 2021</b>
            </a>
        </div>

    )
};

export default TermsAndConditions;