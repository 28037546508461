import "../styles/products.css";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import algoliasearch from 'algoliasearch/lite';

import { startGetProductsList } from "../actions/expenses";

import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import ProductsList from "../components/UI/ProductList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../components/UI/pagination";
import { useTranslation } from "react-i18next";

const Products = ({ categories, categoriesAR }) => {
  const [productsListSearch, setProductsListSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(20);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const searchProductList = async (term, page = 0) => {
    const searchClient = algoliasearch('PXATRGXJE2', 'd1d3cdfdfbb612ea22a26b59034e8bf0');
    const index = searchClient.initIndex('products');

    const options = {
      page,
      hitsPerPage: 500,
    };

    const results = await index.search(term, options);
    const hits = results.hits.map((hit) => {
      hit.id = hit.objectID;
      return hit;
    });

    return hits;
  };

  const handleFilter = (e) => {
    const sortTerm = e.target.value;

    const productsList = productsListSearch.sort((a, b) => {
      switch (sortTerm) {
        case "creationDate":
          return a.creationDate - b.creationDate;
        case "price":
          return b.publishedPrice - a.publishedPrice;
        case "rate":
          return b.rating - a.rating;
        default:
          return 0;
      }
    });
    setProductsListSearch([...productsList]);
    setCurrentPage(1);
  };

  const handleSearch = async (e) => {
    let products;
    setLoading(true);
    if (e.target.value === ("undefined" || undefined || null)) {
      products = await searchProductList("");
      setProductsListSearch(products);
      setCurrentPage(1);
    } else {
      const searchTerm = e.target.value;
      products = await searchProductList(searchTerm);
      console.log(products[0])
    }
    setProductsListSearch(products);
    setCurrentPage(1);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true)
    searchProductList('').then((products) => {
      setProductsListSearch(products);
      setLoading(false)
    });
  }, []);

  useEffect(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    setCurrentProducts(productsListSearch.slice(indexOfFirstProduct, indexOfLastProduct));
  }, [productsListSearch, currentPage])

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = Array.from({ length: Math.ceil(productsListSearch.length / productsPerPage) }, (_, i) => i + 1);

  return (

    <Helmet title="Products">
      <CommonSection title="Products" />
      <section className="filter__section">
        <Container>
          <Row>
            <Col lg="3" md="6" className="mb-3 mb-md-0">
              <div className="filter__widget">
                <select onChange={handleSearch}>
                  <option value={"undefined"} style={{ fontStyle: 'italic', fontSize: "1rem" }}>{t("ProductsPage.0")}</option>
                  {Object.keys(categories).map(category => (
                    <optgroup key={category} label={i18n.language == "en" ? category : categoriesAR[category]}>
                      {categories[category].map(subCategory => (
                        <option key={subCategory} value={subCategory} >
                          {i18n.language == "en" ? subCategory : categoriesAR[subCategory]}
                        </option>
                      ))}
                    </optgroup>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg="3" md="6" className="mb-3 mb-md-0">
              <div className="filter__widget">
                <select onChange={handleFilter}>
                  <option value={"undefined"} style={{ fontStyle: 'italic', fontSize: "1rem" }} >{t("ProductsPage.1")}</option>
                  <option value="creationDate">{t("ProductsPage.3")}</option>
                  <option value="price">{t("ProductsPage.5")}</option>
                  <option value="rate">{t("ProductsPage.4")}</option>
                </select>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="search__box">
                <input type="text" placeholder={t("ProductsPage.2")} onChange={handleSearch} />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Row>

            {loading ? (<>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                style={{ fontSize: "4rem" }}
              />
            </>
            ) : productsListSearch.length === 0 ? (
              <h1 className="text-center fs-4">No products found!</h1>
            ) : (
              <>
                <ProductsList t={t} i18n={i18n} data={currentProducts} />
                <Pagination currentPage={currentPage} pageNumbers={pageNumbers} paginate={paginate} />
              </>
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const mapStateToProps = (state, props) => {
  return {
    products: state.expenses.marketProducts,
    categories: state.categories.categories,
    categoriesAR: state.categories.categoriesTr
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProductsList: () => dispatch(startGetProductsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);