import React from "react";
import { Link } from "react-router-dom";
import Helmet from "../Helmet/Helmet";
import "../../styles/auth.css";
import loginImg from "../../assets/images/login.svg";
import horusImg from "../../assets/images/favicon_nobackground.png";

const LoginForm = ({
    handleLoginSubmit,
    phoneNumber,
    handlephoneNumberChange,
    t,
    i18n
}) => {
  return (
    <Helmet title="Login">
      <section className="container auth">
        <div className="form card">
          <div className="auth-header-logo">
            <img src={horusImg} alt="" className="auth-header-logo-img" />
          </div>
          <h1 className="auth-header-title">{t("Login.0")}</h1>
          <p className="auth-header-subtitle">{t("Login.1")}</p>

          <form onSubmit={handleLoginSubmit}>
            <input
              type="text"
              placeholder={t("Login.2")}
              required
              value={phoneNumber}
              onChange={handlephoneNumberChange}
            />
            <button className="--btn --btn-primary --btn-block">{t("Login.3")}</button>
            <div id="recaptcha-container"></div>
          </form>

          <span className="register">
            <p>{t("Login.4")}</p>
            <Link to="/Signup">{t("Login.5")}</Link>
          </span>
        </div>
        <div className="img">
          <img src={loginImg} alt="Login" />
        </div>
      </section>
    </Helmet>
  );
};


export default LoginForm;
