const wishlistReducerDefaultState = [];

export default (state = wishlistReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_TO_WISHLIST':
            return [
                ...state,
                action.item
            ];
        case 'REMOVE_FROM_WISHLIST':
            return state.filter(({ id }) => {
                return id !== action.id;
            });
        case 'SET_WISHLIST':
            return action.wishlist;
        default:
            return state;
    }
}