import React from "react";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import "../../styles/product-card.css";
import { startAddToCart } from "../../actions/cart";
import { startAddToWishlist } from "../../actions/wishlist";

const ProductCard = ({ item, addToCart, addToWishlist, isAuth, t, i18n, categoriesAR }) => {
  return (
    <Col lg="3" md="4" className="mb-0">
      <div className="product-card">
        <Link to={`/product-details/${item.id}`} className="product-card__link">
          <div className="product-card__image">
            <img
              src={
                item.thumbsUrls && item.thumbsUrls.length > 0
                  ? item.thumbsUrls[0]
                  : item.url1
              }
              alt=""
              className="product-card__image-img"
            />
          </div>
        </Link>
        <div className="product-card__details">
          <Link to={`/product-details/${item.id}`} className="product-card__link">
            <h3 className="product-card__name">
              {i18n.language === "en" ? item.title : item.titleAR}
            </h3>
          </Link>
          <span className="product-card__category">{i18n.language == "en" ? item.categories[0].mainCategory : categoriesAR[item.categories[0].mainCategory] /*item.categories[0].mainCategory*/}</span>
          <p className="product-card__description">
            {i18n.language === "en" ? item.description.length > 50
              ? item.description.slice(0, 50) + "..."
              : item.description : item.descriptionAR.length > 50
              ? item.descriptionAR.slice(0, 50) + "..."
              : item.descriptionAR}
          </p>
          <div className="product-card__rating">
            {Array(5).fill(1).map((_, i) => (
              <i
                key={i}
                className={`ri-star-${i < item.rating ? 'fill' : 'line'}`}
              />
            ))}
            <span className="product-card__reviews">{Math.ceil(item.rating)} ({item.numReviews} {t("ProductDetailsPage.13")})</span>
          </div>
          <div className="product-card__actions">
            <div className="product-card__icons">
              <span
                className="product-card__icon product-card__icon--wishlist"
                onClick={() => addToWishlist(item)}
              >
                <i className="ri-heart-fill"></i>
              </span>
              <span
                className="product-card__icon product-card__icon--add-to-cart"
                onClick={() => addToCart(item)}
              >
                <i className="ri-add-line"></i>
              </span>
            </div>
            <span className="product-card__price">
              {item.publishedPrice}{" "}
              {i18n.language === "en" ? "EGP" : "جنيه"}
            </span>
          </div>
        </div>
      </div>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuth: !!state.auth.email,
    categoriesAR: state.categories.categoriesTr
  };
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (product) => {
    toast.success("Product added to cart successfully!");
    return dispatch(startAddToCart(product));
  },
  addToWishlist: (product) => {
    toast.success("Product added to wishlist successfully!");
    return dispatch(startAddToWishlist(product));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);