import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { startCreateShop } from '../actions/shop';
import ShopForm from './ShopForm';

const AddShopPage = (props) => {

    const { t } = useTranslation();
    return (
        <div className="content-container">
            <h1>{props.user.hasShop === 'true' ? t("AddShop.3") : t("AddShop.0")}</h1>
            {
                ((props.shop !== undefined && props.shop.pending) || props.user.hasShop === 'pending')
                    ?
                    <label className="center">{t("AddShop.1")}</label>
                    :
                    <div className={props.user.hasShop === "true" ? "shift-right" : "content-container"}>
                        <ShopForm
                            t={t}
                            edit={props.user.hasShop === 'true'}
                            onSubmit={(shop) => {
                                props.startCreateShop(shop);
                                props.history.push('/verify-phone');
                            }}
                            user={props.user}
                        />
                    </div>
            }

        </div>
    )
};

const mapDispatchToProps = (dispatch) => {
    return {
        startCreateShop: (shop) => dispatch(startCreateShop(shop))
    }
}

const mapStateToProps = (state) => {
    return {
        phoneVerified: state.auth.phoneVerified,
        shop: state.shop,
        user: state.auth
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddShopPage);