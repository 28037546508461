import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import WishListCard from "../components/UI/wishListCard";
import { useTranslation } from "react-i18next";

const Wishlist = ({ wishlistItems }) => {
  const { t, i18n } = useTranslation();
  return (
    <Helmet title="Wishlist">
      <CommonSection title="Wishlist" />
      <section className="py-5">
        <Container>
          {wishlistItems.length === 0 ? (
            <h2 className="fs-4 text-center">{t("WishListPage.0")}</h2>
          ) : (
            <Row>
              {wishlistItems.map((item) => (
                <WishListCard t={t} i18n={i18n} item={item} key={item.id} />
              ))}
            </Row>
          )}
        </Container>
      </section>
    </Helmet>
  );
};

const mapStateToProps = (state) => {
  return {
    wishlistItems: state.wishlist,
  };
};

export default connect(mapStateToProps)(Wishlist);