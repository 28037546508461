import React from 'react';
import { connect } from 'react-redux';
import ShopOrderListItem from './ShopOrderListItem';
import ExpenseListFilter from './ExpenseListFilter';
import { startUpdateOrder } from '../actions/shopOrders';
import { useTranslation } from 'react-i18next';

const ShopOrdersList = (props) => {

    const { t } = useTranslation();

    return (
        <div className="content-container">
            <div className="list-body">
                <h2>{t("CartList.3")}</h2>
                {
                    props.products.length === 0 ? (
                        <div className="list-item list-item--message">
                            <span>            
                                {t("CartList.0")}
                            </span>
                        </div>
                    ) : (
                            <div>
                                <div className="vertical-list">
                                    {
                                        props.products.map(
                                            (product) => {
                                                return <ShopOrderListItem key={product.id} {...product} />
                                            }
                                        )
                                    }
                                </div>

                            </div >
                        )
                }
            </div>


        </div >
    )
};

const mapStateToProps = (state, props) => {
    return {
        products: state.shopOrders,
    };
};


export default connect(mapStateToProps)(ShopOrdersList);
