//import * as firebase from 'firebase';
import firebase from 'firebase/app';

import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASURMENT_ID
  };


  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

 const db = firebase.firestore();
 const _storage = firebase.storage(); 
 const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
 const _auth = firebase.auth();

 export {  googleAuthProvider, _auth, _storage, db as default };