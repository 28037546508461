import db from '../firebase/firebase';

export const updateShopOrder = (id, status) => ({
    type: 'UPDATE_ORDER',
    id,
    status
});

export const startUpdateOrder = (id, status) => {
    return (dispatch, getState) => {
        db.collectionGroup('orderedProducts').doc(id).update(status).then(() => {
            dispatch(updateShopOrder(id, status));
        }).catch((e) => {
        });
    };
};



//Set expenses
export const setShopOrders = (shopOrders) => ({
    type: 'SET_SHOP_ORDERS',
    shopOrders
});

//get shop products
export const startShopSetOrders = () => {
    return async (dispatch, getState) => {

        const id = getState().shop.id;

        if (id == null) return;

        db
            .collectionGroup('orderedProducts')
            .where('productShopId', '==', id)
            .where('paymentStatus', '==', 'paid')
            .get().then( (snapshot) => {

            const docs = snapshot.docs;
            const shopOrders = [];

            //get products
            docs.forEach((doc) => {
                const data = doc.data();
                shopOrders.push({
                    id: doc.id,
                    ...data
                });
            });

            dispatch(setShopOrders(shopOrders));

        });
    };
};
