import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { FilePicker } from 'react-file-picker';
import { connect } from 'react-redux';
import { startEditUser } from '../actions/auth';
import { _storage } from '../firebase/firebase';

export class ShopForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            t: props.t,
            title: props.shop ? props.shop.title : '',
            addressLine: props.shop ? props.shop.addressLine : '',
            phoneNumber: props.user ? props.user.phoneNumber : '',
            hasPhone: props.user ? props.user.hasPhone : '',
            validPhoneNumber: true,

            error: '',
            img1: null,
            url1: props.shop ? props.shop.url1 : '',   //first gets assigned from local, then after uploads

            id_url_front: '',
            id_img_front: null,

            id_url_back: '',
            id_img_back: null,
        }
    }

    onTitleChange = (e) => {
        const title = e.target.value;
        this.setState(() => ({ title }));
    }

    onAdressChange = (e) => {
        const addressLine = e.target.value;
        this.setState(() => ({ addressLine }));
    }

    onFileChange1 = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    img1: file,
                    url1: URL.createObjectURL(file)
                })
            )
        }
    }

    on_Id_Imgfront_Change = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    id_img_front: file,
                    id_url_front: URL.createObjectURL(file)
                })
            )
        }
    }

    on_Id_Imgback_Change = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    id_img_back: file,
                    id_url_back: URL.createObjectURL(file)
                })
            )
        }
    }

    uploadFile1 = async () => {
        const img1 = this.state.img1;
        if (img1) {
            try {
                const name = img1 ? img1.name : '';
                const snapshot = await _storage.ref(`images/IDs/${this.state.title}/${name} 1`).put(img1);
                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url1: url }));
                }
            }
            catch (e) {
                console.log("error: " + e.error);
            }
        }
    }

    upload_ID_Front = async () => {
        const img1 = this.state.id_img_front;
        if (img1) {
            try {
                var name = img1 ? img1.name : '';
                name += this.props.userId + '_front';
                const snapshot = await _storage.ref(`images/IDs/${name}`).put(img1);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ id_url_front: url }));
                }
            }
            catch (e) {
                console.log("error 1: " + e.toString());
                console.log('\tname: ' + e.name + ' message: ' + e.message + ' at: ' + e.at + ' text: ' + e.text);
                console.log("error object stack: ");
                console.log(e.stack);
            }
        }
    }

    upload_ID_Back = async () => {
        const img1 = this.state.id_img_back;
        if (img1) {
            try {
                // var name = img1 ? img1.name : '';
                // name += this.props.userId;
                // const uploadTask = _storage.ref(`images/IDs/${name}`).put(img1);
                // const snapshot = await uploadTask.snapshot;
                // const url = await snapshot.ref.getDownloadURL();

                var name = img1 ? img1.name : '';
                name += this.props.userId + '_back';
                const snapshot = await _storage.ref(`images/IDs/${name}`).put(img1);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ id_url_back: url }));
                }
            }
            catch (e) {
                console.log("error: " + e);
            }
        }
    }

    removeShopImg = () => {
        this.setState(() => ({
            url1: '',
            img1: null
        })
        )
    }

    removeIDFront = () => {
        this.setState(() => ({
            id_url_front: '',
            id_img_front: null
        })
        )
    }

    removeIDBack = () => {
        this.setState(() => ({
            id_url_back: '',
            id_img_back: null
        })
        )
    }

    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        if (!phoneNumber || phoneNumber.match(/^[0-9]{0,11}$/))
            this.setState(() => ({ phoneNumber }));

        const firstChar = phoneNumber.charAt(0);
        const secChar = phoneNumber.charAt(1);
console.log(firstChar , secChar)
        if (firstChar !== '0') {
            this.setState({
                validPhoneNumber: false,
                error: this.state.t('ShopForum.12')
            });
        }
        else if (secChar !== '1') {
            this.setState({
                validPhoneNumber: false,
                error: this.state.t('ShopForum.12')
            });
        }
        if (firstChar === '0' && secChar === '1') {
            this.setState({
                validPhoneNumber: true,
                error: ''
            });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();


        if (!this.state.hasPhone) {
            this.setState({ error: this.state.t('ShopForum.12') });
        } else if (!this.state.phoneNumber || !this.state.validPhoneNumber || this.state.phoneNumber.length !== 11) {
            console.log('phone number: ' + this.state.phoneNumber);
            console.log('this.state.validPhoneNumber: ' + this.state.validPhoneNumber);
            console.log('this.state.phoneNumber.length: ' + this.state.phoneNumber.length);

            this.setState({ error: this.state.t('ShopForum.12') });
        }
         if (!this.state.title) {
            this.setState(() => ({ error: this.state.t('ShopForum.13') }));
        }
        else if (!this.state.addressLine) {
            this.setState(() => ({ error: this.state.t('ShopForum.14') }));
        }
        else if (!this.state.id_img_back || !this.state.id_img_front) {
            this.setState(() => ({ error: this.state.t('ShopForum.15') }));
        }
        else {
            try {
                await this.uploadFile1();
                await this.upload_ID_Front();
                await this.upload_ID_Back();

                // const updates = {
                //     phoneNumber: this.state.phoneNumber,
                //     hasShop: 'pending'
                // };
                //      await this.props.updateUser(this.props.userId, updates);

                this.setState(() => ({ error: '' }));
                this.props.onSubmit({
                    title: this.state.title,
                    addressLine: this.state.addressLine,
                    creationDate: new Date().toISOString(),
                    url1: this.state.url1,
                    id_url_front: this.state.id_url_front,
                    id_url_back: this.state.id_url_back,
                    phoneNumber: this.state.phoneNumber
                });
            } catch (e) {
                console.log('error: ' + e.toString());
            }


        }
    }
    render() {
        return (
            <form className="form--middle" onSubmit={this.onSubmit}>
                <div className="img-pickers-group img-picker-shop">
                    <h2>{this.state.t("ShopForm.1")}</h2>
                    <div className="img-pickers-h">
                        <div className="img-picker">
                            <FilePicker
                                maxSize="5mb"
                                extensions={['jpg', 'jpeg', 'png']}
                                onChange={this.onFileChange1}
                                onError={errMsg => (console.log("error"))} >
                                <img className="img-picker__img" src={this.state.url1 || 'images/img-ph.jpg'} alt="" />
                            </FilePicker>
                            {
                                this.state.url1 &&
                                <div onClick={() => this.removeShopImg()}>
                                    <DeleteIcon className="delete-icon--nd" fontSize="large" />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <input
                    className="text-input"
                    type="text"
                    placeholder={this.state.t('ShopForum.1')}
                    autoFocus
                    value={this.state.title}
                    onChange={this.onTitleChange}
                />

                <input
                    className="text-input"
                    type="text"
                    placeholder={this.state.t('ShopForum.2')}
                    value={this.state.addressLine}
                    onChange={this.onAdressChange}
                />

                {
                    (!this.props.editing && !this.state.hasPhone) &&
                    <input
                        className="text-input"
                        //      required
                        type="number"
                        placeholder={this.state.t('ShopForum.3')}
                        value={this.state.phoneNumber}
                        onChange={this.onPhoneNumberChange}
                    ></input>
                }

                {
                    !this.props.editing &&
                    <h2>{this.state.t("ShopForm.2")}</h2>
                }

                {
                    !this.props.editing &&
                    <div className="img-pickers-group img-picker-shop">

                        <div className="img-picker-title-main">
                            <h2>{this.state.t("ShopForm.3")}</h2>
                            <p className="input-tips">
                                {this.state.t("ShopForm.4")}<br /><br />
                                {this.state.t("ShopForm.5")}
                            </p>
                        </div>

                        <div className="img-pickers-h">
                            <div className="img-picker">
                                <FilePicker
                                    maxSize="5mb"
                                    extensions={['jpg', 'jpeg', 'png']}
                                    onChange={this.on_Id_Imgfront_Change}
                                    onError={errMsg => (console.log("error"))} >
                                    <img className="img-picker__img" src={this.state.id_url_front || 'images/img-ph.jpg'} alt="" />
                                </FilePicker>
                                {
                                    this.state.id_url_front &&
                                    <div onClick={() => this.removeIDFront()}>
                                        <DeleteIcon className="delete-icon--nd" fontSize="large" />
                                    </div>
                                }
                                <label className="upper-margin">{this.state.t("ShopForm.6")}</label>
                            </div>
                        </div>

                        <div className="img-pickers-h">
                            <div className="img-picker">
                                <FilePicker
                                    maxSize="5mb"
                                    extensions={['jpg', 'jpeg', 'png']}
                                    onChange={this.on_Id_Imgback_Change}
                                    onError={errMsg => (console.log("error"))} >
                                    <img className="img-picker__img" src={this.state.id_url_back || 'images/img-ph.jpg'} alt="" />
                                </FilePicker>
                                {
                                    this.state.id_url_back &&
                                    <div onClick={() => this.removeIDBack()}>
                                        <DeleteIcon className="delete-icon--nd" fontSize="large" />
                                    </div>
                                }
                                <label className="upper-margin">{this.state.t("ShopForm.7")}</label>
                            </div>
                        </div>
                    </div>
                }

                <a className="error-msg">{this.state.error}</a>
                <button className="button">{this.props.shop.title != undefined ? this.state.t('ShopForum.11') : this.state.t('ShopForum.10')}</button>
                <div className="upper-margin" />
            </form>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (id, updates) => dispatch(startEditUser(id, updates))
    }
}

const mapStateToProps = (state, props) => {
    return {
        userId: state.auth.id,
        shop: state.shop,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopForm);