const products = [
  {
    id: "3MPwbJOiXBhqIgemBRsQ",
    oldDescAR: "سلسلة معدنية بدلاية وردة وقلب اكوا",
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3MPwbJOiXBhqIgemBRsQ340x270@imageIndex0@i0.jpeg?generation=1617048495466120&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3MPwbJOiXBhqIgemBRsQ340x270@imageIndex1@i0.jpeg?generation=1617048608101514&alt=media",
    ],
    oldTitle: "chain",
    sales: 2,
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    pending: false,
    accepted: true,
    adminAccepted: null,
    madeWithin: 10,
    title: "Chain",
    description: "Metal chain, rose and heart pendant",
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    oldTitleAR: "سلسلة",
    hasThumbnails: true,
    creationDate: "2021-02-25T06:51:50.042Z",
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FA2-119c.jpgundefined-1?alt=media&token=8ed7f216-4cb6-423c-87d0-7a1d31776de4",
    publishedPrice: 48,
    url3: "",
    madeToOrder: false,
    madeByUser: false,
    oldDesc: "Metal chain, rose and heart pendant",
    titleAR: "سلسلة",
    url2: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FA2-119a.jpgundefined-2?alt=media&token=9a58ea2c-bc04-4204-8a19-ca71b871402c",
    WR: 0,
    promoCode: "",
    rating: 0,
    madeDate: "2021-02-25T06:50:31.014Z",
    inStock: -1,
    price: 40,
    testThumbnails4: true,
    revenue: 96,
    rejected: false,
    url4: "",
    numReviews: 0,
    categories: [
      {
        category: "Accessories",
        mainCategory: "Fashion",
      },
    ],
    descriptionAR: "سلسلة معدنية بدلاية وردة وقلب اكوا",
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3MPwbJOiXBhqIgemBRsQ800x600@imageIndex0@i0.jpeg?generation=1617048496694225&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3MPwbJOiXBhqIgemBRsQ800x600@imageIndex1@i0.jpeg?generation=1617048624241472&alt=media",
    ],
  },
  {
    id: "3W6eQpYH0htRsdbhJdQd",
    titleAR: "طقم كروشيه",
    WR: 0,
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3W6eQpYH0htRsdbhJdQd340x270@imageIndex0@i0.jpeg?generation=1617048241017783&alt=media",
    ],
    freightCharge: 12,
    adminAccepted: null,
    title: "Crochet set",
    description: "Crochet scarf and ice cap set",
    hasThumbnails: true,
    madeByUser: false,
    madeToOrder: false,
    testThumbnails4: true,
    promoCode: "",
    categories: [
      {
        mainCategory: "Art",
        category: "Crochet",
      },
    ],
    madeDate: "2021-03-01T13:39:59.380Z",
    descriptionAR: "طقم كوفيه وايس كاب كروشيه",
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    price: 225,
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2F20151229_071501.jpgundefined-1?alt=media&token=f47dabe8-8656-4a9e-954c-70f5d3a4e89b",
    pending: false,
    oldTitleAR: "طقم كروشيه",
    rating: 0,
    madeWithin: 10,
    numReviews: 0,
    publishedPrice: 264,
    oldDesc: "Crochet scarf and ice cap set",
    oldTitle: "Crochet set",
    inStock: 1,
    accepted: true,
    oldDescAR: "طقم كوفيه وايس كاب كروشيه",
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3W6eQpYH0htRsdbhJdQd800x600@imageIndex0@i0.jpeg?generation=1617048255222171&alt=media",
    ],
    url3: "",
    url4: "",
    url2: "",
    creationDate: "2021-03-01T13:46:50.732Z",
    rejected: false,
  },
  {
    id: "3kIkakxGhLggML2o7ORH",
    inStock: 1,
    description:
      "A golden chain, braided mesh, with a metal golden pendant and brown agate beads",
    testThumbnails4: true,
    numReviews: 0,
    url3: "",
    creationDate: "2021-03-01T11:41:14.399Z",
    madeToOrder: false,
    descriptionAR:
      "سلسلة دهبي شبك مضفر مع دلاية معدنية دهبي وحبات العقيق البني",
    hasThumbnails: true,
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3kIkakxGhLggML2o7ORH800x600@imageIndex0@i0.jpeg?generation=1617048621537117&alt=media",
    ],
    accepted: true,
    oldTitleAR: "سلسلة",
    price: 75,
    madeWithin: 7,
    freightCharge: 4,
    WR: 0,
    publishedPrice: 89,
    title: "Chain",
    rating: 0,
    oldTitle: "chain",
    categories: [
      {
        mainCategory: "Fashion",
        category: "Accessories",
      },
    ],
    url2: "",
    madeDate: "2021-03-01T11:39:49.868Z",
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    oldDescAR: "سلسلة دهبي شبك مضفر مع دلاية معدنية دهبي وحبات العقيق البني",
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2F20180331_214417.jpgundefined-1?alt=media&token=bf27dd10-e551-4573-8ce1-ab3c250179f4",
    rejected: false,
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3kIkakxGhLggML2o7ORH340x270@imageIndex0@i0.jpeg?generation=1617048670865589&alt=media",
    ],
    promoCode: "",
    adminAccepted: null,
    oldDesc:
      "A golden chain, braided mesh, with a metal golden pendant and brown agate beads",
    url4: "",
    titleAR: "سلسلة",
    madeByUser: false,
    pending: false,
  },
  {
    id: "3rqEFmSUVY4tPlmZriz1",
    accepted: false,
    oldDesc: "comp",
    url3: "",
    title: "comp",
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3rqEFmSUVY4tPlmZriz1340x270@imageIndex0@i0.jpeg?generation=1617049347863743&alt=media",
    ],
    description: "comp",
    url2: "",
    freightCharge: 7,
    madeToOrder: false,
    hasThumbnails: true,
    adminAccepted: "c6FAC1oQ1uYAqdieZLbhrri1s1i2",
    categories: [
      {
        category: "Accessories",
        mainCategory: "Art",
      },
    ],
    WR: 0,
    madeByUser: false,
    creationDate: "2021-03-27T23:04:39.375Z",
    inStock: 1,
    uid: "q2PsWWXDt7aIk85nx8bbHzihFpJ2",
    pending: false,
    titleAR: "comp",
    descriptionAR: "comp",
    madeWithin: 2,
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc3rqEFmSUVY4tPlmZriz1800x600@imageIndex0@i0.jpeg?generation=1617049347078580&alt=media",
    ],
    oldTitle: "comp",
    publishedPrice: 143,
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2Fproducts%2FTest_thumb%40800x600_LargeSizeImage.jpgundefined-1?alt=media&token=5741fa5b-8172-4ebf-91a0-07c663fd8493",
    promoCode: "",
    oldTitleAR: "comp",
    detailsAR: "comp",
    rating: 0,
    details: "comp",
    oldDescAR: "comp",
    url4: "",
    madeDate: "2021-03-27T23:04:23.426Z",
    rejected: true,
    price: 122,
    numReviews: 0,
    shopId: "7lpeMRfkBhJdKkSB4anj",
  },
  {
    id: "43TiOFt2VY4d2Pz7LPlY",
    descriptionAR: "سلسلة قصيرة بدلاية حجر شفاف أخضر",
    pending: false,
    oldTitleAR: "سلسلة",
    madeDate: "2021-02-27T07:07:54.877Z",
    publishedPrice: 30,
    testThumbnails4: true,
    oldTitle: "chain",
    oldDescAR: "سلسلة قصيرة بدلاية حجر شفاف أخضر",
    promoCode: "",
    oldDesc: "A short chain of green translucent stone pendant",
    rejected: false,
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc43TiOFt2VY4d2Pz7LPlY340x270@imageIndex0@i0.jpeg?generation=1617048573270041&alt=media",
    ],
    freightCharge: 2,
    url2: "",
    url3: "",
    creationDate: "2021-02-27T07:08:40.611Z",
    price: 25,
    madeWithin: 10,
    categories: [
      {
        mainCategory: "Fashion",
        category: "Accessories",
      },
    ],
    description: "A short chain of green translucent stone pendant",
    adminAccepted: null,
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FA2-262.jpgundefined-1?alt=media&token=bd339c5a-800c-49fb-a4cc-b4346feb14a1",
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    WR: 0,
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc43TiOFt2VY4d2Pz7LPlY800x600@imageIndex0@i0.jpeg?generation=1617048575575005&alt=media",
    ],
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    url4: "",
    inStock: 0,
    title: "Chain",
    numReviews: 0,
    accepted: true,
    revenue: 30,
    sales: 1,
    madeToOrder: false,
    madeByUser: false,
    rating: 0,
    hasThumbnails: true,
    titleAR: "سلسلة",
  },
  {
    id: "4AUWpKK5VNoVMFwO2aaL",
    descriptionAR: "كوستر قلب شفاف وموف",
    creationDate: "2021-02-25T07:53:18.587Z",
    description: "Coaster transparent and purple heart",
    numReviews: 0,
    rating: 0,
    madeByUser: false,
    WR: 0,
    oldTitleAR: "كوستر",
    pending: false,
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    title: "Coaster",
    categories: [
      {
        mainCategory: "Kitchen & Dining",
        category: "Accessories",
      },
    ],
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FR4-5a.jpgundefined-1?alt=media&token=cfbfbaba-c757-401e-8f6b-6623b62a2189",
    url2: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FR4-5b.jpgundefined-2?alt=media&token=01650c8b-9442-4966-bedc-5ae227f395f8",
    adminAccepted: null,
    rejected: false,
    hasThumbnails: true,
    testThumbnails4: true,
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4AUWpKK5VNoVMFwO2aaL800x600@imageIndex0@i0.jpeg?generation=1617048254261876&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4AUWpKK5VNoVMFwO2aaL800x600@imageIndex1@i0.jpeg?generation=1617048258123962&alt=media",
    ],
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    oldDescAR: "كوستر قلب شفاف وموف",
    oldTitle: "Coaster",
    titleAR: "كوستر",
    promoCode: "",
    madeToOrder: false,
    oldDesc: "Coaster transparent and purple heart",
    inStock: 1,
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4AUWpKK5VNoVMFwO2aaL340x270@imageIndex0@i0.jpeg?generation=1617048233235527&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4AUWpKK5VNoVMFwO2aaL340x270@imageIndex1@i0.jpeg?generation=1617048249914704&alt=media",
    ],
    url3: "",
    price: 75,
    url4: "",
    publishedPrice: 89,
    madeWithin: 10,
    accepted: true,
    madeDate: "2021-02-25T07:51:30.345Z",
  },
  {
    id: "4ISTnPabfsSxE8oQbVxZ",
    hasThumbnails: true,
    madeDate: "2021-02-18T07:35:05.616Z",
    description: "A necklace ",
    madeToOrder: false,
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    categories: [
      {
        category: "Accessories",
        mainCategory: "Art",
      },
    ],
    madeByUser: false,
    inStock: -40,
    oldDesc: "A necklace ",
    adminAccepted: null,
    url4: "",
    url3: "",
    url2: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FA2-21b.jpgundefined-2?alt=media&token=36c0a274-cf1b-43cd-a032-9f66d92c6743",
    accepted: true,
    sales: 41,
    madeWithin: 10,
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    publishedPrice: 86.25,
    oldTitleAR: "سلسلة",
    oldDescAR: "سلسلة",
    rating: 0,
    rejected: false,
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FA2-21a.jpgundefined-1?alt=media&token=8c73155a-933d-4788-a795-b12a0e19d182",
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4ISTnPabfsSxE8oQbVxZ800x600@imageIndex0@i0.jpeg?generation=1617049556864906&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4ISTnPabfsSxE8oQbVxZ800x600@imageIndex1@i0.jpeg?generation=1617049559138071&alt=media",
    ],
    price: 75,
    creationDate: "2021-02-18T12:48:03.000Z",
    numReviews: 0,
    promoCode: "",
    title: "Necklace",
    pending: false,
    revenue: 3536.25,
    WR: 0,
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4ISTnPabfsSxE8oQbVxZ340x270@imageIndex0@i0.jpeg?generation=1617049554441801&alt=media",
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4ISTnPabfsSxE8oQbVxZ340x270@imageIndex1@i0.jpeg?generation=1617049556569715&alt=media",
    ],
    descriptionAR: "عقد حبات الفيروز مع حلي معدنية",
    titleAR: "عقد",
    oldTitle: "Necklace",
  },
  {
    id: "4P0zxpc2vF95TmZ0V1mD",
    thumbsUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4P0zxpc2vF95TmZ0V1mD340x270@imageIndex0@i0.jpeg?generation=1617049562806574&alt=media",
    ],
    pending: false,
    rejected: false,
    madeToOrder: false,
    madeWithin: 5,
    adminAccepted: null,
    publishedPrice: 71,
    oldTitle: "necklace",
    title: "Necklace",
    inStock: 1,
    creationDate: "2021-03-01T11:25:54.924Z",
    description: "Necklace turquoise beads with metal ornaments",
    hasThumbnails: true,
    freightCharge: 3,
    url2: "",
    madeDate: "2021-03-01T11:24:17.736Z",
    uid: "c72lPil05sTsiELUjd26cvZlQ5O2",
    numReviews: 0,
    url1: "https://firebasestorage.googleapis.com/v0/b/bazaar-bc2e3.appspot.com/o/images%2FIDs%2FDSC05211.JPGundefined-1?alt=media&token=65f5572e-396f-4bef-8b75-932b90252323",
    categories: [
      {
        category: "Accessories",
        mainCategory: "Fashion",
      },
    ],
    shopId: "K3fnTvJKpxwOkWeOUbkk",
    oldDescAR: "عقد حبات الفيروز مع حلي معدنية",
    promoCode: "",
    WR: 0,
    titleAR: "عقد",
    accepted: true,
    url4: "",
    oldTitleAR: "عقد",
    rating: 0,
    url3: "",
    madeByUser: false,
    price: 60,
    descriptionAR: "عقد حبات الفيروز مع حلي معدنية",
    detailPageUrls: [
      "https://storage.googleapis.com/download/storage/v1/b/bazaar-bc2e3.appspot.com/o/thumb@doc4P0zxpc2vF95TmZ0V1mD800x600@imageIndex0@i0.jpeg?generation=1617049563196318&alt=media",
    ],
    oldDesc: "necklace turquoise beads with metal ornaments",
  },
];
export default products;
