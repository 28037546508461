import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux'
import LoginForm from './LoginForm';
import UserForm from './UserForm';
import { startSignUp, startLogin } from '../actions/auth';
import { useTranslation } from 'react-i18next';

export const SignModal = (props) => {
    
    const { t } = useTranslation();
    
    return(
    <Modal
        isOpen={props.openModal}
        contentLabel={props.title}
        onRequestClose={props.handleCloseModal}
        closeTimeoutMS={200}
        className="modal"
    >
        <h3>{props.title}</h3>
        {
            props.title === 'Login' &&
            <LoginForm
                t={t}
                onSubmit={
                    (user) => {
                        try {
                            props.startLogin(user);
                            props.handleCloseModal();
                        }
                        catch (e) {
                            console.log("error: " + e);
                        }
                            
                    }}
            />
        }
        {
            props.title === 'Signup' &&
            <UserForm
                t={t}
                onSubmit=
                {
                    (user) => {
                        props.startSignUp(user);
                    //    props.handleCloseModal();
                    }       
                }
                closeModal={props.handleCloseModal}
            />
        }
        <button className="button--wishlist borderless" onClick={props.handleCloseModal}>Close</button>
    </Modal>
)};

const mapDispatchToProps = (dispatch, props) => ({
    startLogin: (user) => {
        try {
            dispatch(startLogin(user))
        }
        catch (e) {
            console.log("err3");
            console.log("err3: " + e);
        }
        console.log("clicked 3");
    },
    startSignUp: (user) => dispatch(startSignUp(user, props.history))
});

export default connect(undefined, mapDispatchToProps)(SignModal);