import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Helmet from "../Helmet/Helmet";
import "../../styles/auth.css";
import horusImg from "../../assets/images/favicon_nobackground.png";
import verifyImg from "../../assets/images/OTP.svg";
import { toast } from "react-toastify";

const VerifyForm = (props) => {
  const [phoneCode, setPhoneCode] = useState(
    props.user ? props.user.phoneCode : ""
  );
  const [state, setState] = useState({ error: "", success: false });

  const onCodeChange = (e) => {
    const phoneCode = e.target.value;
    setPhoneCode(phoneCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phoneCode) {
      setState(() => ({ error: props.t("VerifyPhonePage.4") }));
      toast.error(props.t("VerifyPhonePage.4"))
    } else {
      setState(() => ({ error: "" }));
      try {
        const user = { phoneCode };
        await props.onSubmit(user, setState);
        setState((prevState) => {
          if (prevState.error) {
            toast.error(prevState.error);
          }
          return prevState;
        });
      } catch (error) {
        setState(() => ({ error: error.message }));
      }
    }
  };

  return (
    <Helmet title="Verify">
      <section className="container auth">
        <div className="img">
          <img src={verifyImg} alt="Login" />
        </div>
        <div className="form card">
          <div className="auth-header-logo img-register">
            <img src={horusImg} alt="" className="auth-header-logo-img" />
          </div>
          <h1 className="auth-header-title">{props.t("VerifyPhonePage.0")}</h1>
          <p className="auth-header-subtitle">{props.t("VerifyPhonePage.1")}</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder={props.t("VerifyPhonePage.2")}
              required
              value={phoneCode}
              onChange={onCodeChange}
            />
            {state.error && <div className="error">{state.error}</div>}
            {state.success && <Redirect to="/home-page" />}
            <button className="--btn --btn-primary --btn-block">{props.t("VerifyPhonePage.3")}</button>
          </form>
        </div>
      </section>
    </Helmet>
  );
};

export default VerifyForm;
