import React, { useState } from "react";
import "../styles/cart.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container, Row, Col } from "reactstrap";
import dummyProducts from "../assets/data/products";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { startRemoveFromCart } from "../actions/cart";
import cartTotalPrice from "../utility/cartTotalPrice";
import { useTranslation } from "react-i18next";

const Cart = ({ cartItems, removeCartItem }) => {
  const totalAmount = cartTotalPrice(cartItems);
  const { t, i18n } = useTranslation();

  return (
    <Helmet title="Cart">
      <CommonSection title="Shopping Cart" />
      <section className="cart-section">
        <Container>
          <Row>
            <Col xs="12" md="8">
              {cartItems.length === 0 ? (
                <h2 className="fs-4 text-center">{t("CartPage.12")}</h2>
              ) : (
                <div className="table-responsive">
                  <table className="table bordered">
                    <thead>
                      <tr>
                        <th>{t("CartPage.2")}</th>
                        <th>{t("CartPage.3")}</th>
                        <th>{t("CartPage.4")}</th>
                        <th>{t("CartPage.5")}</th>
                        <th>{t("CartPage.6")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {cartItems.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img src={item.url1} alt="" />
                          </td>
                          <td>
                            {i18n.language === "en"
                              ? item.title
                              : item.titleAR
                              ? item.titleAR
                              : item.title}
                          </td>
                          <td>
                            {item.publishedPrice} {t("CartPage.8")}
                          </td>
                          <td>{item.quantity}</td>
                          <td>
                            <i
                              onClick={() => removeCartItem(item.id)}
                              className="ri-delete-bin-line"
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Col>

            <Col xs="12" md="4">
              <div className="summary-section">
                <h6 className="d-flex align-items-center justify-content-between">
                  {t("CartPage.7")}
                  <span className="fs-4 fw-bold">
                    {totalAmount} {t("CartPage.8")}
                  </span>
                </h6>
                <p className="fs-6 mt-2">{t("CartPage.9")}</p>
                <div className="cart-buttons">
                  <button className="buy__btn">
                    <Link to="/shop" className="link__btn">
                      {t("CartPage.10")}
                    </Link>
                  </button>

                  <button className="buy__btn">
                    <Link to="/checkout" className="link__btn">
                      {t("CartPage.11")}
                    </Link>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeCartItem: (id) => dispatch(startRemoveFromCart({ id })),
  };
};

const mapStateToPros = (state) => {
  return {
    cartItems: state.cart.cart,
  };
};

export default connect(mapStateToPros, mapDispatchToProps)(Cart);