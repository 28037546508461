import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { startLogin, handleSendCodeLogin } from '../actions/auth';
import LoginForm from './LoginForm';
import VerifyForm from './auth/VerifyForm';

export const LoginPage = (props) => {

    const { t } = useTranslation();
    const [step, setStep] = useState("1");
    const [verificationId, setVerificationId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    if (step == 1) {
        return (
            <div className="box-layout">
                <div className="box-layout__box">
                    <h1 className="box-layout__title">{t("LoginForm.6")}</h1>
                    <LoginForm
                        t={t}
                        onSubmit={
                            async (user, setState) => {
                                const data = await props.handleSendCodeLogin(user.phoneNumber);
                                if (data) {
                                    if (data.error) {
                                        if (data.error.code == "auth/phone-not-exist") {
                                            setState(() => ({ error: t("VerifyForm.6") }));
                                        } else if (data.error.code == "auth/invalid-phone-number") {
                                            setState(() => ({ error: t("VerifyForm.8") }))
                                        } else {
                                            setState(() => ({ error: data.error.message }))
                                        }
                                    } else {
                                        setVerificationId(data);
                                        setStep("2");
                                        setPhoneNumber(user.phoneNumber);
                                    }
                                }
                            }

                        }
                    />

                    <div id="recaptcha-container"></div>
                </div>
            </div>
        )
    } else if (step == 2) {
        toast.success(`${t("VerifyForm.5")}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return (
            <div className="content-container">
                <div style={{ display: 'flex', flexDirection: "column", alignItems: "center", paddingTop: 40 }}>
                    <h1>{t("VerifyForm.0")}</h1>
                    <VerifyForm
                        t={t}
                        onSubmit={(user, setState) => {
                            user.verificationId = verificationId;
                            user.phoneNumber = phoneNumber;
                            props.startLogin(user)
                                .then((data) => {
                                    if (data.error) {
                                        if (data.error.code == "auth/phone-not-exist") {
                                            setState(() => ({ error: t("VerifyForm.6") }));
                                        } else if (data.error.code == "auth/invalid-verification-code") {
                                            setState(() => ({ error: t("VerifyForm.7") }));
                                        } else if (data.error.code == "auth/invalid-phone-number") {
                                            setState(() => ({ error: t("VerifyForm.8") }))
                                        } else if (data.error.code == "auth/code-expired") {
                                            setState(() => ({ error: t("VerifyForm.9") }))
                                        } else {
                                            setState(() => ({ error: data.error.message }))
                                        }
                                    } else {
                                        props.history.push('/home-page');
                                    }
                                })
                        }}
                    />
                </div>
                <ToastContainer />
            </div>
        );
    }
};

//dispatch is what calls the fn globally, we need to map the function to this property (startLogin:  ) in props
const mapDispatchToProps = (dispatch) => ({
    startLogin: (user) => dispatch(startLogin(user)),
    handleSendCodeLogin: (phoneNumber) => dispatch(handleSendCodeLogin(phoneNumber))

});

export default connect(undefined, mapDispatchToProps)(LoginPage);

