import React from 'react';
import { _storage } from '../firebase/firebase';
import { Link } from 'react-router-dom';
import { PhoneInTalk } from '@material-ui/icons';
import { updateEmail } from '../actions/auth';


export default class EditUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            t: props.t,
            type: props.type,

            firstName: props.user ? props.user.firstName : '',
            secondName: props.user ? props.user.secondName : '',
            
            email: '',
            newEmail: '',       

            addressLine: props.user ? props.user.addressLine : '',
            phoneNumber: props.user ? props.user.phoneNumber : '',

            img1: null,
            url1: props.user ? props.user.imageUrl : '',   //first gets assigned from local, then after uploads
        }
    }
    onFirstNameChange = (e) => {
        const firstName = e.target.value;
        this.setState(() => ({ firstName }));
    }
    onLastNameChange = (e) => {
        const secondName = e.target.value;
        this.setState(() => ({ secondName }));
    }
    onAddressLineChange = (e) => {
        const addressLine = e.target.value;
        this.setState(() => ({ addressLine }));
    }
    onEmailChange = (e) => {
        const email = e.target.value;
        this.setState(() => ({ email }));
    }
    onNewEmailChange = (e) => {
        const newEmail = e.target.value;
        this.setState(() => ({ newEmail }));
    }
    onPasswordChange = (e) => {
        const password = e.target.value;
        this.setState(() => ({ password }));
    }
    onConfirmPasswordChange = (e) => {
        const confirmPassword = e.target.value;
        this.setState(() => ({ confirmPassword }));
    }
    onPhoneNumberChange = (e) => {
        const phoneNumber = e.target.value;
        if (!phoneNumber || phoneNumber.match(/^[0-9]{0,11}$/))
            this.setState(() => ({ phoneNumber }));

        const firstChar = phoneNumber.charAt(0);
        const secChar = phoneNumber.charAt(1);

        if (firstChar != 0) {
            this.setState({
                validPhoneNumber: true,
                error: 'Please add a valid phone number'
            });
        }
        if (secChar != 1) {
            this.setState({
                validPhoneNumber: true,
                error: 'Please add a valid phone number'
            });
        }
        if (firstChar == 0 && secChar == 1) {
            this.setState({
                validPhoneNumber: false,
                error: ''
            });
        }
    }


    onFileChange1 = (file) => {
        if (file) {
            this.setState(
                () =>
                    ({
                        img1: file,
                        url1: URL.createObjectURL(file)
                    })
            )
        }
    }

    uploadFile1 = async () => {
        const img1 = this.state.img1;
        if (img1) {
            try {
                const name = img1 ? img1.name : '';
                const uploadTask = _storage.ref(`images/${name}`).put(img1);
                const snapshot = await uploadTask.snapshot;
                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url1: url }));
                }
            }
            catch (e) {
            }
        }
    }




    onSubmit = async (e) => {
        e.preventDefault();


        if (this.props.type == 'name') {
            if (!this.state.firstName) {
                this.setState(() => ({ error: this.state.t("UserForm.9") }));
            }
            else if (!this.state.secondName) {
                this.setState(() => ({ error: this.state.t("UserForm.10") }));
            }
        }
        else if(this.props.type == 'email'){
            if (!this.state.email) {
                this.setState(() => ({ error: this.state.t("UserForm.9") }));
            }
            else if (!this.state.newEmail) {
                this.setState(() => ({ error: this.state.t("UserForm.10") }));
            }
            else if(this.state.email != this.state.user.email){
                this.setState(() => ({ error: this.state.t("Emails don't match") }));
            }
        }
        else if (this.props.type == 'verify') {
            if (this.state.changedPhoneNumber && (!this.state.validPhoneNumber && this.state.phoneNumber == 11))
                return;

            if (!this.state.email) {
                this.setState(() => ({ error: this.state.t("UserForm.12") }));
            }
            else if (!this.state.phoneNumber) {
                this.setState(() => ({ error: this.state.t("UserForm.12") }));
            }
            else if (!this.state.addressLine) {
                this.setState(() => ({ error: this.state.t("UserForm.12") }));
            }
        }

        else {

            await this.uploadFile1();
            this.setState(() => ({ error: '' }));

            if (this.props.type == 'name') {
                this.props.onSubmit({
                    firstName: this.state.firstName,
                    secondName: this.state.secondName,
                });
            }
            else if(this.props.type == 'email'){
                await updateEmail(this.state.newEmail);
                this.props.onSubmit({
                    email: this.state.newEmail
                })
            }
            else if (this.props.type == 'verify') {
                if(this.state.phoneNumber == this.props.state.user.phoneNumber){
                    this.props.onSubmit({
                        addressLine: this.state.addressLine,
                    });
                }
                else{
                    this.props.onSubmit({
                        phoneNumber: this.state.phoneNumber,
                        addressLine: this.state.addressLine,
                        phoneVerified: false,
                        verificationCode: '',
                        sentPhoneCode: false
                    });
                }
               
            }
            else {
                this.props.onSubmit({
                    firstName: this.state.firstName,
                    secondName: this.state.secondName,
                    phoneNumber: this.state.phoneNumber,
                    email: this.state.email,
                    imageUrl: this.state.url1
                });
            }

        }
    }
    render() {
        return (
            <form className="form" onSubmit={this.onSubmit}>

                {
                    this.state.type == 'name' &&
                    <div>
                        <h3>{this.state.t("UserForm.16")}</h3>
                        <input
                            className="text-input"
                            required
                            type="text"
                            placeholder={this.state.t("UserForm.0")}
                            autoFocus
                            value={this.state.firstName}
                            onChange={this.onFirstNameChange}
                        />

                        <input
                            className="text-input"
                            required
                            type="text"
                            placeholder={this.state.t("UserForm.1")}
                            value={this.state.secondName}
                            onChange={this.onLastNameChange}
                        />
                    </div>
                }

                {
                    this.state.type == 'verify' &&
                    <div>
                        <input
                            className="text-input"
                            type="number"
                            placeholder={this.state.t("UserForm.5")}
                            value={this.state.phoneNumber}
                            onChange={this.onPhoneNumberChange}
                        ></input>

                        <input
                            className="text-input"
                            type="text"
                            placeholder={this.state.t("EditUser.3")}
                            value={this.state.addressLine}
                            onChange={this.onAddressLineChange}
                        ></input>
                    </div>
                }
                {
                    this.state.type == 'email' &&
                    <div>
                         <input
                            className="text-input"
                            type="email"
                            placeholder={this.state.t("UserForm.17")}
                            value={this.state.email}
                            onChange={this.onEmailChange}
                        />
                        <input
                            className="text-input"
                            type="email"
                            placeholder={this.state.t("UserForm.2")}
                            value={this.state.newEmail}
                            onChange={this.onNewEmailChange}
                        />
                    </div>

                }
                {
                /* {
                    this.props.type == 'email' &&
                    <input
                        className="text-input"
                        type="email"
                        placeholder={this.state.t("UserForm.2")}
                        value={this.state.email}
                        onChange={this.onEmailChange}
                    />
                }
                {
                    this.props.type == 'password' &&
                    <div>
                        //TODO add old password
                        <input
                            className="text-input"
                            type="password"
                            placeholder={this.state.t("UserForm.3")}
                            value={this.state.password}
                            onChange={this.onPasswordChange}
                        ></input>
                        <input
                            className="text-input"
                            type="password"
                            placeholder={this.state.t("UserForm.4")}
                            value={this.state.confirmPassword}
                            onChange={this.onConfirmPasswordChange}
                        ></input>
                    </div>
                } */}


                <a className="error-msg">{this.state.error}</a>
                <button className="button">{this.state.t("EditUser.2")}</button>

            </form>
        )
    }
} 