import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { connect } from 'react-redux';
import ShopForm from '../ShopForm';
import { useTranslation } from 'react-i18next';
import { startEditUser } from '../../actions/auth';
import { startCreateShop } from '../../actions/shop';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '50%',
    height: '100px',
    fontSize: '100px'
  },
  line: {
   // background: 'red',
    //width: '500px'
  },
  button: {
    marginRight: theme.spacing(1.5),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconContainer: { // define styles for icon container
    //background: 'blue',
    transform: 'scale(1.5)',
    alignContent: 'center',
    fontSize: '200px'
  }
}));


function getSteps() {
  return ['Create shop', 'Create an ad group', 'Create an ad'];
}

function getStepContent(step, t, props) {
  switch (step) {
    case 0:
        return <ShopForm
                    t={t}
                    edit={props.user.hasShop=='true'}
                    onSubmit={(shop) => {
                        props.startCreateShop(shop);
                        props.history.push('/home-page');
                    }
                    }
                />
     case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown step';
  }
}

export function HorizontalLinearStepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel className={classes.iconContainer} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

          {/* <div>
            <Typography>{getStepContent(activeStep, t, props)}</Typography>
            <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div> */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (id, updates) => dispatch(startEditUser(id, updates)),
        startCreateShop: (shop) => dispatch(startCreateShop(shop))
    }
}

const mapStateToProps = (state, props) => {
    return {
        user: state.auth,
        userId: state.auth.id,
        shop: state.shop,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HorizontalLinearStepper);