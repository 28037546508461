import db from '../firebase/firebase';
import { startSetExpensesShop } from './expenses';
import { startShopSetOrders } from './shopOrders';

export const createShop = (shop) => ({
    type: 'CREATE_SHOP',
    shop
});

export const startCreateShop = (shop = {}) => {
    return (dispatch, getState) => {
        //destructur expenseData to these variables. If expenseData is empty, take the variables empty values
        const {
            title = '',
            owner = '',
            addressLine = '',
            creationDate = new Date().toISOString(),
            url1 = '',
            salesNumber = 0,
            rating = 0.0,
            pending = true,
            accepted = false,
            rejected = false,
            id_url_front = '',
            id_url_back = '',
            uid = getState().auth.uid,
            phoneNumber = ''
        } = shop;

        //    const phoneVerified = getState().auth.phoneVerified;
        //    if(!phoneVerified) return;
        const shopToPush = {
            title,
            addressLine,
            creationDate,
            owner,
            url1,
            uid,
            salesNumber,
            rating,

            pending,
            accepted,
            rejected,

            id_url_front,
            id_url_back,
        };

       var shopId;
        db.collection('shops').add(shopToPush).then((value) => {
            shopId = value.id;

            const updates = {
                hasShop: 'pending',
                sellerSignUpStep: 3,
                hasPhone: true,
                shopId,
                phoneNumber
            };

            const id = getState().auth.id;
        getState().auth.hasShop = 'pending';
        getState().auth.sellerSignUpStep = 3;
        db.collection('users').doc(id).update(updates);

            dispatch(createShop({
                id: value.id,
                ...shopToPush
            }));
        });

        
        
        
    };
};

export const editShop = (id, updates) => ({
    type: 'EDIT_SHOP',
    id,
    updates
});

export const startEditShop = (updates) => {
    return (dispatch, getState) => {
        const id = getState().shop.id;
        db.collection('shops').doc(id).update(updates).then(() => {
            dispatch(editShop(id, updates));
        });
    };
};

export const setShop = (shop) => ({
    type: 'SET_SHOP',
    shop
});

export const startSetShop = () => {
    return (dispatch, getState) => {

        const hasShop = getState().auth.hasShop;
        if (hasShop !== 'false') {
            db.collection('shops')
                .where('uid', '==', getState().auth.uid).get().then((snapshot) => {
                    const doc = snapshot.docs[0];
                    if (doc === undefined) return;
                    const data = doc.data();
                    const shop = {
                        id: doc.id,
                        createdFrom: 'website',
                        ...data
                    };
                    dispatch(setShop(shop));
                    dispatch(startSetExpensesShop());
                    dispatch(startShopSetOrders());
                });
        }
        else
            return;


    }
}