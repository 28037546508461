import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { getDateAsString } from '../actions/expenses';
import { useTranslation } from 'react-i18next';

const ExpenseListItem = ({ dispatch, id, title, description, price, publishedPrice, inStock, madeWithin, shopItem, url1, accepted, rejected, pending }) => {
    
    const { t } = useTranslation();

    const status = accepted ? 'Accepted' : (rejected ? 'Rejected' : 'Pending');

    return(
    <Link className="list-item list-item--link2" to={`/edit/${id}`}>
        <div className="list-item list-tem-fix-dim borderless">
            <div>
                <img className="list-item__img-v" src={url1 != null ? url1 : 'images/img-ph.jpg'} />
            </div>
            <div className="col-div">
                <h3 className={status}>{status}</h3>
                <h2 className="list-item__title">{title}</h2>
                <h3 className="list-item__title">{description}</h3>
                <h3 className="list-item__title">{`In Stock: ${inStock}`}</h3>
                <h3 className="list-item__title">{`Manufacturing time: ${madeWithin}`}</h3>
            </div>
            <div style={{width: "30%"}}>
                <div className="row-div">
                    <h4 className="list-item__data">{t("Add Product.Price")}</h4>
                    <h4 className="list-item__data">
                        {Math.ceil(price)}
                    </h4>
                    <h4 className="list-item__data">{" EGP"}</h4>
                </div>

                <div className="row-div">
                    <h4 className="list-item__data">{t("Add Product.Price Taxed")}</h4>
                    <h4 className="list-item__data">
                        {Math.ceil(publishedPrice == undefined ? price*1.15 : publishedPrice)}
                    </h4>
                    <h4 className="list-item__data">{" EGP"}</h4>
                </div>
            </div>
            

        </div>
    </Link>

)};

export default ExpenseListItem;