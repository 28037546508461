import React from 'react';
import { Link } from 'react-router-dom';
import { _storage } from '../firebase/firebase';

export default class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      t: props.t,
      firstName: props.user ? props.user.firstName : '',
      secondName: props.user ? props.user.secondName : '',
      email: props.user ? props.user.email : '',
      password: props.user ? props.user.password : '',
      confirmPassword: '',
      phoneNumber: '',
      hasShop: props.user ? props.user.hasShop : false,
      shopId: props.user ? props.user.shopId : '',
      error: '',
      img1: null,
      url1: props.user ? props.user.imageUrl : '',
      acceptTerms: false,
    };

    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onLastNameChange = this.onLastNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onAcceptChange = this.onAcceptChange.bind(this);
    this.onFileChange1 = this.onFileChange1.bind(this);
    this.uploadFile1 = this.uploadFile1.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onFirstNameChange(e) {
    const firstName = e.target.value;
    this.setState(() => ({ firstName }));
  }

  onLastNameChange(e) {
    const secondName = e.target.value;
    this.setState(() => ({ secondName }));
  }

  onEmailChange(e) {
    const email = e.target.value;
    this.setState(() => ({ email }));
  }

  onPasswordChange(e) {
    const password = e.target.value;
    this.setState(() => ({ password }));
  }

  onConfirmPasswordChange(e) {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword }));
  }

  onPhoneNumberChange(e) {
    const phoneNumber = e.target.value;
    if (!phoneNumber || phoneNumber.match(/^[0-9]{0,11}$/)) {
      this.setState(() => ({ phoneNumber }));
    }

    const firstChar = phoneNumber.charAt(0);
    const secChar = phoneNumber.charAt(1);
    console.log(`first char: ${firstChar}, sec char: ${secChar}`);

    if (firstChar !== '0') {
      this.setState({
        validPhoneNumber: true,
        error: 'Please add a valid phone number',
      });
    }
    if (secChar !== '1') {
      this.setState({
        validPhoneNumber: true,
        error: 'Please add a valid phone number',
      });
    }
    if (firstChar === '0' && secChar === '1') {
      this.setState({
        validPhoneNumber: false,
        error: '',
      });
    }
  }

  onAcceptChange(e) {
    const acceptTerms = e.target.checked;

    this.setState(() => ({ acceptTerms }));
  }

  onFileChange1(file) {
    if (file) {
      this.setState(() => ({
        img1: file,
        url1: URL.createObjectURL(file),
      }));
    }
  }

  async uploadFile1() {
    const img1 = this.state.img1;
    if (img1) {
      console.log('Has image');
      try {
        const name = img1 ? img1.name : '';
        const uploadTask = _storage.ref(`images/${name}`).put(img1);
        const snapshot = await uploadTask.snapshot;
        const url = await snapshot.ref.getDownloadURL();
        if (url) {
          this.setState(({ url1: url }));
        }
      } catch (e) {
        console.log(`error: ${e}`);
      }
    }
  }

  async onSubmit(e) {
    e.preventDefault();

    if (!this.state.firstName) {
      this.setState(() => ({ error: this.state.t('UserForm.9') }));
    } else if (!this.state.secondName) {
      this.setState(() => ({ error: this.state.t('UserForm.10') }));
    } else if (!this.state.email) {
      this.setState(() => ({ error: this.state.t('UserForm.12') }));
    } else if (!this.state.phoneNumber) {
      this.setState(() => ({ error: this.state.t('VerifyForm.4') }));
    } else if (!this.state.acceptTerms) {
      this.setState(() => ({ error: this.state.t('UserForm.15') }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit(
        {
          firstName: this.state.firstName,
          secondName: this.state.secondName,
          phoneNumber: "+2" + this.state.phoneNumber,
          email: this.state.email,
          hasShop: this.state.hasShop,
          shopId: this.state.hasShop,
          imageUrl: this.state.url1,
        },
        (state) => this.setState(state),
      );
    }
  }

  render() {
    return (
      <form className="form" style={{ width: "50rem" }} onSubmit={this.onSubmit}>
        <input
          className="text-input"
          required
          autoFocus
          type="text"
          placeholder={this.state.t('UserForm.0')}
          value={this.state.firstName}
          onChange={this.onFirstNameChange}
        />
        <input
          className="text-input"
          required
          type="text"
          placeholder={this.state.t('UserForm.1')}
          value={this.state.secondName}
          onChange={this.onLastNameChange}
        />
        <input
          className="text-input"
          required
          type="email"
          placeholder={this.state.t('UserForm.2')}
          value={this.state.email}
          onChange={this.onEmailChange}
        />
        <input
          className="text-input"
          required
          type="tel"
          placeholder={this.state.t('UserForm.5')}
          value={this.state.phoneNumber}
          onChange={this.onPhoneNumberChange}
        />
        <div style={{ marginLeft: "2rem" }}>
          <input
            type="checkbox"
            id="terms"
            name="terms"
            checked={this.state.acceptTerms}
            onChange={this.onAcceptChange}
          />
          <label style={{ marginLeft: 4 }} htmlFor="terms">
            {this.state.t('UserForm.6')}{' '}
            <span className="link-underline">
              <Link onClick={() => window.open('/termsAndConditions')}>
                {this.state.t('UserForm.7')}
              </Link>{' '}
            </span>{' '}
          </label>
          <br />
        </div>

        <a className="error-msg">{this.state.error}</a>
        <button className="button">{this.state.t('UserForm.8')}</button>
      </form>
    );
  }
}