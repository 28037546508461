import React from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

class AutoCompleteText extends React.Component {
    constructor(props) {
        super(props);

        this.items = [];
        this.map = {};
        this.invertedMap = {};

        for (const [key, value] of Object.entries(this.props.categories)) {
            this.map[key] = value;
            value.forEach(element => {
                this.items.push(element);
                this.invertedMap[element] = key;
            });
        }
        this.state = {
            suggestions: [],
            keyValuePairs: [],
            key: props.mainCategory,
            text: props.category,
            choosenValue: ' ',
            focused: false,
            renderedText: '',
            i18n: props.i18n
        };
    }

    onTextChanged = (e) => {
        const value = e.target.value;
        let suggestions = [];
        let keyValuePairs = {};
        if (value.length > 0) {
            const regex = new RegExp(`^${value}`, 'i');
            const values = Object.keys(this.invertedMap); //inverted - keys are values
            suggestions = values.filter(v => regex.test(v));
            suggestions.forEach(_value => {
                const _key = this.invertedMap[_value];  //master category
                keyValuePairs[_key] = _value;
            });
        }
        this.setState(() => ({ suggestions, text: value, keyValuePairs, key: '', choosenValue: '' }));
    }

    onFocus = (e) => {
        this.setState(() => ({
            focused: true
        }));
    }
    onBlur = (e) => {
        const { text } = this.state;
        if (text === '') {
            console.log('returned');
        }
        this.setState(() => ({
            focused: false
        }));
    }

    suggestionSelected(key, value) {
        this.setState(() => ({
            text: value,
            choosenValue: value,
            key: key,
            suggestions: [],
            focused: false
        }));

        this.props.getSuggestion(key, value);
    }

    renderSuggestions() {
        const { suggestions, focused, keyValuePairs } = this.state;
        const keys = Object.keys(keyValuePairs);
        if (suggestions.length === 0 && focused) {
            return <ul>
                {
                    Object.keys(this.props.categories).map((key) => {
                        const keyTr = this.state.i18n.language === 'en' ? key : this.props.categoriesTr[key]
                        return <div className="categories-Box" key={key}>
                            <h4>{keyTr}</h4>
                            {
                                this.props.categories[key].map((item) => {
                                    const itemTr = this.state.i18n.language === 'en' ? item : this.props.categoriesTr[item]
                                    return <li key={key + item} onClick={() => this.suggestionSelected(key, item)}>{itemTr}</li>
                                })
                            }
                        </div>
                    })
                }
            </ul>
        }
        else if (suggestions.length > 0) {
            return (
                <ul>
                    {
                        keys.map(key => {
                            const keyTr = this.state.i18n.language === 'en' ? key : this.props.categoriesTr[key]
                            return <ul key={keyTr}> 
                            <div className="categories-Box" key={key}>
                                <h4>{key}</h4>
                                {
                                    <li key={key + keyValuePairs[key]} onClick={() => {this.suggestionSelected(key, keyValuePairs[key])}} >
                                        {this.state.i18n.language === 'en' ? keyValuePairs[key] : this.props.categoriesTr[key]}
                                    </li>
                                }
                            </div>
                        </ul>})
                    }
                </ul>
            )
        }

    }

    render() {
        const { text, key, choosenValue } = this.state;
        var keyTr;
        var textTr;
        if (this.state.i18n !== undefined) {
            keyTr = this.state.i18n.language === 'en' ? key : this.props.categoriesTr[key]
            textTr = this.state.i18n.language === 'en' ? text : this.props.categoriesTr[text]
        } else {
            keyTr = key
            textTr = text 
        }
        let keyValue = keyTr + " - " + textTr;
        if(key === "" && text === "") keyValue = "";
        return (
            <div className="simple-row">
                <div className="AutoCompleteText">
                    <div className="simple-row">
                        <input onFocus={this.onFocus} value={choosenValue === '' ? textTr : keyValue} onChange={this.onTextChanged} type="text" />
                        {
                            this.state.focused &&
                            <IconButton onClick={() => this.setState(() => ({ focused: false }))}>
                                <Close fontSize="large" />
                            </IconButton>
                            
                        }
                    </div>
                    {this.renderSuggestions()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categories.categories,
        categoriesTr: state.categories.categoriesTr
    };
}

export default connect(mapStateToProps)(AutoCompleteText);