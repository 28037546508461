import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import Header from "../header/Header";
import NewHeader from "../components/header/header.js";
import CartPage from "../components/CartPage";
import NewCartPage from "../Pages/Cart";
import HomePage from "../components/HomePage";
import NewHomePage from "../Pages/Home";
import ExpenseDashboardPage from "../components/ExpenseDashboardPage";
import AddExpensePage from "../components/AddExpensePage";
import EditExpensePage from "../components/EditExpensePage";
import NotFoundPage from "../components/NotFoundPage";
import LoginPage from "../components/LoginPage";
import NewLoginPage from "../Pages/Login";
import PrivateRoute from "./PrivateRoute";
import AddShopPage from "../components/AddShopPage";
import EditShopPage from "../components/EditShopPage";
import SignUpPage from "../components/SignUpPage";
import NewSignUpPage from "../Pages/Signup";
import ProductDetailsPage from "../components/ProductDetailsPage";
import newProductDetailsPage from "../Pages/ProductDetails";
import WishlistPage from "../components/WishlistPage";
import NewWishlistPage from "../Pages/WishList";
import CustomerOrdersPage from "../components/CustomerOrdersPage";
import NewCustomerOrdersPage from "../Pages/Orders";
import CustomerOrderDetailsPage from "../components/CustomerOrderDetailsPage";
import NewCustomerOrderDetailsPage from "../Pages/OrderDetails";
import ShopOrdersPage from "../components/ShopOrdersPage";
import ShopHomePage from "../components/ShopHomePage";
import ProductsPage from "../components/show_products/ProductsPage";
import VerifyPhonePage from "../components/VerifyPhonePage";

import TermsAndConditions from "../components/policy-pages/TermsAndConditionsPage";
import ServiceFeePolicy from "../components/policy-pages/ServiceFeePolicyPage";
import PrivacyPolicy from "../components/policy-pages/PrivacyPolicyPage";
import BuyerPolicy from "../components/policy-pages/BuyerPolicyPage";

import { useTranslation } from "react-i18next";
import EditUserPage from "../components/EditUserPage";
import VerifyUser from "../components/VerifyUser";
import SellerPolicy from "../components/policy-pages/SellerPolicyPage";
import ShopStepper from "../components/widgets/ShopStepper";
// import FooterWidget from '../components/widgets/FooterWidget';
// import { FooterContainer } from '../components/footer/footer'
import NewFooter from "../components/footer/Footer";
import MobileStickyFooter from "../components/widgets/MobileStickyFooter";
import { isMobile } from "react-device-detect";
import Checkout from "../components/Checkout";
import NewCheckout from "../Pages/Checkout";
import NewProducts from "../Pages/Products";
import PublicRoute from "./PublicRoute";

export const history = createBrowserHistory();

const AppRouter = () => {
  const { i18n } = useTranslation();

  document.body.dir = i18n.dir();

  return (
    <Router history={history} forceRefresh={true}>
      {/* <Header history={history} /> */}
      <NewHeader />
      <Switch>
        {/* <Route path="/signup" component={SignUpPage} exact={true} /> */}
        <PublicRoute Route path="/signup" component={NewSignUpPage} exact={true} />
        {/* <Route path="/login" component={LoginPage} /> */}
        <PublicRoute Route path="/login" component={NewLoginPage} />

        <Route path="/stepper" component={ShopStepper} />

        <Route path="/seller-policy" component={SellerPolicy} />
        <Route path="/buyer-policy" component={BuyerPolicy} />
        <Route path="/termsAndConditions" component={TermsAndConditions} />
        <Route path="/service-fee-policy" component={ServiceFeePolicy} />
        {/* Not implemented yet */}
        <Route
          path="/anti-discrimination-policy"
          component={ServiceFeePolicy}
        />
        <Route path="/privacy-policy" component={PrivacyPolicy} />

        <Route path="/home-page" component={NewHomePage} />
        <Route path="/product-details/:id" component={newProductDetailsPage} />
        <PrivateRoute path="/checkout" component={NewCheckout} />
        <Route path="/products" component={NewProducts} />

        {/* <Route path="/home-page/:orderStatus" component={HomePage} /> */}
        <Route path="/products/:mainCat/:cat" component={ProductsPage} />
        {/* <Route path="/product-details/:id" component={ProductDetailsPage} /> */}

        {/* <PrivateRoute path="/cart" component={CartPage} /> */}
        <PrivateRoute path="/cart" component={NewCartPage} />
        {/* <PrivateRoute path="/wishlist" component={WishlistPage} /> */}
        <PrivateRoute path="/wishlist" component={NewWishlistPage} />
        {/* <PrivateRoute path="/customer-orders" component={CustomerOrdersPage} /> */}
        <PrivateRoute path="/customer-orders" component={NewCustomerOrdersPage} />
        {/* <PrivateRoute
          path="/order-details/:id"
          component={CustomerOrderDetailsPage}
        /> */}
        <PrivateRoute
          path="/order-details/:id"
          component={NewCustomerOrderDetailsPage}
        />
        {/* <PrivateRoute path="/shop-orders" component={ShopOrdersPage} /> */}
        {/* <PrivateRoute path="/verify-phone" component={VerifyPhonePage} /> */}
        {/* <PrivateRoute path="/edit-user" component={EditUserPage} /> */}
        {/* <PrivateRoute path="/verify-user" component={VerifyUser} /> */}
        {/* <PrivateRoute path="/checkout" component={Checkout} /> */}

        {/* <PrivateRoute path="/shop-home" component={ShopHomePage} />
        <PrivateRoute path="/dashboard" component={ExpenseDashboardPage} />
        <PrivateRoute path="/add-product" component={AddExpensePage} />
        <PrivateRoute path="/create-shop" component={AddShopPage} />
        <PrivateRoute path="/edit-shop" component={EditShopPage} />
        <PrivateRoute path="/edit/:id" component={EditExpensePage} /> */}

        {/* <Route path="/help" component={HelpPage} /> */}
        <Route component={NotFoundPage} />
      </Switch>

      <NewFooter />
      {/* {
        (isMobile) &&
        <MobileStickyFooter isMobile={isMobile} />
      } */}
    </Router>
  );
};

export default AppRouter;
