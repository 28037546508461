import React, { useEffect } from 'react';
import ProductsListCustomer from './ProductsListCustomer';
import { connect } from 'react-redux';
import selectExpenses from '../selectors/expenses';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useLocation } from "react-router-dom";
import 'react-notifications/lib/notifications.css';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-dom';
import ProductListItemCustomer from './ProductListItem-Customer';

const HomePage = (props) => {
    const searchClient = algoliasearch('PXATRGXJE2', 'd1d3cdfdfbb612ea22a26b59034e8bf0');
    const myParam = useLocation().search;
    const orderStatus = new URLSearchParams(window.location.search).get("orderStatus");


    useEffect(() => {
        if (orderStatus) {
            if (orderStatus == 'success') {
                NotificationManager.success('Order placed successfully!', 'Success', 5000);
            } else {
                NotificationManager.error("Couldn't place order, please try again later!", 'Error!', 5000, () => {
                    alert('callback');
                });
            }
        }

    }, []);



    return (
        <div className="home-page-container">

            <NotificationContainer />
            <InstantSearch searchClient={searchClient} indexName="products">
                <div className='search' style={{ "marginTop": "150px","display":"flex", "justify-content": "center"}}>
                    <SearchBox
                        autoFocus={true}
                        searchAsYouType={false}
                        showLoadingIndicator
                        submit={<img src="https://img.icons8.com/ios/256/search--v4.png" alt="search" width="35px" height="35px" />}
                        translations={
                            {
                                placeholder: 'Search about product'
                            }
                        }
                        showReset={false}
                    />
                   
                </div>
                <Hits hitComponent={Hit} />
            </InstantSearch>
            <ProductsListCustomer numRows={0} showFooter={true} />

        </div>
    )
};

function Hit(props) {
    props.hit.id = props.hit.objectID;
    return (
        <ProductListItemCustomer key={props.hit.objectID} product={props.hit} />
    );

}

const mapStateToProps = (state, props) => {
    return {
        products: selectExpenses(state.expenses.marketProducts, state.filters)
    };
};

export default connect(mapStateToProps)(HomePage);
