import React, { useState, useEffect } from "react";
import "../styles/order-details.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import OrderDetailsCard from "../components/UI/OrderDetailsCard";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { refundOrder } from "../actions/orders";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const OrderDetails = ({ refundProduct, orders, match }) => {
  const [order, setOrder] = useState(null);
  const [refundReason, setRefundReason] = useState("");
  const [refundOption, setRefundOption] = useState("");

  useEffect(() => {
    const { id } = match.params;
    const order = orders.find((order) => order.uuid === id);
    setOrder(order);
  }, [match.params.id, orders]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const { t, i18n } = useTranslation();

  const handleSubmit = async (e, productDocId) => {
    e.preventDefault();
    await refundProduct(order.id, productDocId, refundReason, refundOption);
    toast.success(`refunded Successfully`)
  };

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <Helmet title="Order Details">
      <CommonSection title="Order Details" />
      <section>
        <Container>
          <Row>
            <Col lg="9">
              <div className="table-container">
                <table className="table bordered">
                  <thead>
                    <tr>
                      <th>{t("OrderDetailsPage.0")}</th>
                      <th>{t("OrderDetailsPage.1")}</th>
                      <th>{t("OrderDetailsPage.2")}</th>
                      <th>{t("OrderDetailsPage.3")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <OrderDetailsCard
                      orderProducts={order.orderProducts}
                      setRefundReason={setRefundReason}
                      setRefundOption={setRefundOption}
                      handleSubmit={handleSubmit}
                      t={t}
                      i18n={i18n}
                    />
                  </tbody>
                </table>
              </div>
            </Col>

            <Col xs="12" lg="3">
              <div className="summary-section">
                <h6 className="d-flex align-items-center justify-content-between ">
                  {t("OrderDetailsPage.4")}
                  <span className="fs-4 fw-bold">
                    {Math.ceil(order.amount / 100)} {t("OrderDetailsPage.9")}
                  </span>
                </h6>
                <p className="fs-6 mt-2">
                  {t("OrderDetailsPage.5")}: {order.uuid}
                </p>
                <p className="fs-6 mt-2">
                  {t("OrderDetailsPage.6")}: {formatDate(order.creationDate)}
                </p>
                <p className="fs-6 mt-2">{t("OrderDetailsPage.7")}: {order.status}</p>
                <div>
                  <button className="buy__btn">
                    <Link to="/customer-orders" className="link__btn">
                      {t("OrderDetailsPage.8")}
                    </Link>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    refundProduct: (orderId, productId, refundReason, refundOption) =>
      dispatch(refundOrder(orderId, productId, refundReason, refundOption)),
  };
};

const mapStateToProps = (state) => {
  return {
    orders: state.orders
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);