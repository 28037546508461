import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import serviceData from "../../assets/data/serviceData"
import "./services.css";

const Services = ({t}) => {
    return (
        <section className="services">
            <Container>
                <Row>
                    {serviceData.map((item, index) => (
                        <Col lg="3" md="4" key={index}>
                            <div
                                whileHover={{ scale: 1.1 }}
                                className="service__item"
                                style={{ background: `${item.bg}` }}
                            >
                                <span>
                                    <i class={item.icon}></i>
                                </span>
                                <div>
                                    <h3>{t(`${item.title}`)}</h3>
                                    <p>{t(`${item.subtitle}`)}</p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    )
}

export default Services;