// Pagination.js

import React from "react";

const Pagination = ({ currentPage, pageNumbers, paginate }) => {
  return (
    <nav className="mt-4">
      <ul className="pagination justify-content-center">
        {currentPage > 1 && (
          <li className="page-item">
            <button onClick={() => paginate(currentPage - 1)} className="page-link">
              <span aria-hidden="true">&laquo;</span>
              <span className="sr-only">Previous</span>
            </button>
          </li>
        )}
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber} className={`page-item ${currentPage === pageNumber ? 'active' : ''}`}>
            <button className="page-link" onClick={() => paginate(pageNumber)}>
              {pageNumber}
            </button>
          </li>
        ))}
        {currentPage < pageNumbers.length && (
          <li className="page-item">
            <button onClick={() => paginate(currentPage + 1)} className="page-link">
              <span aria-hidden="true">&raquo;</span>
              <span className="sr-only">Next</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;