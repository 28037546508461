const categoryReducerDefaultState = {
    mainCategory: '',
    category: '',
}

export default (state = categoryReducerDefaultState, action) => {
    switch (action.type) {
        case 'REFRESH':
            return {
                ...state,
                mainCategory: action.mainCategory,
                category: action.category
            };
        default:
            return state;
    }
};