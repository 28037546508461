import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { startAddToWishlist } from '../actions/wishlist';
import { startAddToCart } from '../actions/cart';
import { connect } from 'react-redux';
import GridListTile from '@material-ui/core/GridListTile';
import FavoriteSharp from '@material-ui/icons/FavoriteSharp';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 10,
    },
    cardRoot: {
        width: 200,
        margin: 10,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 140,
            margin: 1,
        },
    },
    media: {
        height: 170,
        [theme.breakpoints.down('sm')]: {
            height: 140,
        },
    },
    description: {
        height: 70,
        [theme.breakpoints.down('sm')]: {
            height: 90,
        },
    },
    iconSize: {
        fontSize: "2rem"
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        width: "100%"
    },
}));

const ProductListItemCustomer = ({ product, addToCart, addToWishList, useremail }) => {
    const classes = useStyles();
    const [addToCartString, setAddToCart] = useState("grey");
    const [addToWishlistString, setAddToWishlist] = useState("grey");
    const { t, i18n } = useTranslation();

    const [title, setTitle] = useState(i18n.language === 'en' ? product.title : product.titleAR);
    const [desc, setDesc] = useState(i18n.language === 'en' ? product.description : product.descriptionAR);

    useEffect(() => {
        setTitle(i18n.language === 'en' ? product.title : product.titleAR);
        setDesc(i18n.language === 'en' ? product.description : product.descriptionAR);
    })

    return (
        <GridListTile className={classes.root}>
            <Card className={classes.cardRoot}>
                <Link to={`/product-details/${product.id}`} style={{ textDecoration: 'none' }}>
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            image={(product.thumbsUrls != null && product.thumbsUrls[0] != null) ? product.thumbsUrls[0] : 'images/img-ph.jpg'}
                        />
                        <CardContent className={classes.description}>
                            <Typography gutterBottom variant="h4" component="h2" style={{ color: 'black', fontSize: '15px' }}>
                                {title}
                            </Typography>
                            <Typography variant="h6" color="textSecondary" component="p" style={{ fontSize: '11px' }} className="text-overflow-class">
                                {desc}
                            </Typography>
                            <Typography variant="h6" color="textPrimary" component="p" style={{ fontSize: '12px' }}>
                                {'EGP ' + Math.ceil(product.publishedPrice == undefined ? product.price * 1.15 : product.publishedPrice)}
                            </Typography>
                        </CardContent>


                    </CardActionArea>
                </Link>

                <div className={classes.controls}>

                </div>

                <CardActions>
                    <IconButton onClick={() => {
                        if (useremail != undefined && useremail != null && useremail != "") {
                            addToCart(product);
                            if(addToCartString === "grey")
                                setAddToCart("#5f2a75");
                            else
                            setAddToCart("grey");
                        }
                    }}>
                        <ShoppingCart className={classes.iconSize} style={{color: addToCartString}} />
                    </IconButton>
                    <IconButton onClick={() => {
                        if (useremail != undefined && useremail != null && useremail != "")
                            addToWishList(product);
                            if(addToWishlistString === "grey")
                                setAddToWishlist("#5f2a75");
                            else
                                setAddToWishlist("grey");
                    }}>
                        <FavoriteSharp className={classes.iconSize} style={{color: addToWishlistString}}/>
                    </IconButton>
                </CardActions>

            </Card>
        </GridListTile>

    )
};

const mapStateToPros = (state) => {
    return {
        useremail: state.auth.email,
    }
}

const mapDispatchToProps = (dispatch) => ({
    addToWishList: (product) => dispatch(startAddToWishlist(product)),
    addToCart: (product) => dispatch(startAddToCart(product))
});


export default connect(mapStateToPros, mapDispatchToProps)(ProductListItemCustomer);
