export default (state = {}, action) => {
    switch (action.type) {
        case 'SIGNUP':
            return {
                ...action.auth
            };
        case 'LOGIN':
            return {
                ...action.auth
            };
        case 'EDIT':
            return{
                ...action.updates
            }
        case 'LOGOUT':
            return {};
        default:
            return state;
    }
};