import React from 'react';
//import  SingleDatePicker  from 'react-dates/lib/components/SingleDatePicker';
// import { SingleDatePicker } from 'react-dates';
// import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux';
import { _storage } from '../firebase/firebase';
import { FilePicker } from 'react-file-picker';
import Multiselect from 'multiselect-react-dropdown'
import AutoCompleteText from './widgets/AutoCompleteText';
import { getDateAsString } from '../actions/expenses';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingPage from '../components/LoadingPage';
import { getPublishedPrice } from '../utility/priceHelper';
import { resizeFile } from '../utility/imageCompression';


class ProductForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            t: props.t,
            i18n: props.i18n,
            title: props.product ? props.product.title : '',
            price: props.product ? props.product.price.toString() : '',
            publishedPrice: props.product ? props.product.publishedPrice.toString() : '',
            inStock: props.product ? props.product.inStock.toString() : 1,
            madeWithin: props.product ? props.product.madeWithin.toString() : '',
            description: props.product ? props.product.description : '',
            madeByUser: props.product ? props.product.maker : false, //bool 
            madeToOrder: props.product ? props.product.madeToOrder : false,
            madeDate: props.product ? getDateAsString(props.product.madeDate) : new Date().toISOString(),
            mainCategory: props.product ? props.product.mainCategory : '',
            category: props.product ? props.product.category : '',
            calenderFocused: false,
            error: '',
            success: '',
            img1: null,
            url1: props.product ? props.product.url1 : '',   //first gets assigned from local, then after uploads
            img2: null,
            url2: props.product ? props.product.url2 : '',
            img3: null,
            url3: props.product ? props.product.url3 : '',
            img4: null,
            url4: props.product ? props.product.url4 : '',

            titleAR: props.product ? props.product.titleAR : '',
            descriptionAR: props.product ? props.product.descriptionAR : '',

            details: props.product ? props.product.details : '',
            detailsAR: props.product ? props.product.detailsAR : '',

            suggestedCategory: '',
            publishedPrice: props.product
                ? (props.product.publishedPrice != undefined && !isNaN(props.product.publishedPrice) ? props.product.publishedPrice : props.product.price * 1.15)
                : 0.0,

            loading: false,
            categories: [],
            options: [],
        }
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    componentDidMount() {
        
        const options = Object.keys(this.props.categories).flatMap(mainCategory => {
            return this.props.categories[mainCategory].map(category => {
                if (this.state.i18n && this.state.i18n.language == 'ar') {
                    return { mainCategory: this.props.categoriesTr[mainCategory], category: this.props.categoriesTr[category], display: `${this.props.categoriesTr[mainCategory]} (${this.props.categoriesTr[category]})` }
                }
                return { mainCategory, category, display: `${category} (${mainCategory})` };
            });
        });

        this.setState({ options });
    }

    handleDropdownChange(selectedList) {


        const categories = selectedList.map((option) => {
            let { mainCategory, category } = option;
            if (this.state.i18n.language === "ar") {
                mainCategory = Object.keys(this.props.categoriesTr).find((key) => this.props.categoriesTr[key] === mainCategory) || mainCategory;
                category = Object.keys(this.props.categoriesTr).find((key) => this.props.categoriesTr[key] === category) || category;
            }

            return { mainCategory, category };
        });
        this.setState({ categories });

    }

    onTitleChange = (e) => {
        const title = e.target.value;
        this.setState(() => ({ title }));
    }
    onTitleChangeAR = (e) => {
        const titleAR = e.target.value;
        this.setState(() => ({ titleAR }));
    }
    onPriceChange = (e) => {
        const price = e.target.value;
        if (!price || price.match(/^\d{1,}(\.\d{0,2})?$/))   //only take numbers with two decimals ex: 100.00
            this.setState(() => ({
                price,
                publishedPrice: getPublishedPrice(price)
            }));
    }
    onStockChange = (e) => {
        const inStock = e.target.value;
        if (!inStock || inStock.match(/^\d{1,}(\.\d{0,2})?$/))   //only take numbers with two decimals ex: 100.00
            this.setState(() => ({ inStock }));
    }
    onMadeWithinChange = (e) => {
        const madeWithin = e.target.value;
        if (!madeWithin || madeWithin.match(/^\d{1,}(\.\d{0,2})?$/))   //only take numbers with two decimals ex: 100.00
            this.setState(() => ({ madeWithin }));
    }
    onDescriptionChange = (e) => {
        const description = e.target.value;
        this.setState(() => ({ description }));
    }
    onDetails = (e) => {
        const details = e.target.value;
        this.setState(() => ({ details }));
    }
    onDescriptionChangeAR = (e) => {
        const descriptionAR = e.target.value;
        this.setState(() => ({ descriptionAR }));
    }
    onDetailsAR = (e) => {
        const detailsAR = e.target.value;
        this.setState(() => ({ detailsAR }));
    }

    onMakerChange = (e) => {
        let madeByUser = e.target.value;
        if (madeByUser === 'me') madeByUser = true;
        else madeByUser = false;
        this.setState(() => ({ madeByUser }));
    }

    onDateChange = (madeDate) => {
        if (madeDate) {
            const madeToOrder = false;
            document.getElementById("order").checked = false;

            this.setState(() => ({ madeDate, madeToOrder }));
        }
    }
    onMadeToOrderChange = (e) => {
        const madeToOrder = e.target.value;
        if (madeToOrder) {
            const madeDate = null;
            this.setState(() => ({ madeToOrder, madeDate }));
        }
        else {
            this.setState(() => ({ madeToOrder }));
        }
    }

    onCategoryChange = (key, value) => {
        const category = value;
        const mainCategory = key;
        this.setState(() => ({ mainCategory, category }));
    }

    onSuggestedCategoryChange = (value) => {
        const suggestedCategory = value;
        this.setState(() => ({ suggestedCategory }));
    }

    onFocusChange = ({ focused }) => {
        this.setState(() => ({ calenderFocued: focused }));
    }

    removeImage1 = () => {
        this.setState(() => ({
            url1: '',
            img1: null
        })
        )
    }

    removeImage2 = () => {
        this.setState(() => ({
            url2: '',
            img2: null
        })
        )
    }

    removeImage3 = () => {
        this.setState(() => ({
            url3: '',
            img3: null
        })
        )
    }

    removeImage4 = () => {
        this.setState(() => ({
            url4: '',
            img4: null
        })
        )
    }

    onFileChange1 = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    img1: file,
                    url1: URL.createObjectURL(file)
                })
            )
        }
    }

    onFileChange2 = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    img2: file,
                    url2: URL.createObjectURL(file)
                })
            )
        }
    }

    onFileChange3 = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    img3: file,
                    url3: URL.createObjectURL(file)
                })
            )
        }
    }

    onFileChange4 = (file) => {
        if (file) {
            this.setState(
                () =>
                ({
                    img4: file,
                    url4: URL.createObjectURL(file)
                })
            )
        }
    }

    uploadImage = async (img) => {
        if (img) {
            try {
                var name = img ? img.name : '';
                name += this.props.userId + '-1';
                const snapshot = await _storage.ref(`images/products/${name}`).put(img);
                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url1: url }));
                }
            }
            catch (e) {
                throw e;
            }
        }
    }

    uploadFile1 = async () => {
        const img1 = this.state.img1;
        if (!img1) return;
        //const img = await resizeFile(img1);
        const img = img1;
        if (img) {
            try {
                var name = img ? img.name : '';
                name += this.props.userId + '-1';
                const snapshot = await _storage.ref(`images/products/${name}`).put(img);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url1: url }));
                }
            }
            catch (e) {
                throw e;
            }
        }
    }

    uploadFile2 = async () => {
        try {
            const img1 = this.state.img2;
            if (!img1) return;
            const img = await resizeFile(img1);
            if (img) {
                var name = img ? img.name : '';
                name += this.props.userId + '-2';
                const snapshot = await _storage.ref(`images/products/${name}`).put(img);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url2: url }));
                }
            }
        } catch (e) {
            throw e;
        }

    }

    uploadFile3 = async () => {
        try {
            const img1 = this.state.img3;
            if (!img1) return;
            const img = await resizeFile(img1);
            if (img) {
                var name = img ? img.name : '';
                name += this.props.userId + '-3';
                const snapshot = await _storage.ref(`images/products/${name}`).put(img);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url3: url }));
                }
            }
        } catch (e) {
            throw e;
        }
    }

    uploadFile4 = async () => {
        try {
            const img1 = this.state.img4;
            if (!img1) return;
            const img = await resizeFile(img1);
            if (img) {
                var name = img ? img.name : '';
                name += this.props.userId + '-4';
                const snapshot = await _storage.ref(`images/products/${name}`).put(img);

                const url = await snapshot.ref.getDownloadURL();
                if (url) {
                    this.setState(({ url4: url }));
                }
            }
        } catch (e) {
            throw e;
        }


    }
    hasImage = () => {
        if (!this.state.img1 && !this.state.img2 && !this.state.img3 && !this.state.img4
            && !this.state.url1 && !this.state.url2 && !this.state.url3 && !this.state.url4) {
            return false;
        } else {
            return true;
        }
    }

    validate = () => {
        if (!this.state.description || !this.state.title
            || !this.state.descriptionAR || !this.state.titleAR
            || !this.state.details || !this.state.detailsAR
        ) {
            this.setState(() => ({ error: this.state.t("Add Product.9") }));
            return false;
        }
        else if (!this.state.price) {
            this.setState(() => ({ error: this.state.t("Add Product.10") }));
            return false;
        }
        else if (!this.hasImage()) {
            this.setState(() => ({ error: this.state.t("Add Product.11") }));
            return false;
        }
        else if (this.state.categories.length == 0 || !this.state.inStock || !this.state.madeWithin) {
            this.setState(() => ({ error: "Please complete product data" }));
            return false;
        }
        return true;
    }

    pushProduct = async () => {
        try {
            this.setState(() => ({ loading: true }));

            await this.uploadFile1();
            await this.uploadFile2();
            await this.uploadFile3();
            await this.uploadFile4();

            if (this.state.madeByUser == undefined) this.state.madeByUser = false;
            if (this.state.madeToOrder == undefined) this.state.madeToOrder = false;

            this.setState(() => ({ error: '' }));
            this.props.onSubmit({
                title: this.state.title,
                titleAR: this.state.titleAR,
                description: this.state.description,
                descriptionAR: this.state.descriptionAR,
                details: this.state.details,
                detailsAR: this.state.detailsAR,
                price: parseFloat(this.state.price, 10),
                publishedPrice: parseFloat(this.state.publishedPrice, 10),
                inStock: parseFloat(this.state.inStock, 10),
                madeWithin: parseFloat(this.state.madeWithin, 10),
                madeDate: this.state.madeDate.valueOf(),
                madeByUser: this.state.madeByUser,
                madeToOrder: this.state.madeToOrder,
                mainCategory: this.state.mainCategory,
                category: this.state.category,
                categories: this.state.categories,
                url1: this.state.url1,
                url2: this.state.url2,
                url3: this.state.url3,
                url4: this.state.url4,
            });
            this.setState(() => ({ loading: false }));
        }
        catch (e) {
            this.setState(() => ({ loading: false }));
            this.setState(() => ({ error: 'An error occurred, please try again later' }));
        }
    }

    pushProduct2 = async () => {
        try {
            // this.state.loading = true;
            this.setState(() => ({ loading: true }));

            await this.uploadFile1();
            await this.uploadFile2();
            await this.uploadFile3();
            await this.uploadFile4();

            if (this.state.madeByUser == undefined) this.state.madeByUser = false;
            if (this.state.madeToOrder == undefined) this.state.madeToOrder = false;

            this.setState(() => ({ error: '' }));
            this.props.onSubmit2({
                title: this.state.title,
                titleAR: this.state.titleAR,
                description: this.state.description,
                descriptionAR: this.state.descriptionAR,
                details: this.state.details,
                detailsAR: this.state.detailsAR,
                price: parseFloat(this.state.price, 10),
                publishedPrice: parseFloat(this.state.publishedPrice, 10),
                inStock: parseFloat(this.state.inStock, 10),
                madeWithin: parseFloat(this.state.madeWithin, 10),
                madeDate: this.state.madeDate.valueOf(),
                madeByUser: this.state.madeByUser,
                madeToOrder: this.state.madeToOrder,
                mainCategory: this.state.mainCategory,
                category: this.state.category,
                categories: this.state.categories,
                url1: this.state.url1,
                url2: this.state.url2,
                url3: this.state.url3,
                url4: this.state.url4,
            });
            this.state.loading = false;
        }
        catch (e) {
            this.setState(() => ({ loading: false }));
            this.setState(() => ({ error: 'An error occurred, please try again later' }));
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        var validated = this.validate();
        if (validated) {
            await this.pushProduct();
        }
    }
    onSubmit2 = async (e) => {
        e.preventDefault();
        var validated = this.validate();
        if (validated) {
            await this.pushProduct2();
        }
        window.scrollTo(0, 0)
        this.setState(({ success: 'Going for another product addition' }));
    }
    render() {
        return (
            <div>
                {
                    !this.state.loading &&
                    <form className="form" onSubmit={this.onSubmit}>
                        <a className="Accepted">{this.state.success}</a>
                        <div className="imgs-container">
                            <h2>{this.state.t("Add Product.Add Images")}</h2>
                            <div className="img-pickers-group">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.Photos")}</label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.PhotosDesc1")}<br /><br />

                                        {this.state.t("Add Product.PhotosDesc2")}<br />
                                        &nbsp;&nbsp; {this.state.t("Add Product.PhotosDesc3")} <br />
                                        &nbsp;&nbsp; {this.state.t("Add Product.PhotosDesc4")} <br />
                                        &nbsp;&nbsp; {this.state.t("Add Product.PhotosDesc5")}  <br />
                                        &nbsp;&nbsp; {this.state.t("Add Product.PhotosDesc6")} <br />
                                    </p>
                                </div>

                                <div className="show-for-desktop img-pickers-h">
                                    <div className="img-picker">
                                        <FilePicker
                                            maxSize="5mb"
                                            extensions={['jpg', 'jpeg', 'png']}
                                            onChange={this.onFileChange1}
                                            onError={errMsg => (console.log("error: " + errMsg))} >
                                            <div>
                                                <img className="img-picker__img" src={this.state.url1 || 'images/img-ph.jpg'} />
                                            </div>
                                        </FilePicker>
                                        {
                                            this.state.url1 &&
                                            <div onClick={() => this.removeImage1()}>
                                                <DeleteIcon className="delete-icon" fontSize="large" />
                                            </div>
                                        }
                                    </div>

                                    <div className="img-picker">
                                        <FilePicker
                                            maxSize="5mb"
                                            extensions={['jpg', 'jpeg', 'png']}
                                            onChange={this.onFileChange2}
                                            onError={errMsg => (console.log("error"))} >
                                            <img className="img-picker__img" src={this.state.url2 || 'images/img-ph.jpg'} />
                                        </FilePicker>
                                        {
                                            this.state.url2 &&
                                            <div onClick={() => this.removeImage2()}>
                                                <DeleteIcon className="delete-icon" fontSize="large" />
                                            </div>
                                        }
                                    </div>

                                    <div className="img-picker">
                                        <FilePicker
                                            maxSize="5mb"
                                            extensions={['jpg', 'jpeg', 'png']}
                                            onChange={this.onFileChange3}
                                            onError={errMsg => (console.log("error"))} >
                                            <img className="img-picker__img" src={this.state.url3 || 'images/img-ph.jpg'} />
                                        </FilePicker>

                                        {
                                            this.state.url3 &&
                                            <div onClick={() => this.removeImage3()}>
                                                <DeleteIcon className="delete-icon" fontSize="large" />
                                            </div>
                                        }
                                    </div>

                                    <div className="img-picker">
                                        <FilePicker
                                            maxSize="5mb"
                                            extensions={['jpg', 'jpeg', 'png']}
                                            onChange={this.onFileChange4}
                                            onError={errMsg => (console.log("error"))} >
                                            <img className="img-picker__img" src={this.state.url4 || 'images/img-ph.jpg'} />
                                        </FilePicker>

                                        {
                                            this.state.url4 &&
                                            <div onClick={() => this.removeImage4()}>
                                                <DeleteIcon className="delete-icon" fontSize="large" />
                                            </div>
                                        }
                                    </div>
                                </div>


                            </div>

                            <div className="img-pickers-group">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.Thumbnail")}</label>
                                    <p className="input-tips">{this.state.t("Add Product.ThumbnailDesc")} </p>
                                </div>
                                <div className="img-pickers-h">
                                    <div className="img-picker">
                                        <img className="img-picker__img" src={this.state.url1 || 'images/img-ph.jpg'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="containers-row">

                            <div className="fields-container">
                                <h3>Product details</h3>
                                <p className="input-tips">Please add details in english</p>
                                <p className="input-tips">Please add at least one language.</p>
                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder="Product name"
                                    value={this.state.title}
                                    onChange={this.onTitleChange}
                                />

                                <textarea
                                    className="textarea"
                                    placeholder="Product description"
                                    value={this.state.description}
                                    onChange={this.onDescriptionChange}
                                ></textarea>

                                <textarea
                                    className="textarea"
                                    placeholder="Product details"
                                    value={this.state.details}
                                    onChange={this.onDetails}
                                ></textarea>

                            </div>


                            <div className="fields-container">
                                <h3 className="text-input-ar">وصف المنتج</h3>
                                <p className="input-tips text-input-ar">رجاء أضف التفاصيل باللغة العربية</p>
                                <p className="input-tips text-input-ar">رجاء أضف على الأقل التفاصيل بلغة واحدة</p>
                                <input
                                    className="text-input text-input-ar"
                                    type="text"
                                    placeholder="اسم المنتج"
                                    value={this.state.titleAR}
                                    onChange={this.onTitleChangeAR}
                                />
                                <textarea
                                    className="textarea text-input-ar"
                                    placeholder="وصف المنتج"
                                    value={this.state.descriptionAR}
                                    onChange={this.onDescriptionChangeAR}
                                ></textarea>

                                <textarea
                                    className="textarea text-input-ar"
                                    placeholder="تفاصيل المنتج"
                                    value={this.state.detailsAR}
                                    onChange={this.onDetailsAR}
                                ></textarea>

                            </div>


                        </div>

                        <div className="main-fields-container">
                            <h3>{this.state.t("Add Product.ProductDetails")}</h3>
                            <p className="input-tips">{this.state.t("Add Product.ProductDetailsDesc")}</p>

                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title"> {this.state.t("Add Product.Price")} </label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.PriceDesc1")} <span className="link-underline"> <a href="/service-fee-policy" target="_blank">{this.state.t("Add Product.PriceDesc2")}</a>  </span>
                                    </p>
                                </div>
                                <input
                                    className="text-input desc-input--width"
                                    type="text"
                                    placeholder="EGP 0"
                                    value={this.state.price}
                                    onChange={this.onPriceChange}
                                />
                            </div>

                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title"> {this.state.t("Add Product.Price Taxed")} </label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.PriceDesc3")} <span className="link-underline"> <a href="/service-fee-policy" target="_blank">{this.state.t("Add Product.PriceDesc2")}</a>  </span>
                                    </p>
                                </div>
                                <input
                                    className="text-input desc-input--width no-border"
                                    type="text"
                                    placeholder={this.state.price * 1.15}
                                    value={isNaN(this.state.publishedPrice) ? (this.state.price * 1.15) : this.state.publishedPrice}
                                    readOnly="readonly"
                                />
                            </div>

                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.InStock")}</label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.InStockDesc")}
                                    </p>
                                </div>

                                <input
                                    className="text-input desc-input--width"
                                    type="text"
                                    placeholder="1"
                                    value={this.state.inStock}
                                    onChange={this.onStockChange}
                                />
                            </div>

                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.leadTime")}</label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.leadTimeDesc")}
                                    </p>
                                </div>
                                <input
                                    className="text-input desc-input--width"
                                    type="text"
                                    placeholder={this.state.t("Add Product.leadTimePH")}
                                    value={this.state.madeWithin}
                                    onChange={this.onMadeWithinChange}
                                />
                            </div>

                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.madeItem")}</label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.madeItemDesc")}
                                    </p>
                                </div>

                                <div className="input-group__item">
                                    <select className="select"
                                        onChange={this.onMakerChange}>
                                        <option value="me">{this.state.t("Add Product.madeItemDesc2")}</option>
                                        <option value="someoneElse">{this.state.t("Add Product.madeItemDesc3")}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.mainCategory == "Others" &&
                            <div className="input-row-container">
                                <div className="img-picker-title-main">
                                    <label className="img-picker-title">{this.state.t("Add Product.category2")}</label>
                                    <p className="input-tips">
                                        {this.state.t("Add Product.category3")}
                                    </p>
                                </div>

                                <input
                                    className="text-input"
                                    type="text"
                                    placeholder="Category name"
                                    autoFocus
                                    value={this.state.suggestedCategory}
                                    onChange={this.onSuggestedCategoryChange}
                                />
                            </div>
                        }
                        <Multiselect
                            displayValue="display"
                            groupBy="mainCategory"
                            onSelect={this.handleDropdownChange}
                            onRemove={this.handleDropdownChange}
                            options={
                                this.state.options
                            }
                            showCheckbox
                            selectionLimit={3}
                            placeholder="select product category"
                        />

                        <a className="error-msg">{this.state.error}</a>

                        <button type="submit" className="button desc-input--width low-margin" name="button_1">{this.state.t("Add Product.buttonTitle")}</button>
                        <button type="button" className="button desc-input--width low-margin" name="button_2" onClick={this.onSubmit2}>{this.state.t("Add Product.Title2")}</button>

                        <div className="footer-container">
                            <p className="input-tips">{this.state.t("Add Product.adminApproval")}</p>
                        </div>
                    </form>
                }
                {
                    this.state.loading &&
                    <LoadingPage />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        categories: state.categories.categories,
        categoriesTr: state.categories.categoriesTr
    };
}

export default connect(mapStateToProps)(ProductForm);