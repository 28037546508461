function validateEgyptianPhoneNumber(phoneNumber) {
  const validPrefixes = ["010", "011", "012", "015"];
  const regex = /^01[0125][0-9]{8}$/; // Matches 11 digits starting with 01, followed by a valid second digit, and 8 more digits

  if (!phoneNumber.match(regex)) {
    // Phone number does not match the pattern
    return false;
  }

  const prefix = phoneNumber.substring(0, 3);
  if (!validPrefixes.includes(prefix)) {
    // Invalid prefix
    return false;
  }

  // All checks passed, phone number is valid
  return true;
}

export default validateEgyptianPhoneNumber;
