import React from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "../../styles/product-card.css";
import "../../styles/product-wishlist-card.css";
import { startAddToCart } from "../../actions/cart";
import { startRemoveFromWishlist } from "../../actions/wishlist";

const WishListCard = ({ t, i18n, item, addToCart, startRemoveFromWishlist }) => {
    return (
        <Col lg="3" md="4" className="mb-0">
            <div className="product-card">
                <Link to={`/product-details/${item.productDocId}`} className="product-card__link">
                    <div className="product-card__image">
                        <img
                            src={
                                item.thumbsUrls && item.thumbsUrls.length > 0
                                    ? item.thumbsUrls[0]
                                    : item.url1
                            }
                            alt=""
                            className="product-card__image-img"
                        />
                    </div>
                </Link>
                <div className="product-card__details">
                    <Link to={`/product-details/${item.id}`} className="product-card__link">
                        <h3 className="product-card__name">
                            {i18n.language === "en" ? item.title : item.titleAR}
                        </h3>
                    </Link>
                    <p className="product-card__description">
                        {i18n.language === "en" ? item.description.length > 50
                            ? item.description.slice(0, 50) + "..."
                            : item.description : item.descriptionAR.length > 50
                            ? item.descriptionAR.slice(0, 50) + "..."
                            : item.descriptionAR}
                    </p>
                    <div className="product-card__rating">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-line"></i>
                        <span className="product-card__reviews">4.0 (500 reviews)</span>
                    </div>
                    <div className="product-card__actions">
                        <div className="product-card__icons">
                            <span
                                className="product-card__icon product-card__icon--wishlist"
                                onClick={() => startRemoveFromWishlist(item)}
                            >
                                <i className="ri-close-line"></i>
                            </span>
                            <span
                                className="product-card__icon product-card__icon--add-to-cart"
                                onClick={() => addToCart(item)}
                            >
                                <i className="ri-add-line"></i>
                            </span>
                        </div>
                        <span className="product-card__price">
                            {item.publishedPrice}{" "}
                            {i18n.language === "en" ? "EGP" : "جنيه"}
                        </span>
                    </div>
                </div>
            </div>
        </Col>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addToCart: (product) => {
        toast.success("Product added to cart successfully!");
        return dispatch(startAddToCart(product));
    },
    startRemoveFromWishlist: (item) => {
        toast.success("Product removed from wishlist successfully!");
        return dispatch(startRemoveFromWishlist(item));
    }
});

export default connect(null, mapDispatchToProps)(WishListCard);