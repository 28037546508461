const ordersReducerDefaultState = [];

// local database
export default (state = ordersReducerDefaultState, action) => {
    switch (action.type) {
        case 'ADD_ORDERS':
            return [
                ...state,
                action.item
            ];
        case 'CANCEL_ORDERS':
            return state.filter(({ id }) => {
                return id !== action.id;
            });
        case 'SET_ORDERS':
            return action.orders;
        case 'REFUND_ORDER':
            // Update status of a single order by id
            // return state;

            const orderId = action.orderId;
            const prodId = action.prodId;
            return state.map((order) => {
                if (order.id === orderId ) {
                    order.orderProducts.map((prod) => {
                        if (prod.docId === prodId) {
                            prod.status = 'Refund requested';
                        }
                    });
                    return {
                        ...order,
                        ...action.order
                    };
                } else {
                    return order;
                };
            });
        default:
            return state;
    }
}