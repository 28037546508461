import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import "../../styles/common-section.css";
import img1 from "../../assets/images/common-1.jpg";
import img2 from "../../assets/images/common-2.jpg";
import img3 from "../../assets/images/common-3.jpg";
import img5 from "../../assets/images/common-6.png";

const imageUrls = [img1, img2, img3, img5];

const CommonSection = ({ title }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const backgroundImageStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
      url(${imageUrls[currentImageIndex]}) no-repeat center center / cover`,
    height: "400px",
    position: "relative",
    overflow: "hidden",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
    transition: "background-image 0.5s ease-in-out",
  };

  const titleStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff",
    fontSize: "2.5rem",
    fontWeight: "700",
    textTransform: "uppercase",
    letterSpacing: "2px",
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
    transition: "color 0.5s ease-in-out",
  };

  return (
    <section className="common__section" style={backgroundImageStyle}>
      <Container>
        <h1 style={titleStyle}>{title}</h1>
        <div className="carousel-arrows">
          <button className="carousel-arrow-left" onClick={previousImage}>
            <i className="fas fa-chevron-left"></i>
          </button>
          <button className="carousel-arrow-right" onClick={nextImage}>
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </Container>
    </section>
  );
};

export default CommonSection;