import db from "../firebase/firebase";

//Add expense
export const addToCart = (item) => ({
  type: "ADD_TO_CART",
  item,
});

export const updateCartItem = (id, updates) => ({
  type: 'UPDATE_CART_ITEM',
  id,
  updates
});

export const startAddToCart = (itemData) => {
  return async (dispatch, getState) => {
    const item = {
      //    ...itemData,
      title: itemData.title,
      publishedPrice: itemData.publishedPrice,
      productDocId: itemData.id,
      productShopId: itemData.shopId,
      quantity: itemData.quantity || 1,
      titleAR: itemData.titleAR,
      descriptionAR: itemData.descriptionAR,
      url1: itemData.detailPageUrls[0],
      uid: getState().auth.uid,
    };

    const userPhone = getState().auth.phoneNumber;
    if (!userPhone) {
      return;
    }
    const userRef = await db
      .collection("users")
      .where("phoneNumber", "==", userPhone);
    const snapshot = await userRef.get();
    if (snapshot.empty) {
      return;
    }
    const userId = snapshot.docs[0].id;
    const cartItemRef = db
      .collection("users")
      .doc(userId)
      .collection("userCart");
    const cartSnapshot = await cartItemRef
      .where("productDocId", "==", item.productDocId)
      .get();
    if (cartSnapshot.docs.length === 0 || cartSnapshot.docs === null) {
      cartItemRef.add(item).then((value) => {
        dispatch(
          addToCart({
            id: value.id,
            ...item,
          })
        );
      });
    } else {
      // Product already exists in cart, so update the quantity
      const existingCartItem = cartSnapshot.docs[0];
      const existingQuantity = existingCartItem.data().quantity;
      const newQuantity = existingQuantity + 1;
      cartItemRef
        .doc(existingCartItem.id)
        .update({ quantity: newQuantity })
        .then(() => {
          dispatch(
            updateCartItem(existingCartItem.id, { quantity: newQuantity })
          );
        });
    }
  };
};

export const removeFromCart = ({ id } = {}) => ({
  type: "REMOVE_FROM_CART",
  id,
});

export const startRemoveFromCart = ({ id }) => {
  return async (dispatch, getState) => {
    try {
      const userId = getState().auth.id;
      const auth = getState().auth;

      await db
        .collection("users")
        .doc(userId)
        .collection("userCart")
        .doc(id)
        .delete();

      dispatch(removeFromCart({ id }));
    } catch (e) {
    }

    // db.collection('users').doc(userId).collection('userCart').doc(id).get().then((snapshot) => {
    //     snapshot.ref.delete();
    // });
  };
};

//Set expenses
export const setCart = (cart) => ({
  type: "SET_CART",
  cart,
});

//get shop products
export const startSetCart = () => {
  return async (dispatch, getState) => {
    const id = getState().auth.id;

    if (id == null) return;

    db.collection("users")
      .doc(id)
      .collection("userCart")
      .get()
      .then((snapshot) => {
        const cart = [];

        snapshot.forEach((doc) => {
          const data = doc.data();
          cart.push({
            id: doc.id,
            ...data,
          });
        });

        dispatch(setCart(cart));
      });
  };
};

//Set govs
export const setGovs = (govs) => ({
  type: "SET_GOVS",
  govs,
});

//get shop products
export const startSetGovs = () => {
  return async (dispatch, getState) => {
    db.collection("govs")
      .doc("VsRd9it3C0qhAWsIO1vd")
      .get()
      .then((snapshot) => {
        const govs = [];
        const data = snapshot.data();
        const arr = data.arr;
        for (var i = 0; i < arr.length; i++) {
          const item = arr[i];
          const id = item.id;
          const en = item.en;
          const ar = item.ar;
          const price = item.price;
          if (price == -1) continue;

          govs.push({
            value: id,
            label: window.localStorage.i18nextLng == "en" ? en : ar,
            price,
          });
        }

        dispatch(setGovs(govs));
      });
  };
};