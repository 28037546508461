import React from "react";
import "../styles/orders.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Orders = ({ orderItems }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  const formatHour = (dateString) => {
    const date = new Date(dateString);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    return ` ${hours}:${minutes}`;
  }

  const { t } = useTranslation();

  return (
    <Helmet title="Orders">
      <CommonSection title="Your Orders" />
      <section>
        <Container>
          <Row>
            <Col lg="12">
              {orderItems.length === 0 ? (
                <h2 className="fs-4 text-center">
                  {t("OrderPage.6")}
                </h2>
              ) : (
                <div className="card-container">
                  {orderItems.map((item) => (
                    <div className="card" key={item.uuid}>
                      <div className="card-header">
                        <h4 className="card-title">{t("OrderPage.0")}: {item.uuid}</h4>
                        <p className="card-subtitle">
                          {t("OrderPage.1")}: {formatDate(item.creationDate)}
                        </p>
                        <p className="card-subtitle">
                          {t("OrderPage.7")}: {formatHour(item.creationDate)}
                        </p>
                      </div>
                      <div className="card-body">
                        <p className="card-text">
                          {t("OrderPage.2")}: {item.amount / 100} {t("OrderPage.5")}
                        </p>
                        <p className="card-text">{t("OrderPage.3")}: {item.status}</p>
                        <Link
                          to={`/order-details/${item.uuid}`}
                          className="btn btn-primary"
                        >
                          {t("OrderPage.4")}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const mapStateToPros = (state) => {
  return {
    orderItems: state.orders,
  };
};

export default connect(mapStateToPros)(Orders);
