const productsReducerDefaultState = {
  shopProducts: [],
  marketProducts: [],
  categoryProducts: [],

  lastCategoryProduct: null,
  firstCategoryProduct: null,

  lastShopProduct: null,
  firstShopProduct: null,
  productsList: [],
  trendingProducts: [],
  bestSalesProducts: [],
  newArrivalsProducts: [],
  popularCategoryProducts: [],
  reviewProductList: [],
};

export default (state = productsReducerDefaultState, action) => {
  switch (action.type) {
    case "ADD_EXPENSE":
      const shopProducts = state.shopProducts;
      shopProducts.push(action.expense);
      return {
        ...state,
        shopProducts: shopProducts,
      };
    case "REMOVE_EXPENSE":
      return state.shopProducts.filter(({ id }) => {
        return id !== action.id;
      });
    case "EDIT_EXPENSE":
      const x = state.shopProducts.map((expense) => {
        if (expense.id === action.id) {
          return {
            ...expense,
            ...action.updates,
          };
        } else {
          return expense;
        }
      });

      return {
        ...state,
        shopProducts: x,
      };
    case "SET_SHOP_PRODUCTS":
      return {
        ...state,
        shopProducts: action.shopProducts,
      };
    case "SET_MARKET_PRODUCTS":
      const prods = action.marketProducts.slice(0, 6);
      console.log(`prods: ${action.marketProducts.length}`);
      return {
        ...state,
        marketProducts: action.marketProducts,
      };
    case "SET_CATEGORY_PRODUCTS":
      //const categoryProducts = action.categoryProducts.slice(0, 6);
      return {
        ...state,
        categoryProducts: action.categoryProducts,
      };
    case "CLEAR_LAST_PRODUCTS":
      return {
        ...state,
        lastCategoryProduct: null,
        lastShopProduct: null,
      };
    case "SET_LAST_CATEGORY_PRODUCTS":
      return {
        ...state,
        lastCategoryProduct: action.product,
      };
    case "SET_FIRST_CATEGORY_PRODUCTS":
      return {
        ...state,
        firstCategoryProduct: action.product,
      };
    case "SET_LAST_SHOP_PRODUCTS":
      return {
        ...state,
        lastShopProduct: action.product,
      };
    case "SET_FIRST_SHOP_PRODUCTS":
      return {
        ...state,
        firstShopProduct: action.product,
      };
    case "GET_PRODUCTS":
      return {
        ...state,
        productsList: action.productsList,
      };
    case "GET_TRENDING_PRODUCTS":
      return {
        ...state,
        trendingProducts: action.trendingProducts,
      };
    case "GET_BEST_SALES_PRODUCTS":
      return {
        ...state,
        bestSalesProducts: action.bestSalesProducts,
      };
    case "GET_NEW_ARRIVALS_PRODUCTS":
      return {
        ...state,
        newArrivalsProducts: action.newArrivalsProducts,
      };
    case "GET_POPULAR_CATEGORY_PRODUCTS":
      return {
        ...state,
        popularCategoryProducts: action.popularCategoryProducts,
      };
    case "ADD_PRODUCT_REVIEW":
      return {
        ...state,
        reviewProductList: [...state.reviewProductList, action.newReview] ,
      };
      case "GET_PRODUCT_REVIEWS":
      return {
        ...state,
        reviewProductList: action.reviews,
      };
    default:
      return state;
  }
};
